import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import jsPDF from "jspdf";
import "jspdf-autotable";
import Header from "../../../../components/blocks/header";
import { CardDetails } from "./OrderCard";
import { Button } from "../../../../@/components/ui/button";
import utilities from "../../../../utils/utilities";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { usePlaceOrder } from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import { RootState } from "../../../../redux/rootReducer";
import toastService from "../../../../services/toastService";
import {
  QuotationItem,
  QuotationItemForBackend,
} from "../../../../ts-utils/interfaces";
import { Renderable, ValueFunction, Toast } from "react-hot-toast";

function OrderInformation() {
  const { country } = useGlobalContext();
  const { mutatePlaceOrder, isPending } = usePlaceOrder();
  const quotationData = useSelector((state: RootState) => state.quotation.data);

  const hospitalId = useSelector(
    (state: RootState) => state.quotation.hospital_id
  );
  const quotationType = useSelector(
    (state: RootState) => state.quotation.quotationType
  );
  const navigate = useNavigate();
  const [itemtotalPrice, setItemPrice] = useState(0);

  const paymentMethod = useSelector(
    (state: RootState) => state.quotation.paymentMethod
  );

  useEffect(() => {
    const total = quotationData?.reduce(
      (acc: number, product: { price: any; purchase_cost: any }) => {
        const productPrice = parseInt(
          product.price || product.purchase_cost,
          10
        );
        return acc + Number(productPrice);
      },
      0
    );
    setItemPrice(total);
  }, [quotationData]);

  const placeOrder = async () => {
    const itemsData = quotationData.map(
      (item: QuotationItem): QuotationItemForBackend => ({
        id: item.id,
        name: item.name,
        quantity: item.qty || item.quantity,
        cost: item.price,
      })
    );
    const data = {
      hospital_id: hospitalId,
      payment: paymentMethod,
      discount: "",
      total: itemtotalPrice,
      requester: "",
      po_id: quotationType === "quip" && 3,
      channel_type: "web",
      stockOrQuip: quotationType,
      ...(quotationType === "quip"
        ? { item: itemsData[0] }
        : { items: itemsData }),
    };

    mutatePlaceOrder(data, {
      onSuccess: (data: {
        data: { message: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        console.log(data);
        toastService.success(data?.data?.message);
        navigate(`/quotations`);
      },
    });
    // try {
    //   const response = await axiosInstance.post(
    //     "orders/stockbank/placeorder",
    //     data
    //   );
    //   // log consumable order event
    //   try {
    //     const checkoutEvent = {
    //       id: response.data.id,
    //       cart_id: cart.id,
    //       cart_price: itemtotalPrice,
    //       currency: "NGN",
    //       items: consumableData.map((d) => ({
    //         id: d.id,
    //         type: "medical_equipment",
    //         quantity: parseInt(d.qty, 10),
    //         price: parseInt(d.qty, 10) * parseFloat(d.price),
    //         currency: "NGN",
    //         meta: {
    //           name: d.name,
    //           quote_id: d.quote_id,
    //         },
    //       })),
    //       is_successful: true,
    //     };
    //     // ECommerce.logCheckoutEvent(checkoutEvent);
    //   } catch (error) {
    //     console.log("error logging order");
    //   }
    //   navigate("/ordersuccessfullyplaced");
    //   //   dispatch(clearCart());
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleDownload = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    let yPosition = 50;
    const contentData = quotationData?.map(
      (product: {
        name: any;
        price: string | number;
        purchase_cost: string | number;
        qty: any;
        quantity: any;
      }) => [
        product.name,
        utilities.currencyFormatter(product.price, "") ||
          utilities.currencyFormatter(product.purchase_cost, ""),
        product.qty || product.quantity,
      ]
    );
    const header = [["Name", "Price", "Quantity"]];

    const total = itemtotalPrice;

    const heading = `${
      quotationType === "stockbank" ? "Consumable" : "Equipment"
    } Invoice`;

    doc.setFontSize(18);
    doc.text(heading, 105, 15, { align: "center" });

    //@ts-ignore
    doc.autoTable({
      head: header,
      body: contentData,
      startY: 40,
      headerStyles: {
        fillColor: "#066304",
        textColor: [255, 255, 255],
      },
    });

    // Draw Total Price at the bottom right
    doc.setFontSize(14);
    const totalPriceText = `Total Price: ${utilities.currencyFormatter(
      total,
      country?.currency
    )}`;
    const totalPriceTextWidth = doc.getTextWidth(totalPriceText);
    doc.text(
      totalPriceText,
      pageWidth - totalPriceTextWidth - 20,
      yPosition + 20
    ); // Bottom right corner

    doc.save("OrderInformation.pdf");
  };

  return (
    <div className=" w-full min-h-screen">
      <Header title={`Order Information`} />
      <div className="w-11/12 mx-auto">
        <div className="text-consumableGreen mt-16">
          <div className="bg-[#FFF] rounded-xl mt-8">
            {quotationData?.map((product, index: number) => {
              return (
                <CardDetails
                  key={index + 1}
                  title={product.name}
                  value={utilities.currencyFormatter(product.price, "")}
                  qty={product?.qty || product?.quantity}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-8 bg-[#FFF]">
          <CardDetails title={"Payment method"} value={paymentMethod} />
        </div>
        <div className="mt-8 bg-[#FFF]">
          <CardDetails
            title={"Price"}
            value={utilities.currencyFormatter(
              itemtotalPrice,
              country?.currency
            )}
          />
        </div>
        <div className="mt-4 gap-4 flex flex-col">
          <Button disabled={isPending} onClick={placeOrder}>
            {isPending ? "Submitting..." : "Place order"}
          </Button>
          <Button disabled={isPending} onClick={handleDownload}>
            Download Invoice
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OrderInformation;
