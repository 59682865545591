import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import Button from "../../common/button";
import { success, error } from "../../../services/toastService";
import { Controller, useForm } from "react-hook-form";
import Input from "../../common/input";
import RadioButton from "../../common/radio";
import BackButton from "../backbutton";
import PreactiveMasteryCallLog from "./PreactiveMasteryCallLog";
import CustomField from "../../common/CustomField";
import {
  useCreateCallLog,
  useCompleteOnboarding,
  useConfirmMastery,
  useGetCurrentActiveSchedule,
  useGetProcurementCycle,
} from "../../../hooks/useHospital";
import utilities from "../../../utils/utilities";
import { useGlobalContext } from "../../../contexts/globalContext";

type Props = {
  setShowMastery: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
  handleClose: () => void;
};

export default function MasterPreActive({
  setShowMastery,
  refId,
  handleClose,
}: Props) {
  const queryClient = useQueryClient();
  const { user } = useGlobalContext();
  const { mutateCallLog, isPending: isCreatingCall } = useCreateCallLog(refId);
  const { confirmMastery, isPending: isConfirmingMastery } =
    useConfirmMastery();
  const { completeOnboarding } = useCompleteOnboarding();
  const { review } = useGetCurrentActiveSchedule(refId);
  const { procurementCycle } = useGetProcurementCycle(refId);

  const reviewed_schedule = review && review;
  const [showReview, setShowReview] = useState(false);
  const [confirmReviewed, setConfirmReviewed] = useState(false);
  const [showCallInfo, setShowCallInfo] = useState(false);

  const isPending = isCreatingCall || isConfirmingMastery;

  const {
    handleSubmit: submitCallLog,
    control: controlCallLog,
    formState: { errors: errorsCallLog },
    setValue,
  } = useForm<any>({
    defaultValues: {
      call: {
        date: "",
        sales_agent: "",
        city: "",
        procurement_cycle: "",
        product_selection: "",
        sales_agent_relationship: "",
        major_stakeholders: "",
        current_agreement: "",
      },
    },
  });

  useEffect(() => {
    setValue("call.procurement_cycle", procurementCycle);
  }, [procurementCycle, setValue]);

  const onSubmitCallLog = (data: any) => {
    const payload = {
      hospital_ref: refId,
      customer_success_rep_ref: user?.id,
      representative: data.call.sales_agent,
      city: data.call.city,
      procurement_cycle: data.call.procurement_cycle,
      call_date: utilities.formatDate(data.call.date, "YYYY-MM-DD HH:mm:ss"),
      actiontype: "pre-active",
      products: data.call.product_selection,
      stakeholders: data.call.major_stakeholders,
      agreements: data.call.current_agreement,
    };

    const masteryPayload = {
      hospital_ref: refId,
      data: {
        customer_success_rep_ref: user?.id,
      },
    };
    mutateCallLog(payload, {
      onSuccess: () => {
        success("Call log created successfully", "call-log-success");
        confirmMastery(masteryPayload, {
          onSuccess: () => {
            success("Mastery confirmed successfully", "mastery-success");
            setShowCallInfo(false);
            // handleClose();
          },
          onError: () => {
            error("An error occurred", "mastery-error");
          },
        });
      },
      onError: () => {
        error("An error occurred", "call-log-error");
      },
    });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      schedule: "",
      goal: "",
    },
  });

  const watchSchedule = watch("schedule");

  const onSubmit = (data: any) => {
    const payload = {
      hospital_ref: refId,
      data: {
        reviewed_schedule: +data.schedule,
        hospital_goal: +data.goal,
      },
    };
    completeOnboarding(payload, {
      onSuccess: (data) => {
        success(data?.data?.message);
        queryClient.refetchQueries({ queryKey: ["hospitals"] });
        handleClose();
      },
      onError: () => {
        error("An error occurred", "schedule-error");
      },
    });
  };

  return (
    <>
      {showReview ? (
        <div>
          <BackButton
            goBack={() => {
              setShowReview(false);
            }}
          />
          <CustomField title="Amount" value={reviewed_schedule?.amount} />
          <CustomField
            title="Email"
            value={reviewed_schedule?.email === "1" ? "Yes" : "No"}
          />
          <CustomField title="Frequency" value={reviewed_schedule?.frequency} />
          <CustomField title="Orders" value={reviewed_schedule?.orders} />
          <CustomField
            title="Success"
            value={reviewed_schedule?.success === "1" ? "Yes" : "No"}
          />
          <CustomField
            title="Training"
            value={reviewed_schedule?.training === "1" ? "Yes" : "No"}
          />

          <Button
            label="Confirm Review"
            onClick={() => {
              setShowReview(false);
              setConfirmReviewed(true);
            }}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {showCallInfo ? (
            <PreactiveMasteryCallLog
              setShowCallInfo={setShowCallInfo}
              control={controlCallLog}
              errors={errorsCallLog}
              submit={submitCallLog(onSubmitCallLog)}
              isLoading={isPending}
            />
          ) : (
            <React.Fragment>
              <BackButton
                goBack={() => {
                  setShowMastery(false);
                  setConfirmReviewed(false);
                }}
              />
              <div className="my-8">
                <CustomField
                  title="Phone Call with Sales Agent to get Hospital Information"
                  value={
                    <Button
                      onClick={() => setShowCallInfo(true)}
                      label="Edit Call Information"
                      type="button"
                      style={{ marginTop: "0px" }}
                    />
                  }
                />
                <CustomField
                  title="Review of Hospital Active Schedule"
                  value={
                    <>
                      <div className="flex flex-col">
                        <button
                          onClick={() => setShowReview(true)}
                          className="text-blue-400 underline mr-2"
                        >
                          View Active Schedule
                        </button>
                        <p className="text-xs text-red-400">
                          {watchSchedule === "1" && !confirmReviewed
                            ? "Please review active schedule"
                            : ""}
                        </p>
                      </div>
                      <Controller
                        control={control}
                        name="schedule"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <div>
                            <div className="flex gap-4">
                              <RadioButton
                                name={name}
                                onChange={onChange}
                                value="1"
                                checked={value === "1"}
                                message="Yes"
                              />
                            </div>
                          </div>
                        )}
                      />
                    </>
                  }
                />
                <CustomField
                  title="One month goal for hospital - revenue target"
                  style={{ paddingTop: "10px" }}
                  value={
                    <Controller
                      control={control}
                      name="goal"
                      rules={{ required: "goal is required" }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Input
                          autoFocus={false}
                          name={name}
                          onChange={onChange}
                          placeholder="20000000"
                          value={value}
                          type="number"
                          error={errors.goal?.message || null}
                          style={{
                            width: "100%",
                            border: "none",
                            background: "#f1f1f1",
                          }}
                        />
                      )}
                    />
                  }
                />
              </div>
              <Button
                disabled={
                  (watchSchedule === "1" && !confirmReviewed) ||
                  watchSchedule === ""
                }
                label="Finish Mastery"
              />
            </React.Fragment>
          )}
        </form>
      )}
    </>
  );
}
