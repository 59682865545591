import React, { Dispatch, SetStateAction } from "react";
import ProgressLoader from "../../../../components/common/progressLoader";
import { Button } from "../../../../@/components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../@/components/ui/alert-dialog";

interface ConfirmClaimProps {
  selectedProspect: {
    name: string;
  };
  onSubmit: () => void;
  progress: number;
  isConfirmed: boolean;
  setIsConfirmed: Dispatch<SetStateAction<boolean>>;
}

export default function ConfirmClaim({
  selectedProspect,
  onSubmit,
  progress,
  isConfirmed,
  setIsConfirmed,
}: ConfirmClaimProps) {
  return (
    <>
      <ProgressLoader progress={progress} />

      <AlertDialog open={isConfirmed} onOpenChange={setIsConfirmed}>
        <AlertDialogContent>
          {" "}
          <div className="space-y-4">
            <AlertDialogHeader>
              <AlertDialogTitle className="capitalize">
                Click below to update{" "}
                <span className="text-primary">{selectedProspect?.name}</span>{" "}
                information.
              </AlertDialogTitle>
              <AlertDialogDescription className="capitalize"></AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="flex items-center !justify-center">
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <Button onClick={onSubmit} className="mt-1.5">
                Confirm
              </Button>
            </AlertDialogFooter>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
