import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import http from "./httpService";

export interface Appointment {
  id: string;
  userId: number;
  date: string;
  location: string;
}

export const getAllAppointments = (userId: string): Promise<Appointment[]> => {
  return http.get(`/marketing/appointment/${userId}?sendaquisition=0`);
};

export const getAllAcquisition = (city: string) => {
  return http.get(`acquisition/appointment/${city}`);
};

export const getPitchInformation = (hospitalId: string) => {
  return http.get(`acquisition/pitch/information/${hospitalId}`);
};

export const getPendingRequests = (city: string) => {
  return http.get("acquisition/close/pending/" + city);
};

export const getSinglePendingRequests = (id: string) => {
  return http.get("acquisition/single/closed/" + id);
};

export const getClosedRequests = () => {
  return http.get("acquisition/closed");
};

export const getActiveHospitals = () => {
  return http.get("acquisition/active");
};

export const getOnboardChecklist = (id: string) => {
  return http.get("/acquisition/checklist/" + id);
};

export const getSuccessActive = () => {
  return http.get("/acquisition/success/active");
};

export const getMaintenanceSchedule = () => {
  return http.get("/acquisition/schedule/active");
};

export const getHospitalScoreCard = (id: string) => {
  return http.get("/acquisition/hospitals/score/" + id);
};

export const sendToClose = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return http.post("/acquisition/sendtoclosed", data, options);
};

export const closeAppointment = (data: any) => {
  return http.post("/acquisition/closed", data);
};
export const reserveAppointment = (data: any) => {
  return http.post("/admin/hospitals/reserved", data);
};

export const scheduleAppointment = (data: any) => {
  return http.post("/acquisition/schedule/onboard", data);
};

export const scheduleActive = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return http.post("/acquisition/schedule/active", data, options);
};

export const sendEmail = (
  id: string,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/checklist/email/" + id, options);
};

export const completeChecklist = (id: string) => {
  return http.post("/acquisition/checklist/completed/" + id);
};

export const confirmTraining = (
  id: string,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/acquisition/checklist/training/" + id, options);
};

export const uploadSla = (data: any) => {
  return http.post("/acquisition/checklist/sla/" + data.id, data.data);
};

export const sendToSuccess = (
  id: string,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.put("/acquisition/sucess/handover/" + id, options);
};

export const alertEveryone = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return http.post("/acquisition/alert/everyone", data, options);
};

export const sendGift = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return http.post("/acquisition/send/active/gift", data, options);
};

export const updateAppointment = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return http.post("/acquisition/change/appointment/date", data, options);
};

const appointmentService = {
  alertEveryone,
  confirmTraining,
  completeChecklist,
  closeAppointment,
  getActiveHospitals,
  getAllAppointments,
  getAllAcquisition,
  getClosedRequests,
  getHospitalScoreCard,
  getPendingRequests,
  getPitchInformation,
  getOnboardChecklist,
  getSinglePendingRequests,
  getMaintenanceSchedule,
  getSuccessActive,
  scheduleAppointment,
  sendEmail,
  sendGift,
  sendToClose,
  sendToSuccess,
  uploadSla,
  updateAppointment,
  reserveAppointment,
};

export default appointmentService;
