import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import httpService from "./httpService";

export const getProspectedHospitals = (city: string, id: string) => {
  return httpService.get("/manage/verified/hospitals/" + city + "/" + id);
};

export const getUnprospectedHospitals = (
  city: string,
  id: string | undefined
) => {
  return httpService.get("/manage/unverified/hospitals/" + city + "/" + id);
};

export const getNerveClaims = (city: string) => {
  return httpService.get("/manage/hospitals/claim/account/" + city);
};

export const getUnassignedHospitals = (city: string) => {
  return httpService.get("/manage/unassigned/hos/" + city);
};

export const updateHospital = (
  data: any,
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.put("/manage/hospitals/update", data, options);
};

export const validateHospital = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.put("/manage/hospitals/verification", data, options);
};

export const confirmClaim = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.post(
    "/manage/hospitals/claim/account/now/",
    data,
    options
  );
};

export const nerveUsageMessages = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }

  return httpService.get(
    `/manage/hospital/why/nerve/${filters.user_id}?${queryParams.toString()}`
  );
  // return httpService.get(
  //   `/manage/hospital/why/nerve?${queryParams.toString()}`
  // );
};

const prospectService = {
  confirmClaim,
  getNerveClaims,
  getUnassignedHospitals,
  getProspectedHospitals,
  getUnprospectedHospitals,
  updateHospital,
  validateHospital,
  nerveUsageMessages,
};

export default prospectService;
