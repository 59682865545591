import CountUp from "react-countup";

const AnimatedCounter = ({
  amount,
  decimal,
  prefix,
}: {
  amount: number;
  decimal?: number;
  prefix: string;
}) => {
  return (
    <div className="w-full">
      <CountUp
        start={0}
        decimals={decimal}
        decimal="."
        prefix={prefix}
        end={amount}
      />
    </div>
  );
};

export default AnimatedCounter;
