/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Modal from "../common/modal";

import withAppointments from "../hoc/withAppointment";
import Button from "../common/button";
import { renderSelect } from "../common/field";
import { assignAgent, getSaleAgents } from "../../services/agent.service";
import utilities from "../../utils/utilities";
import toastService, { error as toastError } from "../../services/toastService";

import ms from "../../services/marketing.service";
import ProgressLoader from "../common/progressLoader";
import LoadingButton from "../common/loadingButton";
import { useGlobalContext } from "../../contexts/globalContext";
const mapAgents = (agents) => {
  return utilities.mapItemsFromObjects(agents).map((a) => ({
    id: a.id,
    name: a.username,
  }));
};

const AppointmentInfo = (props) => {
  const [openMessage, setOpenMessage] = useState(false);
  const [note, setNote] = useState("");
  const [progress, setProgress] = useState(0);
  const { selectedProspect, handleClose } = props;
console.log(selectedProspect)
  const { user } = useGlobalContext();

  const handleProgressChange = (progress) => {
    setProgress(progress);
  };

  const [loading, setLoading] = useState(false);
  const [agentsDropdown, setAgents] = useState([]);
  const [dataSet, setData] = React.useState({
    assignee: "",
    assigneeId: "",
  });
  
  const [errors, setErrors] = React.useState({});

  const handleChange = (event) => {
    const { value, name } = event.target;
    const selectedOption = agentsDropdown.find(
      (option) => option.name == value
    );

    setData((prevData) => ({
      ...prevData,
      [name]: value,
      assigneeId: selectedOption?.id || "", 
    }));
  };

  const postData = async () => {
    const apiData = {
      id: selectedProspect.id,
      note: note,
    };

    const res = await ms.sendAcquisition(apiData, handleProgressChange);
    if (res.data.status !== "success") throw new Error(res.data.message);

    handleClose();
  };

  const handleAcquisition = () => {
    toastService.promise(postData(), {
      loading: "Uploading data...",
      success: () => "Information added Sucessfully!",
      error: (e) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not process this appointment!",
    });
  };

  useEffect(() => {
   
    fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAgents = async () => {
    
    let newState ;
  

    if (!selectedProspect.state) {
      newState = selectedProspect.city.split(" ")[0];
    }else{
       newState = selectedProspect.state.split(" ")[0];
    }

    try {
      setLoading(true);

      const response = await getSaleAgents(newState);
      const agents = mapAgents(response.data.data);

      setAgents(agents);
      setLoading(false);
    } catch (error) {
      setErrors(error);
      setLoading(false);
      toastError("Could not fetch sales reps");
    }
  };

  const postAssign = async () => {
    const { assigneeId } = dataSet;
    const apiData = {
      user_id: assigneeId,
      hospital_id: selectedProspect.id,
    };

    const res = await assignAgent(apiData, handleProgressChange);
    if (res.data.status !== "success") throw new Error(res.data.message);

    handleClose();
    
  };

  const handleAssign = () => {
    toastService.promise(postAssign(), {
      loading: "Uploading data...",
      success: () => "Assigned to a Sales Agent Sucessfully!",
      error: (e) =>
        e?.response?.data?.message ||
        e?.message ||
        "Could not process this appointment!",
    });
  };

  return (
    <Modal handleClose={handleClose}>
      <ProgressLoader progress={progress} />
      <h3 className="modal-title">Appointment Information</h3>

      <ul className="complaint-modal-items no-list">
        <li className="complaint-modal-items-single">
          <i className="fa fa-hospital-o"></i> <span>Facility Name:</span>{" "}
          <span className="update">{selectedProspect.name}</span>
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-envelope-o"></i> <span>Email Address:</span>
          <span className="update">{selectedProspect.email || "N/A"}</span>{" "}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-phone"></i> <span>Phone Number:</span>{" "}
          <span className="update">
            {selectedProspect.countrycode}-{selectedProspect.phone || "N/A"}
          </span>{" "}
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-home"></i> <span>Facility Address:</span>{" "}
          <span className="update">{selectedProspect.address || "N/A"}</span>{" "}
        </li>
        {user.position !== "sales_lead" && (
          <li className="complaint-modal-items-single">
            <i className="fa fa-sitemap"></i> <span>Status:</span>{" "}
            {/* {getFacilitySize()?.name || "Not Recorded"} */}Pending
          </li>
        )}
        <li className="complaint-modal-items-single">
          <i className="fa fa-user-o"></i> <span>Prospect Mode:</span>{" "}
          <span className="update">{selectedProspect.preference}</span>
        </li>
        <li className="complaint-modal-items-single">
          <i className="fa fa-bed"></i> <span>Appointment Date and Time:</span>{" "}
          <span className="update">
            {selectedProspect.appointmentdate} | {selectedProspect.time}
          </span>
        </li>

        {user.position !== "marketing" && (
          <span className="complaint-modal-items-assign">
            {renderSelect({
              name: "assignee",
              label: "Assign Sales Rep",
              placeholder: "Select Rep",
              options: agentsDropdown,
              data: dataSet,
              errors: errors,
              handleChange: handleChange,
            })}
            <button
              className="status primary assign_button   transition duration-500 ease-in-out bg-[#441AB7] text-white hover:bg-[#9575e7] px-4 rounded-full active:translate-y-2 active:scale-95 mt-3"
              onClick={handleAssign}
              disabled={!dataSet.assigneeId}
              // onClick={() => handleAssignSelect(c)}
            >
              Assign
            </button>
          </span>
        )}

        {openMessage && (
          <li>
            <textarea
              id="note"
              name="note"
              onChange={(e) => setNote(e.target.value)}
              placeholder="Leave a note"
              rows={4}
              cols={50}
              className="form-field-input"
            ></textarea>
          </li>
        )}
      </ul>

      <LoadingButton visible={loading} message="Please wait" />

      {user.position !== "sales_lead" && !loading && (
        <div className="modal-actions">
          <Button
            icon="envelope"
            label={openMessage ? "Hide note" : "Leave a note"}
            onClick={() => {
              openMessage ? setOpenMessage(false) : setOpenMessage(true);
            }}
          />
          <Button
            icon="paper-plane"
            label="Send to acquisition"
            onClick={handleAcquisition}
          />
        </div>
      )}
    </Modal>
  );
};

AppointmentInfo.propTypes = {
  facilitySize: PropTypes.array,
  facilityType: PropTypes.array,
  selectedProspect: PropTypes.object,
  handleClose: PropTypes.func,
};

export default withAppointments(AppointmentInfo);
