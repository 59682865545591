import React, { useEffect, useState } from "react";

import Button from "../common/button";
import Modal from "../common/modal";

import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../contexts/globalContext";
import { useGetDashboardData } from "../../hooks/useDashboard";

const TargetReminder = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const { dashboard } = useGetDashboardData(user?.id || "");

  const getTargetForTheMonth = () => {
    const month = new Date().getMonth() + 1;
    const targetForTheMonth = dashboard.targets?.find(
      (target: { mon: string }) => target.mon === month.toString()
    );

    return targetForTheMonth;
  };

  const targetForTheMonth = getTargetForTheMonth();

  const [showTargetReminder, setShowTargetReminder] = useState(false);

  const handleCloseTargetReminder = () => {
    setShowTargetReminder(false);
  };

  useEffect(() => {
    const handleShowTargetReminder = () => {
      const showReminder = targetForTheMonth ? false : true;
      setShowTargetReminder(showReminder);
    };
    handleShowTargetReminder();
  }, [targetForTheMonth]);

  if (!showTargetReminder) return null;

  return (
    <Modal handleClose={handleCloseTargetReminder}>
      <h3 className="modal-title text-capitalize">
        <i className="fa fa-newspaper-o"></i> Welcome,{" "}
        <span>{user?.username}</span>
      </h3>

      <p className="modal-body-text">
        Targets have not yet been set for this month.{" "}
        <span className="error">
          It's important to set targets at the start of every month.
        </span>{" "}
        Would you like to set them now?
      </p>

      <div className="modal-buttons">
        <Button
          label="Set Now"
          onClick={() => {
            navigate("/set-target");
            handleCloseTargetReminder();
          }}
        />
        <Button
          label="Remind me later"
          className="app-btn close-btn"
          onClick={handleCloseTargetReminder}
        />
      </div>
    </Modal>
  );
};

export default TargetReminder;
