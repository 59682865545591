import React, { useState } from "react";

import location from "../../assets/Location.png";

const LogVisit = (props: {
  handleClose: any;
  changePage: any;
  setLogData: any;
  label?: string;
}) => {
  const skipped = localStorage.getItem("skippedLog");

  const { handleClose, changePage, setLogData, label } = props;
  const [coordinates, setCoordinates] = useState<string | null>(null);
  const [pitchLocation, setPitchLocation] = useState<string | null>(null);
  const [landmark, setLandmark] = useState(null);

  const [isLocation, setLocation] = useState(false);

  const handleBack = () => {
    if (isLocation) {
      setLocation(false);
      setCoordinates(null);
      setLandmark(null);
    } else {
      handleClose();
    }
  };

  const handleFindLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates(`${latitude} N, ${longitude} E`);
          setPitchLocation(`${latitude},${longitude}`);
          fetchLocationDetails(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchLocationDetails = async (latitude: number, longitude: number) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDQjd9fzSdMQzaCXmjXMlfJILG53ZVJs5M`
    );
    const data = await response.json();
    if (data.status === "OK") {
      const results = data.results;
      if (results.length > 0) {
        setLandmark(results[0].formatted_address);
      }
    }
  };

  const sendToClose = () => {
    let data = {
      location: pitchLocation,
      address: landmark,
    };
    changePage();
    setLogData(data);
  };

  return (
    // <Modal handleClose={handleClose}>
    <div>
      <h3 className="modal-title">Log Your Visit</h3>

      <p className="log-note">
        {!isLocation
          ? "This will be required before you can proceed to submit the pitch"
          : "This will be required before you can proceed to the next stage of the acquisition process"}
      </p>
      {!isLocation && (
        <div className="logContent">
          <img
            src={location}
            alt={"location"}
            width={100}
            height={100}
            style={{ objectFit: "contain" }}
          />

          <div
            className="find-location"
            onClick={() => {
              handleFindLocation();
              setLocation(true);
            }}
          >
            Find My Location
          </div>
        </div>
      )}

      {isLocation && (
        <div className="logContent">
          <div className="log-data">
            <div className="log-data-inner">
              <div className="log-data-header">
                <div>
                  <i
                    className="fa fa-map-marker"
                    style={{ fontSize: "24px", color: "#000" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="current-location">Your Current Location</div>
              </div>

              <div className="coords">{coordinates ?? "N/A"}</div>
            </div>

            <div className="log-data-bottom">
              <p className="log-data-bottom-name">{landmark}</p>
            </div>
          </div>
        </div>
      )}

      <div className="log-actions">
        <div className="log-back" onClick={handleBack}>
          Go Back
        </div>

        {skipped === "Imported" ? (
          <div className="log-Skip" onClick={sendToClose}>
            Skip
          </div>
        ) : (
          <div className="log-Skip" onClick={sendToClose}></div>
        )}

        {(isLocation || coordinates) && (
          <button
            onClick={sendToClose}
            className={
              !isLocation || !coordinates ? "log-save-disable" : "log-save"
            }
            disabled={!isLocation || !coordinates}
          >
            {label ? label : "Save and Log Your Visit"}
          </button>
        )}
      </div>
    </div>
  );
};

export default LogVisit;
