import { AxiosProgressEvent } from "axios";
import http from "./httpService";

export const addHospital = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/manage/hospitals/add/new", data, options);
};

export const addFacilitySize = (data: any) => {
  return http.post("/facility/size/add/new", data);
};

export const addFacilityType = (data: any) => {
  return http.post("/facility/add/new", data);
};

export const getAllHospitals = (city: string, id: string) => {
  return http.get("/manage/verified/hospitals/" + city + "/" + id);
};

export const getPreActiveHospitals = () => {
  return http.get("/hospital_success/success/pre-active");
};

export const getDangerHospitals = () => {
  return http.get(
    `/hospital_success/success/hospitals/get-hospitals?status=Danger`
  );
};

export const getFacilityTypes = () => {
  return http.get("/facility/all");
};

export const getFacilitySizes = () => {
  return http.get("/facility/size/all");
};

export const getAllFeedback = (hospitalId: string) => {
  return http.get("/manage/detailed/verified/hospital/" + hospitalId);
};

export const getHospitalOrderHistory = (
  refId: string | Blob,
  hospitalId: string | Blob
) => {
  const formData = new FormData();
  formData.append("ref_id", refId);
  formData.append("hospital_id", hospitalId);

  return http.post("/manage/details/pending", formData);
};

export const getHospitalCancelledOrder = (filters: {
  page: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  hospital_id?: string;
}) => {
  const queryParams = new URLSearchParams();
  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }

  if (
    filters.hospital_id !== null &&
    filters.hospital_id !== undefined &&
    filters.hospital_id !== ""
  ) {
    queryParams.append("hospital_id", filters.hospital_id);
  }
  return http.get(`/manage/cancelled/orders?${queryParams.toString()}`);
};
export const getHospitalRating = (filters: {
  page: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  hospital_id?: string;
}) => {
  const queryParams = new URLSearchParams();
  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("user_id", filters.user_id);
  }

  if (
    filters.hospital_id !== null &&
    filters.hospital_id !== undefined &&
    filters.hospital_id !== ""
  ) {
    queryParams.append("hospital_id", filters.hospital_id);
  }
  return http.get(`/manage/hospitals/ratings?${queryParams.toString()}`);
};

export const updateHospitalAddress = (data: {
  address: string;
  hospital_id: string;
}) => {
  return http.put(`/admin/hospitals/update/${data.hospital_id}`, {
    address: data.address,
  });
};

const hospitalService = {
  addFacilitySize,
  addFacilityType,
  addHospital,
  getAllFeedback,
  getAllHospitals,
  getFacilitySizes,
  getFacilityTypes,
  getDangerHospitals,
  getPreActiveHospitals,
  getHospitalOrderHistory,
  getHospitalCancelledOrder,
  getHospitalRating,
  updateHospitalAddress,
};

export default hospitalService;
