import React from "react";

import { ComponentProps } from "../../../../../ts-utils/types";

import LoadingButton from "../../../../../components/common/loadingButton";
import Header from "../../../../../components/blocks/header";
import PitchDetails from "./pitchDetails";
import { useNavigate } from "react-router-dom";

const SaleType: React.FC = (props: ComponentProps) => {
  const { selectedPitch, loading } = props;
  const navigation = useNavigate();

  const navigate = (path: string) => {
    navigation(path);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => navigate("/pipeline?content=pitches")}
        className="back"
      >
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <Header title={`${selectedPitch?.name ?? ""}`} />

      <LoadingButton visible={loading} message="Please wait" />
      {!loading && <PitchDetails {...props} />}
    </React.Fragment>
  );
};

export default SaleType;
