import pipelineService from "../services/pipeline.service";
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";

import { AxiosResponse } from "axios";
import toastService from "../services/toastService";
import { getPrivileges } from "../services/authService";

const permission = getPrivileges();

export const useGetPipeline = (
  id: string | undefined,
  city?: string | undefined
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["pipeline", city, id],

    queryFn: async () => {
      const response = (await pipelineService.getPipelineData(
        city,
        id
      )) as AxiosResponse<any[]>;
      return response;
    },
    enabled: permission.exodus,
  });

  const pipeline: any = data?.data || [];

  return { isError, isSuccess, pipeline, isPending };
};

export const useReadyToPitch = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: {
      hospital_id: string | number | null;
      user_id: string;
      pitched: number;
    }) => {
      const response = await pipelineService.readyToPitch(data);
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success(data?.data?.message);
      queryClient.refetchQueries({ queryKey: ["pipeline"] });

      queryClient.refetchQueries({ queryKey: ["dashboard"] });
    },
    onError: (error: { response: { data: { message: string } } }) => {
      toastService.error(
        error.response.data.message || "Failed to update pipeline"
      );
    },
  });

  return { mutate, isPending, error };
};

export const useNerveMessage = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["nerve-message", filters],
    queryFn: async () => {
      const response = await pipelineService.nerveUsageMessages(filters);
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

  const messages: any = data?.data || [];
  const paginate: any = data?.meta || {};

  return { isError, isSuccess, messages, isPending, paginate };
};
