import { LuFileSpreadsheet } from "react-icons/lu";

interface CardProps {
  title: string;
  value?: string | number;
  image?: string;
  qty?: any;
}

const CardDetails = ({ title, value, image, qty }: CardProps) => {
  return (
    <div className="">
      <div className=" h-20 flex rounded shadow justify-between items-center p-4 ">
        <div className="flex items-center flex-grow">
          <LuFileSpreadsheet className="w-6 h-6 text-blue-500" />
          {qty && (
            <div className="font-bold text-gray-500 text-1xl ml-2">
              {qty} X{" "}
            </div>
          )}
          <p className="font-bold text-gray-500 text-1xl ml-2">{title}</p>
        </div>
        <p className="font-bold text-1xl">{value}</p>
      </div>
    </div>
  );
};
const CardDetail = ({ title, value, image, qty }: CardProps) => {
  return (
    <div className="">
      <div className=" h-20 flex rounded shadow justify-between items-center p-4 ">
        <div className="flex items-center flex-grow">
          <img src={image} alt={title} className="w-6 h-6" />
          <p className="font-bold text-gray-500 text-1xl ml-2">{title}</p>
        </div>
        <p className="font-bold text-1xl">{value}</p>
      </div>
    </div>
  );
};

export { CardDetails, CardDetail };
