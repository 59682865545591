import React, { useMemo, useState } from "react";
import { useSetTarget } from "../../../../hooks/useDashboard";
import { useGetAllAgent } from "../../../../hooks/agent/useAgent";
import utilities from "../../../../utils/utilities";
import CAAForm from "./CAAForm";
import KAAForm from "./KAAForm";
import { Button } from "../../../../@/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../../../@/components/ui/card";
import { cn } from "../../../../@/lib/utils";
import { getPrivileges } from "../../../../services/authService";
import toastService from "../../../../services/toastService";
import { useNavigate } from "react-router-dom";

const SetTargets = () => {
  const navigate = useNavigate();
  const { createAgent } = getPrivileges();
  const { mutateSetTarget, isPending } = useSetTarget();
  const { agent } = useGetAllAgent(createAgent as boolean);
  const [selectedPosition, setSelectedPosition] =
    useState<string>("sales_lead");

  const filterCAAgents = useMemo(
    () =>
      agent
        ?.filter((agent) => agent.position === "sales_rep")
        .map((agent) => ({ id: agent.id, name: agent.username })) || [],
    [agent]
  );

  const filterKAAgents = useMemo(
    () =>
      agent
        ?.filter((agent) => agent.position === "sales_lead")
        .map((agent) => ({ id: agent.id, name: agent.username })) || [],
    [agent]
  );

  const handleCAASubmit = (data: any) => {
    mutateSetTarget(data, {
      onSuccess: (data) => {
        toastService.success(data.data.description);
        navigate("/home");
      },
      onError: (e: { response: { data: { message: string } } }) => {
        toastService.error(e.response.data.message);
      },
    });
  };

  const handleKAASubmit = (data: any) => {
    mutateSetTarget(data, {
      onSuccess: (data) => {
        toastService.success(data.data.description);
        navigate("/home");
      },
      onError: (e: { response: { data: { message: string } } }) => {
        toastService.error(e.response.data.message);
      },
    });
  };

  const currentMonthLabel: string = utilities.getMonthLabel();

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center">
          Set <span className="text-red-500">Targets</span> for{" "}
          {currentMonthLabel}
        </CardTitle>
        <CardDescription className="text-center">
          Enter the target values for the selected agent
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-center w-full gap-5 mb-6">
          <Button
            className={cn(
              selectedPosition === "sales_lead" ? "" : "bg-slate-500"
            )}
            onClick={() => setSelectedPosition("sales_lead")}
          >
            Key Account Agents
          </Button>
          <Button
            className={cn(
              selectedPosition === "sales_rep" ? "" : "bg-slate-500"
            )}
            onClick={() => setSelectedPosition("sales_rep")}
          >
            Customer Acquisition Agents
          </Button>
        </div>
        {selectedPosition === "sales_lead" ? (
          <KAAForm
            agents={filterKAAgents}
            isPending={isPending}
            onSubmit={handleKAASubmit}
          />
        ) : (
          <CAAForm
            agents={filterCAAgents}
            isPending={isPending}
            onSubmit={handleCAASubmit}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default SetTargets;
