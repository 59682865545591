import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import OrderCard from "./Card";
import SearchItems from "./SearchItems";
import { z } from "zod";
import { SubmitHandler } from "react-hook-form";

// Define the schema for form validation
const FormSchema = z.object({
  search: z.string().min(3, {
    message: "Search query must be at least 3 characters long",
  }),
});

// Define the form data type based on the schema
type FormData = z.infer<typeof FormSchema>;

export default function OrderCategory() {
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = useState<any>([]);

  const [searchParams, setSearchParams] = useSearchParams({
    subcategory: "",
  });
  const subCat = searchParams.get("subcategory");

  const handleCategoryClick = (
    category: {
      icon_url: string;
      id: string;
      name: string;
    }[],
    name: string
  ) => {
    setSubCategory(category);
    setSearchParams(
      (prev) => {
        prev.set("subcategory", name.toLowerCase());
        return prev;
      }
      // {
      //   replace: true,
      // }
    );
  };

  const handleSubCategoryClick = (subCategory: string) => {
    if (subCategory === "Buy") {
      navigate("/purchasing-orders/category/buy-equipment");
      return;
    } else if (subCategory === "Maintain") {
      // navigate("/purchasing-orders/category/equipment");
      return;
    }
    navigate(`/purchasing-orders/category/${subCategory.toLowerCase()}`, {
      state: { item: subCategory.toLowerCase() },
    });
  };

  const category = useMemo(
    () => [
      {
        title: "Essentials",
        image: "/images/greenglove.png",
        textColor: "text-bloodRed",
        iconOnTop: false,
        borderColor: "border-green-400",
        iconPosition: "ml-auto",
        textPosition: "flex-end",
        items: [
          {
            id: "1",
            name: "Gloves",
            slug: "Gloves",
            icon_url: "/images/consumableImages/armored-gauntlet.png",
          },
          {
            id: "2",
            name: "Syringes",
            slug: "Syringe",
            icon_url: "/images/consumableImages/syringe.png",
          },
          {
            id: "3",
            name: "Needles",
            slug: "Needle",
            icon_url: "/images/consumableImages/needle.png",
          },
          {
            id: "4",
            name: "Sutures",
            slug: "Sutures",
            icon_url: "/images/consumableImages/Stitch.png",
          },
        ],
      },

      {
        title: "Consumables",
        image: "/images/greenwheelchair.png",
        textColor: "text-consumableGreen",
        iconOnTop: false,
        borderColor: "border-green-400",
        iconPosition: "ml-auto",
        textPosition: "flex-end",
        items: [
          {
            icon_url:
              "https://lab.lifebank.ng/labx/api/v1/orders/image/stock-icons/more.png",
            id: "4",
            name: "Other",
            slug: "Other",
          },
          {
            icon_url:
              "https://lab.lifebank.ng/labx/api/v1/orders/image/stock-icons/Infusion.png",
            id: "10",
            name: "Infusion",
            slug: "Infusion",
          },
          {
            icon_url:
              "https://lab.lifebank.ng/labx/api/v1/orders/image/stock-icons/Oncology.png",
            id: "11",
            name: "Oncology ",
            slug: "Oncology",
          },
        ],
      },
      {
        title: "Device(Use Every day)",
        image: "/images/greenodomackine.png",
        textColor: "text-quipYellow",
        iconOnTop: false,
        borderColor: "border-green-400",
        iconPosition: "ml-auto",
        textPosition: "flex-end",
        items: [
          {
            icon_url: "/images/equipmentImages/Euro.png",
            id: "5",
            name: "Buy",
            slug: "Buy",
          },
          {
            icon_url: "/images/equipmentImages/Wrench.png",
            id: "6",
            name: "Maintain",
            slug: "Maintain",
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    if (subCat) {
      const categoryItem = category.find(
        (item) => item.title.toLowerCase() === subCat
      );
      if (categoryItem) {
        setSubCategory(categoryItem.items);
      }
    }
  }, [category, subCat]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    handleSubCategoryClick(data.search);
  };
  return (
    <div className="">
      <div className="mb-3 h-14">
        <SearchItems FormSchema={FormSchema} onSubmit={onSubmit} />
      </div>
      {/* <div className="flex justify-between items-center">
        <h1 className="text-xl text-black font-bold">Categories</h1>
        <button
          className="text-xs text-bloodRed font-bold"
          onClick={() => {
            setSearchParams(
              (prev) => {
                prev.delete("subcategory");
                return prev;
              }
              // {
              //   replace: true,
              // }
            );
            setSubCategory([]);
          }}
        >
          Clear
        </button>
      </div> */}
      <div
        className={
          subCategory.length > 0 && subCat
            ? "grid grid-cols-2 gap-4"
            : "flex flex-col lg:flex-row w-full gap-5"
        }
      >
        <>
          {subCategory.length > 0 && subCat
            ? subCategory.map(
                (category: {
                  id: string;
                  name: string;
                  slug: string;
                  icon_url: string | undefined;
                }) => {
                  return (
                    <OrderCard
                      key={category.id}
                      hoverClass={"white"}
                      width="w-full"
                      title={category.name}
                      image={category.icon_url}
                      textColor="text-bloodRed"
                      iconOnTop={true}
                      borderColor="border-green-400"
                      iconPosition="mx-auto"
                      textPosition="mx-auto"
                      height="220px"
                      onClick={() => handleSubCategoryClick(category.slug)}
                    />
                  );
                }
              )
            : category.map(
                (
                  item: {
                    title: string;
                    image: string;
                    textColor: string;
                    iconOnTop: boolean;
                    borderColor: string;
                    iconPosition: string;
                    textPosition: string;
                    items: {
                      icon_url: string;
                      id: string;
                      name: string;
                      slug: string;
                    }[];
                  },
                  index
                ) => {
                  return (
                    <div key={index} className="flex w-full">
                      <OrderCard
                        hoverClass={"white"}
                        width="w-full"
                        title={item.title}
                        image={item.image}
                        textColor={item.textColor}
                        iconOnTop={item.iconOnTop}
                        borderColor={item.borderColor}
                        iconPosition={item.iconPosition}
                        textPosition={item.textPosition}
                        height="520px"
                        onClick={() =>
                          handleCategoryClick(item.items, item.title)
                        }
                      />
                    </div>
                  );
                }
              )}
        </>
      </div>
    </div>
  );
}
