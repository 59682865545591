import React, { Dispatch, SetStateAction } from "react";

import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Dialog from "@radix-ui/react-dialog";
import { useNavigate } from "react-router-dom";
import utilities from "../../../../utils/utilities";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { useLogDisbursement } from "../../../../hooks/logistics/useLogistics";
import ReactSelect from "../../../../components/common/ReactSelect";

const logisticSchema = z.object({
  amount: z
    .union([z.string({ invalid_type_error: "Amount is required" }), z.number()]) // Allow both string and number
    .refine(
      (value) => {
        if (typeof value === "string") {
          return /^[0-9]*\.?[0-9]*$/.test(value); // Regex for valid number string
        }
        return value > 0; // Ensure number is positive
      },
      {
        message: "Amount must be a valid number",
      }
    )
    .transform((value) => (value ? parseFloat(value as string) : null)) // Transform to number or null
    .refine((value) => value !== null && value > 0, {
      message: "Amount must be a positive number",
    }),
  proposed_start_date: z
    .string()
    .refine((value) => value !== "", { message: "Start Date is required" }),
  proposed_end_date: z
    .string()
    .refine((value) => value !== "", { message: "End Date is required" }),
  note: z.string().optional(),
  agent: z.string({ message: "Agent is Required" }).min(1, "Agent is Required"),
});

type LogisticFormData = z.infer<typeof logisticSchema>;
export default function NewDisbursement({
  createDisbursement,
  setCreateDisbursement,
  agents,
}: {
  createDisbursement: boolean;
  setCreateDisbursement: Dispatch<SetStateAction<boolean>>;
  agents: Array<{ id: string; name: string }>;
}) {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const { mutate, isPending } = useLogDisbursement();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LogisticFormData>({
    resolver: zodResolver(logisticSchema),
  });

  const onSubmit = async (data: LogisticFormData) => {
    const start_date = utilities.formatDate(
      data.proposed_start_date,
      "YYYY-MM-DD HH:mm:ss"
    );
    const end_date = utilities.formatDate(
      data.proposed_end_date,
      "YYYY-MM-DD HH:mm:ss"
    );
    const payload = {
      userid: data.agent,
      disburserid: user?.id,
      amount: data.amount,
      proposed_start_date: start_date,
      proposed_end_date: end_date,
      note: data.note,
    };

    mutate(payload, {
      onSuccess() {
        setCreateDisbursement(false);
        navigate("/logistic-tracker");
      },
    });
  };

  return (
    <div>
      {/* Estimate Form */}
      <Dialog.Root
        open={createDisbursement}
        onOpenChange={setCreateDisbursement}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => {}}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 text-start ">
                <Dialog.Title className="text-lg font-medium text-gray-800 "></Dialog.Title>
                <div className="flex items-center justify-center p-4">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <Controller
                      name="agent"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          placeholder="Select Agent"
                          //   defaultValue={field.value as string}
                          options={agents.map((agent) => ({
                            value: agent.id,
                            label: agent.name,
                          }))}
                          onChange={(selectedOption) => {
                            console.log(selectedOption);
                            field.onChange(selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.agent && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.agent.message}
                      </p>
                    )}
                    <div>
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <input
                            {...field}
                            value={field.value === null ? "" : field.value}
                            placeholder="Amount"
                            className={`w-full p-3 border ${
                              errors.amount
                                ? "border-red-500"
                                : "border-gray-300"
                            } rounded-md focus:ring-2 focus:ring-indigo-500`}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (inputValue === "") {
                                field.onChange(null);
                              } else {
                                const parsedValue = parseFloat(inputValue);

                                if (!isNaN(parsedValue)) {
                                  field.onChange(parsedValue);
                                }
                              }
                            }}
                          />
                        )}
                      />
                      {errors.amount && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.amount.message}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col md:flex-row gap-2">
                      <div className="">
                        <label htmlFor="start_date">Start Date</label>
                        <input
                          {...register("proposed_start_date", {
                            value: new Date().toISOString().split("T")[0],
                          })}
                          type="date"
                          placeholder="Start Date"
                          className="w-full p-3 border border-gray-300 rounded-md bg-gray-100"
                        />{" "}
                        {errors.proposed_start_date && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.proposed_start_date.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="end_date">End Date</label>
                        <input
                          {...register("proposed_end_date", {
                            value: new Date().toISOString().split("T")[0],
                          })}
                          type="date"
                          placeholder="End Date"
                          className="w-full p-3 border border-gray-300 rounded-md bg-gray-100"
                        />{" "}
                        {errors.proposed_end_date && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.proposed_end_date.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <textarea
                        {...register("note")}
                        placeholder="Feedback and Contact Info"
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500"
                      />
                      <p className="italic text-xs opacity-80 text-start">
                        Provide feedback on your experience at the location and
                        also provide contact information
                      </p>
                    </div>
                    <button
                      disabled={isPending}
                      type="submit"
                      className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md transition-all"
                    >
                      {isPending ? "Submitting" : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
