import React, { useState } from "react";
import Button from "../../common/button";
import { success, error } from "../../../services/toastService";
import { Controller, useForm } from "react-hook-form";
import RadioButton from "../../common/radio";
import BackButton from "../backbutton";
import NewlyActiveMasteryCallLog from "./NewlyActiveMasteryCallLog";
import CustomField from "../../common/CustomField";
import {
  useCreateCallLog,
  useCompleteSchedule,
} from "../../../hooks/useHospital";

import utilities from "../../../utils/utilities";
import LogVisit from "../../modals/logVisit";

import { useGlobalContext } from "../../../contexts/globalContext";

const normalCallWeeksInfo = generateWeeksInfo(4);
const notNormalCallWeeksInfo = generateWeeksInfo(12);

function generateWeeksInfo(weeksCount: number) {
  return Array.from({ length: weeksCount }, (_, index) => ({
    title: `Week ${index + 1}`,
    key: `week${index + 1}`,
  }));
}

type Props = {
  setShowMastery: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
  procurementCycle: null | string | number;
  handleClose: () => void;
};

export default function MasterNewlyActive({
  setShowMastery,
  procurementCycle,
  refId,
  handleClose,
}: Props) {
  const { user } = useGlobalContext();
  const { mutateCallLog, isPending } = useCreateCallLog(refId);
  const { completeSchedule } = useCompleteSchedule();
  const [logData, setLogData] = useState({
    location: "",
  });
  const [position, setPosition] = useState<any>(false);
  const [callInfo, setCallInfo] = useState<{
    show: boolean;
    key:
      | "weekOne"
      | "weekTwo"
      | "weekThree"
      | "weekFour"
      | "weekFive"
      | "weekSix"
      | "weekSeven"
      | "weekEight"
      | "weekNine"
      | "weekTen"
      | "weekEleven"
      | "weekTwelve";
  }>({ show: false, key: "weekOne" });

  const {
    handleSubmit: submitCallInfo,
    control,
    formState: { errors: errorsCallInfo },
    reset,
  } = useForm<any>({
    defaultValues: {
      date: "",
      hospital_agent: "",
      questions: "",
      placed_order: "",
      satisfied_with_nerve: "",
      late_delivery: "",
      complaints: "",
    },
  });

  const onSubmitCallInfo = (data: any) => {
    const date = utilities.formatDate(data.date, "YYYY-MM-DD HH:mm:ss");
    const payload = {
      customer_success_rep_ref: user?.id,
      hospital_agent: data.hospital_agent,
      call_date: date,
      actiontype: "newly-active",
      placedorder: data.placed_order,
      satisfied: data.satisfied_with_nerve,
      latedelivery: data.late_delivery,
      complaints: data.complaints,
      hospital_ref: refId,
    };
    mutateCallLog(payload, {
      onSuccess: () => {
        success("Call info submitted", "call-info");
        setCallInfo(
          (prev) =>
            (prev = {
              show: false,
              key: "weekOne",
            })
        );
        reset();
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred", "call-info");
      },
    });
  };

  const { handleSubmit, control: submitControl } = useForm({
    defaultValues: {
      revenue: "",
    },
  });

  const onSubmit = (data: any) => {
    const location = [];
    if (logData.location) {
      location.push(parseFloat(logData.location.split(",")[0]));
      location.push(parseFloat(logData.location.split(",")[1]));
    }

    const payload = {
      goal_met: +data.revenue,
      geo_location: location,
      hospital_ref: refId,
    };

    completeSchedule(payload, {
      onSuccess: () => {
        success(
          "newly-active mastery successful",
          "newly-active-mastery-success"
        );
        handleClose();
      },
      onError: (err) => {
        console.log(err);
        error("An error occurred", "newly-active-mastery-error");
      },
    });
  };
  return (
    <div>
      {callInfo.show ? (
        <NewlyActiveMasteryCallLog
          isLoading={isPending}
          setCallInfo={setCallInfo}
          control={control}
          errors={errorsCallInfo}
          objKey={callInfo.key}
          submit={{
            submitCallInfo: submitCallInfo(onSubmitCallInfo),
          }}
        />
      ) : (
        <React.Fragment>
          <BackButton
            goBack={() => {
              setShowMastery(false);
            }}
          />
          <div className="my-8">
            <CustomField
              title="Confirm Phone Calls"
              value={
                <div
                  className={
                    procurementCycle === "monthly"
                      ? "grid grid-cols-4 gap-2 max-w-4xl mb-3"
                      : "grid grid-cols-6 gap-2 max-w-4xl  mb-3"
                  }
                >
                  {procurementCycle === "monthly" ? (
                    <>
                      {" "}
                      {normalCallWeeksInfo.map((call) => {
                        return (
                          <Button
                            key={call.key}
                            onClick={() =>
                              setCallInfo({
                                show: true,
                                key: call.key as
                                  | "weekOne"
                                  | "weekTwo"
                                  | "weekThree"
                                  | "weekFour",
                              })
                            }
                            label={call.title}
                            type="button"
                            style={{ marginTop: "0px" }}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {notNormalCallWeeksInfo.map((call) => {
                        return (
                          <Button
                            key={call.key}
                            onClick={() =>
                              setCallInfo({
                                show: true,
                                key: call.key as
                                  | "weekOne"
                                  | "weekTwo"
                                  | "weekThree"
                                  | "weekFour"
                                  | "weekFive"
                                  | "weekSix"
                                  | "weekSeven"
                                  | "weekEight"
                                  | "weekNine"
                                  | "weekTen"
                                  | "weekEleven"
                                  | "weekTwelve",
                              })
                            }
                            label={call.title}
                            type="button"
                            style={{ marginTop: "0px" }}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              }
            />

            <CustomField
              title="Confirm Hospital Visit (GPS Log Location)"
              value={
                <Button
                  onClick={() => {
                    setPosition(true);
                  }}
                  label="Log Your Location"
                  type="button"
                  style={{ marginTop: "0px", width: "40%" }}
                />
              }
            />
            {position && (
              <>
                <LogVisit
                  handleClose={() => {
                    setPosition(false);
                  }}
                  changePage={() => setPosition(false)}
                  setLogData={setLogData}
                />
              </>
            )}
            {logData.location && position === false && (
              <CustomField
                title="Confirm if One Month Goals for Hospital were met - Revenue Target"
                value={
                  <Controller
                    control={submitControl}
                    name="revenue"
                    render={({ field: { onChange, value, name } }) => (
                      <div className="flex justify-end gap-4">
                        <RadioButton
                          name={name}
                          onChange={onChange}
                          value="1"
                          checked={value === "1"}
                          message="Yes"
                        />
                        <RadioButton
                          name={name}
                          onChange={onChange}
                          value="0"
                          checked={value === "0"}
                          message="No"
                        />
                      </div>
                    )}
                  />
                }
              />
            )}
          </div>
          <Button
            disabled={logData.location && !position ? false : true}
            type="button"
            onClick={handleSubmit(onSubmit)}
            label="Finish Actions"
          />
        </React.Fragment>
      )}
    </div>
  );
}
