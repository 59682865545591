import { AspectRatio } from "../../../../@/components/ui/aspect-ratio";
import { TiStar } from "react-icons/ti";
import { AiOutlineShareAlt } from "react-icons/ai";
import { GiTicket } from "react-icons/gi";
import { CalendarDays } from "lucide-react";
import { Clock4 } from "lucide-react";
import { Button } from "../../../../@/components/ui/button";
import MapView from "../../../../components/map/index";
import dayjs from "dayjs";
import { useRef, useEffect } from "react";

const formatTimeToAMPM = (
  timeString:
    | {
        split: (arg0: string) => {
          (): any;
          new (): any;
          map: { (arg0: NumberConstructor): [any, any]; new (): any };
        };
      }
    | undefined
) => {
  if (timeString === undefined) return;
  const [hours, minutes] = timeString.split(":").map(Number);

  // Construct a dayjs object with the provided hours and minutes
  const time = dayjs().set("hour", hours).set("minute", minutes);

  // Format the time to AM/PM format using dayjs
  const formattedTime = time.format("hh:mm A");

  return formattedTime;
};
export default function EventDetails({
  eventDetail,
  baseUrl,
}: {
  eventDetail: any;
  baseUrl: string;
}) {
  // let address = "";
  // let latitude = "";
  // let longitude = "";

  // if (eventDetail && eventDetail?.location.length > 0) {
  //   const parts = eventDetail?.location?.split("|");
  //   parts.forEach((part: string) => {
  //     const [key, value] = part.split(":");

  //     if (key === "address") {
  //       const formattedValue = value
  //         .split(",") // Split at commas
  //         .map((part) => part.split(/(?=[A-Z])/).join(" ")) // Split at capital letters and join with space
  //         .join(", "); // Join with comma and space
  //       address = formattedValue;
  //     } else if (key === "latitude") {
  //       latitude = value;
  //     } else if (key === "longitude") {
  //       longitude = value;
  //     }
  //   });
  // }
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto to shrink if needed
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height to match content
    }
  }, [eventDetail?.description]);  

  const formattedAddress = eventDetail?.location
    .split(",")
    .map((part: string) => part.split(/(?=[A-Z])/).join(" "))
    .join(", ");

  let mapData = [];
  mapData.push({
    latitude: parseFloat(eventDetail?.latitude),
    longitude: parseFloat(eventDetail?.longitude),
    address: formattedAddress,
  });

  return (
    <div className="space-y-20 flex flex-col mt-5">
      <h2 className="text-2xl">Event Details</h2>
      <div className="mx-auto w-full">
        {" "}
        <img
          src={`https://${baseUrl}${eventDetail?.header}`}
          alt="events"
          className="rounded-md w-full object-contain"
        />
      </div>

      <div className="flex justify-between">
        <h2 className="text-2xl font-semibold capitalize">
          {eventDetail?.name}
        </h2>
        <div className="flex gap-3">
          <Button
            variant="ghost"
            size="icon"
            className="bg-white rounded-full flex items-center"
          >
            <TiStar className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="bg-white rounded-full flex items-center"
            onClick={() => {
              window.open(`https://${eventDetail?.url}`, "_blank");
            }}
          >
            <AiOutlineShareAlt className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <div className="flex justify-between flex-wrap space-y-5 items-center">
        <div className="space-y-2">
          <h2 className="text-2xl font-semibold">Date and Time</h2>
          <div className="space-y-2">
            <p className="flex gap-2 items-center">
              <CalendarDays />
              {dayjs(new Date(eventDetail?.eventdate)).format("YYYY-MM-DD")}
            </p>
            <p className="flex gap-2 items-center">
              <Clock4 />
              {formatTimeToAMPM(eventDetail?.startime)} -{" "}
              {formatTimeToAMPM(eventDetail?.enddate)}
            </p>
          </div>
        </div>

        <div className="flex">
          <Button className="bg-yellow-400 text-black font-semibold hover:bg-yellow-400">
            <GiTicket className="mr-2 h-4 w-4" />
            <span className="capitalize mr-1">{eventDetail?.type} </span> event
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
        </div>

        <div className="flex mb-4">
          <p>{formattedAddress}</p>
        </div>
        <div className="max-w-5xl mx-auto w-full z-10">
          <AspectRatio ratio={16 / 6}>
            {eventDetail && <MapView mapData={mapData} />}
          </AspectRatio>
        </div>
      </div>
      <div className="space-y-5">
        <div>
          <h2 className="text-2xl font-semibold">Event Description</h2>
        </div>

        <div className="flex">
          <p>{eventDetail?.description}</p>
        </div>
      </div>
    </div>
  );
}
