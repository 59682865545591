import { useMutation  } from "@tanstack/react-query";
import marketingService from "../../services/marketing.service";

export const useSendAcquisition = () => {
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.sendAcquisition(data);
      return response;
    },
  });

  return { mutate, isPending, error };
};
