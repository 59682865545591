import logisticService from "../../services/logistic.service";
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";

import { AxiosResponse } from "axios";
import toastService from "../../services/toastService";
import { getPrivileges } from "../../services/authService";

const permission = getPrivileges();
export const useGetLogisticsExpenses = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  proposed_date: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: [
      "logistic-expenses",
      filters.page,
      filters.limit,
      filters.name,
      filters.proposed_date,
    ],
    enabled: !permission.createAgent,
    queryFn: async () => {
      const response: any = (await logisticService.logisticsExpenses(
        filters
      )) as AxiosResponse<any[]>;
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

  const expenses = data?.data;
  const paginate: any = data?.meta || {};
  return { expenses, isError, isSuccess, isPending, paginate };
};

export const useRequestExpense = () => {
  const queryClient = useQueryClient();
  const { isError, isSuccess, isPending, mutate } = useMutation({
    mutationFn: async (data: any) => {
      const response = (await logisticService.requestExpense(
        data
      )) as AxiosResponse<any[]>;
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success(data?.data?.message);
      queryClient.refetchQueries({
        queryKey: ["logistic-expenses"],
      });
    },
    onError: (data: any) => {
      toastService.error(data.response.data?.message);
    },
  });

  return { isError, isSuccess, isPending, mutate };
};

export const useGetLogisticEstimatedCost = (userId: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["logistic", userId],
    enabled: !!userId,
    queryFn: async () => {
      const response: any = (await logisticService.logisticEstimatedCost(
        userId
      )) as AxiosResponse<any[]>;
      return response.data;
    },
  });

  const cost = data?.data;

  return { cost, isError, isSuccess, isPending };
};

export const useSettleExpense = () => {
  const queryClient = useQueryClient();
  const {
    isError,
    isSuccess,
    isPending,
    mutate: mutateSettleExpense,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = (await logisticService.settleExpense(
        data
      )) as AxiosResponse<any[]>;
      return response;
    },
    onSuccess: (data: any) => {
      if (data?.data?.success) {
        toastService.success(data?.data?.message);
      } else toastService.error(data?.data?.message);
      queryClient.refetchQueries({
        queryKey: ["logistic"],
      });
    },
  });
  return { isError, isSuccess, isPending, mutateSettleExpense };
};

export const useGetAllDisbursement = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  proposed_date: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: [
      "logistic-disbursement",
      filters.page,
      filters.limit,
      filters.name,
      filters.proposed_date,
    ],
    enabled: permission.createAgent,
    queryFn: async () => {
      const response: any = await logisticService.getAllDisbursement(filters);
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

  const disbursement = data?.data;
  return { disbursement, isError, isSuccess, isPending };
};
export const useGetTotalAmountDisbursed = (filters: {
  user_id: string;
  proposed_date: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["logistic-total", filters.proposed_date, filters.user_id],
    // enabled: !!userId,
    queryFn: async () => {
      const response: any = await logisticService.getTotalAmountDisbursed(
        filters
      );
      return response.data;
    },
  });

  const totalAmount = data?.data;
  return { totalAmount, isError, isSuccess, isPending };
};
export const useGetTotalAmountSpent = (filters: {
  user_id: string;
  proposed_date: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["logistic-spent", filters.proposed_date, filters.user_id],
    // enabled: !!userId,
    queryFn: async () => {
      const response: any = await logisticService.getTotalAmountSpent(filters);
      return response.data;
    },
  });

  const totalAmountSpent = data?.data;
  return { totalAmountSpent, isError, isSuccess, isPending };
};

export const useLogDisbursement = () => {
  const queryClient = useQueryClient();
  const { isError, isSuccess, isPending, mutate } = useMutation({
    mutationFn: async (data: any) => {
      const response = (await logisticService.logDisbursement(
        data
      )) as AxiosResponse<any[]>;
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success(data?.data?.message);
      queryClient.refetchQueries({
        queryKey: ["logistic-disbursement"],
      });
    },
  });

  return { isError, isSuccess, isPending, mutate };
};
