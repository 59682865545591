import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";

import { IoCopyOutline } from "react-icons/io5";
import { useSendMessage } from "../../../../hooks/useExodus";
import { success, error } from "../../../../services/toastService";
import ExodusLoading from "../../../../components/exodusComponent/ExodusLoding";
import { GoDash } from "react-icons/go";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { Renderable, ValueFunction, Toast } from "react-hot-toast";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import { Button } from "../../../../@/components/ui/button";
import LogExpenses from "../../../../components/modals/LogExpenses";
const ExodusCall = () => {
  const navigate = useNavigate();
  const { user } = useGlobalContext();
  const { mutateSendMessage, isPending } = useSendMessage();
  const location = useLocation();
  const { name, id, contactType, cord, address } = location?.state;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [note, setNote] = useState("");
  const [selectedOption, setSelectedOption] = useState("Needs Nerve Training");

  const [noteRequiredError, setNoteRequiredError] = useState(false);
  const [fileRequiredError, setFileRequiredError] = useState(false);
  const [optionRequiredError, setOptionRequiredError] = useState(false);

  const [modal, setModal] = useState(false);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFileRequiredError(false);
  };

  const handleNoteChange = (event: any) => {
    setNote(event.target.value);
    setNoteRequiredError(false);
  };

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    setOptionRequiredError(false);
  };

  const dropDown = [
    {
      name: "Needs Nerve Training",
    },
    {
      name: "To learn Nerve",
    },
    {
      name: "Re-pitch",
    },
    {
      name: "Will place an order",
    },
  ];

  const handleSuccessModal = async () => {
    if (!note) {
      setNoteRequiredError(true);
      return;
    }
    if (!selectedOption) {
      setOptionRequiredError(true);
      return;
    }
    // if (!selectedFile) {
    //   setFileRequiredError(true);
    //   return;
    // }

    const formData = new FormData();

    formData.append("log_note", note);
    formData.append("meeting_result", selectedOption);

    if (selectedFile) {
      formData.append("log_file", selectedFile);
    }
    formData.append("rep_id", user?.id as string);
    formData.append("hospital_id", id);
    formData.append("mode_of_contact", contactType);

    mutateSendMessage(formData, {
      onSuccess: (data: {
        data: { description: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        setModal(true);
        success(data?.data?.description, "success");
        localStorage.removeItem("exodus_order_id");
      },
      onError: (err: any) => {
        error(err?.response?.data?.description, "error");
      },
    });
  };
  const [createExpense, setCreateExpense] = useState(false);

  return (
    <div className="w-full">
      <ExodusLoading
        message={"Submitting data... Please wait"}
        visible={isPending}
      />
      <TransitionsModal open={modal} setOpen={setModal}>
        <div className="flex flex-col mx-auto mb-4 items-center h-3/5 justify-center">
          <p className="text-2xl mb-8 text-center">
            Your {contactType} has been logged successfully
          </p>
          <Button
            variant={"outline"}
            className="border-2 !border-blue-500 text-blue-500 font-semibold p-2 !px-4"
            onClick={() => {
              setModal(false);

              contactType === "visit"
                ? setCreateExpense(true)
                : navigate(`/exodus`);
              // setCreateExpense(true);
            }}
          >
            {contactType === "visit" ? "Log Expense" : "Okay"}
          </Button>
        </div>
      </TransitionsModal>
      {/* {modal && <Modal text="Your note has been submitted successfully" />} */}
      <ExodusHeader
        Text={`Log a ${contactType}`}
        subText={`| Exodus Arena > Log a ${contactType} > ${name}`}
      />

      <>
        <div className="w-10/12 flex flex-row m-4 items-center">
          <p className="text-8xl text-[#49209F]">
            <GoDash />
          </p>
          <p className="text-2xl capitalize">
            Log a {contactType} easily on Exodus Arena
          </p>
        </div>

        <div className="w-11/12 mx-auto bg-[#FFFFFF] shadow-white shadow-lg mb-5 p-4">
          <div className="mx-auto w-11/12 p-1">
            <p className="pb-4 text-2xl">Log notes</p>
            <textarea
              name="postContent"
              placeholder="log notes"
              className="w-full h-[150px] p-2 border border-black shadow-white shadow-lg rounded"
              value={note}
              onChange={handleNoteChange}
            />
            {noteRequiredError && (
              <p className="text-red-500">Note is required</p>
            )}
          </div>

          <div className="w-11/12 mx-auto mb-4">
            <label
              htmlFor="fileInput"
              className="flex items-center cursor-pointer"
            >
              <IoCopyOutline size={25} />
              <span className="ml-3 text-1xl">
                Attach File <span className="italic"> (optional)</span>{" "}
              </span>
              <p className="text-[#4D26A0] pl-2 pr-2">
                {selectedFile && selectedFile?.name}{" "}
              </p>
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/jpeg, image/png, .pdf"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {fileRequiredError && (
              <p className="text-red-500">File is required</p>
            )}
          </div>
          <div className="w-11/12 mx-auto">
            <p className="mb-2"> File meeting result</p>
            <select
              className="w-full border border-black rounded p-2"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              {dropDown.map((ele) => {
                return (
                  <option
                    key={ele.name}
                    className="border border-black rounded"
                  >
                    {ele.name}
                  </option>
                );
              })}
            </select>
            {optionRequiredError && (
              <p className="text-red-500">Meeting result is required</p>
            )}

            <div className="w-full mt-4 ">
              <button
                className="w-full text-2xl font-bold text-[white] bg-[#4D26A0] rounded p-2"
                onClick={handleSuccessModal}
              >
                Submit Note
              </button>
            </div>
          </div>
        </div>
      </>
      {/* Estimate Form */}
      <LogExpenses
        note={note}
        destinationGeoCode={cord}
        destinationAddress={address}
        createExpense={createExpense}
        setCreateExpense={setCreateExpense}
        hospital_id={id}
      />
    </div>
  );
};

export default ExodusCall;
