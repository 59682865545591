import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import utilities from "../../../../utils/utilities";
import { SelectOption } from "../../../../ts-utils/interfaces";
import { useGetCities } from "../../../../hooks/useDashboard";
import { useGlobalContext } from "../../../../contexts/globalContext";
import { Button } from "../../../../@/components/ui/button";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { ReloadIcon } from "@radix-ui/react-icons";

const FormSchema = z
  .object({
    username: z.string().min(3, {
      message: "Username must be at least 3 characters long",
    }),
    email: z.string().email(),
    password: z.string().min(6, {
      message: "Password must be at least 6 characters",
    }),
    confirmPassword: z.string().min(6, {
      message: "Password must be at least 6 characters",
    }),
    position: z.string().min(3, {
      message: "Position must be at least 3 characters long",
    }),
    // phone: z.string().transform((data) => Number(data)),
    city: z
      .string()
      .trim()
      .min(3, {
        message: "City must be at least 3 characters long",
      })
      .regex(/^[a-zA-Z_ ]+$/, {
        message: "City must be a valid city",
      }),
    country: z
      .string()
      .trim()
      .min(3, {
        message: "Country must be at least 3 characters long",
      })
      .regex(/^[a-zA-Z_ ]+$/, {
        message: "Country must be a valid country",
      }),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords did not match",
        path: ["confirmPassword"],
      });
    }
  });

export default function AddAgent({
  onSubmit,
  isPending,
}: {
  onSubmit: (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    position: string;
    city: string;
    country: string;
  }) => void;
  isPending: boolean;
}) {
  const { user } = useGlobalContext();
  const [country, setCountry] = useState(user?.country);
  const { cities } = useGetCities(country as string);

  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleConfirmPasswordVisibility = () =>
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
      email: "",
      confirmPassword: "",
      position: "",
      city: "",
      country: "",
    },
  });

  useEffect(() => {
    form.reset();
  }, [form]);

  const mapCountries = (): SelectOption[] => {
    return utilities.getCountries().map((country) => ({
      id: country.name.toLowerCase(),
      name: country.name,
    }));
  };

  const getCurrentUserPrivilegeIndex = (): number => {
    const index: number = utilities.getUserPrivilegeIndex(4);

    return index;
  };

  const mapPositions = (): SelectOption[] => {
    const privilegeIndex: number = getCurrentUserPrivilegeIndex();

    const allPositions: SelectOption[] = utilities.getAllPositions();
    return allPositions
      .filter((p, i) => i > privilegeIndex)
      .map((p) => {
        // if id is sales_rep change the name to Customer Acquisition agent
        if (p.id === "sales_rep")
          return { id: p.id, name: "Customer Acquisition Agent" };

        if (p.id === "sales_lead")
          return { id: p.id, name: "Key Account Agent" };

        return p;
      });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-4 px-2"
        autoComplete="off"
      >
        <div className="flex flex-col lg:flex-row w-full gap-3">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <>
                    {/* <input type="text" /> */}
                    <Input
                      {...field}
                      autoComplete="off"
                      aria-autocomplete="none"
                      placeholder="Username"
                      type="this is no type check"
                    />
                  </>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col lg:flex-row w-full gap-3">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full relative">
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type={isVisible ? "text" : "password"}
                    placeholder="Password"
                  />
                </FormControl>
                <FormMessage />
                <button
                  className="focus:outline-none absolute inset-y-11 right-2 flex items-center justify-center z-40"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <IoEyeSharp className="text-2xl text-default-400" />
                  ) : (
                    <IoEyeOffSharp className="text-2xl text-default-400" />
                  )}
                </button>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="w-full relative">
                <FormLabel>Confirm Password</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                </FormControl>
                <button
                  className="focus:outline-none absolute inset-y-11 right-2 flex items-center justify-center z-20"
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {isConfirmPasswordVisible ? (
                    <IoEyeSharp className="text-2xl text-default-400 " />
                  ) : (
                    <IoEyeOffSharp className="text-2xl text-default-400 " />
                  )}
                </button>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="position"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Position</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Position" />
                  </SelectTrigger>
                  <SelectContent position="item-aligned">
                    {mapPositions().map((position) => (
                      <SelectItem key={position.id} value={position.id}>
                        {position.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col lg:flex-row w-full gap-3">
          {" "}
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Country</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      setCountry(value);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Country" />
                    </SelectTrigger>
                    <SelectContent position="item-aligned">
                      {mapCountries().map((country) => (
                        <SelectItem key={country.id} value={country.id}>
                          {country.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select City" />
                    </SelectTrigger>
                    <SelectContent position="item-aligned">
                      {cities.map(
                        (city: { state_code: string; name: string }) => (
                          <SelectItem key={city.state_code} value={city.name}>
                            {city.name}
                          </SelectItem>
                        )
                      )}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-center ">
          <Button className="w-1/2" type="submit">
            {isPending && <ReloadIcon className="animate-spin mr-2 h-4 w-4" />}
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
}
