import React, { useCallback, useEffect, useState } from "react";
import { ComponentProps, Hospital, ApiResponse } from "../../ts-utils/types";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../ts-utils/interfaces";

import _ from "lodash";

import Conditional from "../blocks/conditional";
import LoadingButton from "../common/loadingButton";
import EditProspectForm from "../forms/editProspectForm";
import HospitalCadre from "../modals/hospitalCadre";
import Table from "../common/table";
import TableButton from "../blocks/tableButton";
import ValidateProspect from "../modals/validateProspect";

import toastService from "../../services/toastService";
import utilities from "../../utils/utilities";

import { getPrivileges } from "../../services/authService";
import { getUnprospectedHospitals } from "../../services/prospects.service";
import { error as toastError } from "../../services/toastService";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../contexts/globalContext";
import CustomPagination from "../common/pagination/Pagination";
import applyFilters from "../../helpers/urlState";

const UnprospectedTable: React.FC = (props: ComponentProps) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [unprospected, setUnprospected] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [prospectToEdit, setProspectToEdit] = useState<Hospital | null>(null);
  const [prospectToValidate, setProspectToValidate] = useState(null);
  const [prospectCadre, setProspectCadre] = useState("");
  const [prospectRef, setProspectRef] = useState("");

  const { activeCategory, onCountChange, search, status, searchKey } = props;
  const { city, user } = useGlobalContext();

  const pageSize: number = 10;

  const handleEditSelect = (hospital: Hospital): void => {
    setProspectToEdit(hospital);
  };

  const handleValidateSelect = (hospital: Hospital): void => {
    setProspectToValidate(hospital);
  };

  const handleProspectCadre = (hospital: Hospital): void => {
    const refId: string | undefined = hospital.id;
    const ref_id: string | undefined = hospital.ref_id;

    if (ref_id) setProspectRef(ref_id);
    if (refId) return setProspectCadre(refId);

    toastService.error("Cadre Not Available!");
  };

  const getUnprospected = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const res: ApiResponse = await getUnprospectedHospitals(city, user?.id);
      const fetchedHospitals: Hospital[] = (res.data as any).hospitals;
      const ids: Hospital[] = utilities.mapKeyToNumber(fetchedHospitals, "id");
      const beds: Hospital[] = utilities.mapKeyToNumber(ids, "bed");

      setUnprospected(beds);
      setLoading(false);
    } catch (e: any) {
      const errorMessage: string = e?.response?.data?.description ?? e.message;

      toastError(errorMessage);
      setLoading(false);
    }
  }, [city, user?.id]);

  const getSearchMatch = (hospital: Hospital): boolean => {
    let searchBy: string = searchKey ? searchKey : "name";
    let item: string | null = hospital[searchBy];
    if (!item) return false;

    return item.toLowerCase().startsWith(search.toLowerCase());
  };

  const getStatusMatch = (hospital: Hospital): boolean => {
    return hospital.status?.toLowerCase?.() === status.toLowerCase();
  };

  const getHospitalsByCategory = (): Hospital[] => {
    let isNerve: boolean = activeCategory === "2";

    return unprospected.filter((u) => {
      return isNerve ? u.channel === "Nerve" : u.channel !== "Nerve";
    });
  };

  const getColumns = (): TableColumn[] => {
    const privileges = getPrivileges();
    const permissionColumns = [
      {
        path: "_",
        label: "Edit",
        content: (hospital: Hospital) =>
          privileges.editProspect && (
            <TableButton
              buttonClass="status view"
              onClick={(): void => handleEditSelect(hospital)}
              label="Edit"
            />
          ),
      },
      // {
      //   path: "active_date",
      //   label: "Validate",
      //   content: (hospital: Hospital) =>
      //     privileges.validateProspect && (
      //       <TableButton
      //         buttonClass="status primary"
      //         onClick={(): void => handleValidateSelect(hospital)}
      //         label="Validate"
      //       />
      //     ),
      // },
      {
        path: "ref_id",
        label: "",
        content: (hospital: Hospital) => (
          <TableButton
            buttonClass="status primary"
            onClick={(): void => handleProspectCadre(hospital)}
            label="Validate"
          />
        ),
      },
    ];

    return [...tableColumns, ...permissionColumns];
  };

  const getPaginatedData = (): PaginatedData => {
    let filtered: Hospital[] = getHospitalsByCategory();

    if (search) filtered = filtered.filter(getSearchMatch);
    if (status) filtered = filtered.filter(getStatusMatch);

    let sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    let paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, paginated };
  };

  const paginatedData: PaginatedData = getPaginatedData();
  const columns: TableColumn[] = getColumns();

  useEffect(() => {
    getUnprospected();

    // eslint-disable-next-line
  }, [city, prospectToEdit, prospectToValidate]);

  useEffect(() => {
    const myInterval = setInterval(getUnprospected, 15000);
    return () => {
      clearInterval(myInterval);
    };
  }, [getUnprospected]);

  useEffect(() => {
    onCountChange(paginatedData.totalCount);

    // eslint-disable-next-line
  }, [paginatedData.totalCount]);

  useEffect(() => {
    const pageFromQuery =
      Number(new URLSearchParams(window.location.search).get("page")) || 1;
    setCurrentPage(pageFromQuery);
  }, [currentPage]);
  const goToPage = (page: number) => {
    setCurrentPage(Math.min(Math.max(page, 1), paginatedData.totalCount));
    applyFilters(
      {
        page: Math.min(Math.max(page, 1), paginatedData.totalCount),
      },
      navigate,
      "prospects"
    );
  };
  return (
    <React.Fragment>
      <Conditional isVisible={!!prospectToEdit}>
        <EditProspectForm
          selectedProspect={prospectToEdit}
          handleClose={() => handleEditSelect(null)}
        />
      </Conditional>

      <Conditional isVisible={!!prospectToValidate}>
        <ValidateProspect
          selectedProspect={prospectToValidate}
          handleClose={() => handleValidateSelect(null)}
          blood=""
          oxygen=""
        />
      </Conditional>

      <Conditional isVisible={!!prospectCadre}>
        <HospitalCadre
          hospitalId={prospectCadre}
          handleClose={() => setProspectCadre("")}
          ref_id={prospectRef}
        />
      </Conditional>

      <div className="activity-feed fade-in">
        <LoadingButton
          visible={loading}
          message="Fetching unprospected hospitals, please wait"
        />

        {/* <Conditional isVisible={!loading}> */}
        <React.Fragment>
          <Table
            columns={columns}
            data={paginatedData.paginated}
            sortColumn={sortColumn}
            onSort={setSortColumn}
          />

          <div className="sb-pagination-container">
            <CustomPagination
              goToPage={goToPage}
              currentPage={currentPage}
              totalPages={
                Math.ceil(paginatedData.totalCount / pageSize) > 0
                  ? Math.ceil(paginatedData.totalCount / pageSize)
                  : 1
              }
            />
          </div>
        </React.Fragment>

        {paginatedData.paginated.length === 0 && (
          <div className="no-items">
            <p>No facilities to show</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const tableColumns: TableColumn[] = [
  {
    path: "id",
    label: "ID",
  },
  {
    path: "name",
    label: "Hospital Name",
    content: (h: Hospital) => <Link to={`/prospects/${h.id}`}>{h.name}</Link>,
  },
  {
    path: "email",
    label: "Email Address",
  },
  {
    path: "phone",
    label: "Phone Number",
  },
  {
    path: "bed",
    label: "Number of Beds",
  },
  {
    path: "address",
    label: "Address",
  },
];

const initialSortColumn: SortColumn = { path: "id", order: "desc" };
export default UnprospectedTable;
