import React, { useState } from "react";
import Joi from "joi-browser";

import LoadingButton from "../common/loadingButton";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import { addProduct } from "../../services/product.service";
import toastService from "../../services/toastService";

const AddProduct = ({ productList, handleClose, loading }) => {
  const [data, setData] = useState({
    name: "",
    color: "",
  });
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(0);

  const schema = {
    name: Joi.string()
      .trim()
      .regex(/^[a-zA-Z_ ]+$/, "product name")
      .required()
      .label("Product Name"),
    color: Joi.string()
      .trim()
      .regex(/^#[0-9A-Fa-f]{6}$/, "color")
      .required()
      .label("Product Color"),
  };

  const getProductMatch = (value) => {
    try {
      return productList.find((s) =>
        s?.name?.toLowerCase?.()?.startsWith(value.toLowerCase())
      );
    } catch (error) {
      return null;
    }
  };

  const handleProgressChange = (progress) => {
    setProgress(progress);
  };

  const handleChange = (e) => {
    const input = e.currentTarget;

    const newData = { ...data };
    const newErrors = { ...errors };

    newData[input.name] = input.value;

    const errorMessage = validateProperty(input);
    if (input.name === "name") {
      const productMatch = getProductMatch(input.value);
      newErrors[input.name] = productMatch
        ? `Match Found: ${productMatch.name}!`
        : errorMessage;
    } else {
      newErrors[input.name] = errorMessage;
    }

    setData(newData);
    setErrors(newErrors);
  };

  const postData = async () => {
    const res = await addProduct(data, handleProgressChange);
    if (res.data.status !== "success") throw new Error(res.data.message);

    handleClose();
  };

  const doSubmit = () => {
    toastService.promise(postData(), {
      loading: "Uploading data...",
      success: () => "Product Added Successfully!",
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not add product!",
    });
  };

  const validateProperty = (input) => {
    const { name, value } = input;
    const obj = { [name]: value };
    const fieldSchema = { [name]: schema[name] };
    const { error } = Joi.validate(obj, fieldSchema);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    doSubmit();
  };

  const renderInput = ({
    name,
    label,
    type = "text",
    placeholder,
    compulsory,
  }) => {
    return (
      <div className="form-group mb-3 space-y-3 px-2">
        <label htmlFor={name}>
          {label} {compulsory && <i className="fa fa-asterisk error"></i>}
        </label>
        <input
          type={type}
          name={name}
          id={name}
          value={data[name]}
          onChange={handleChange}
          className={`form-control ${errors[name] ? "is-invalid" : ""}`}
          placeholder={placeholder}
        />
        {errors[name] && (
          <div className="alert alert-danger">{errors[name]}</div>
        )}
      </div>
    );
  };

  const renderButton = (label) => {
    return (
      <button disabled={validate()} className="btn btn-primary">
        {label}
      </button>
    );
  };

  return (
    <Modal handleClose={handleClose}>
      <ProgressLoader progress={progress} />
      <form onSubmit={handleSubmit}>
        <h2 className="form-header">
          Fields marked <i className="fa fa-asterisk error"></i> are compulsory
        </h2>

        {renderInput({
          type: "text",
          name: "name",
          placeholder: "e.g. Blood",
          label: "Product",
          compulsory: true,
        })}

        {renderInput({
          type: "color",
          name: "color",
          placeholder: "e.g. #000000",
          label: "Product Color",
          compulsory: true,
        })}

        <div className="d-flex justify-content-end ">
          <LoadingButton visible={loading} message="Please wait" />
          {!loading && renderButton("Add")}
        </div>
      </form>
    </Modal>
  );
};

export default AddProduct;
