import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "../../../../components/blocks/header";

import utilities from "../../../../utils/utilities";
import { useLocation, useNavigate } from "react-router-dom";

import Reward from "./Reward";

import {
  useGetRewardList,
  useGetRewardBalance,
} from "../../../../hooks/agent/useAgent";
import Table from "../../../../components/common/table";
import CustomPagination from "../../../../components/common/pagination/Pagination";
import { SortColumn } from "../../../../ts-utils/interfaces";
import applyFilters from "../../../../helpers/urlState";

import { useGlobalContext } from "../../../../contexts/globalContext";
import { SyncLoader } from "react-spinners";

const ScoreCard = (props: any) => {
  const { user, country } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialPageIndex = parseInt(searchParams.get("page") || "1", 10) - 1;
  const [currentPage, setCurrentPage] = useState(initialPageIndex + 1);

  const limit = 20;
  const { rewards, isPending, paginate } = useGetRewardList(
    "",
    currentPage,
    limit,
    ""
  );

  const { balance } = useGetRewardBalance(user?.id || "");

  const rewardValue = [
    {
      id: 1,
      title: "Reward",
      type: "credit",
      status: "pending",
    },
    {
      id: 2,
      title: "Deduction",
      type: "debit",
      status: "settled",
    },
    {
      id: 3,
      title: "Payable Reward",
      type: "credit",
      status: "pending",
    },
  ];

  // filter reward with null id and also sort id by number
  rewards.sort((a: any, b: any) => b.id - a.id).reverse();

  const initialSortColumn: SortColumn = { path: "id", order: "desc" };

  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);

  const pathname = location.pathname.replace("/", "");

  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );

  const salesAdminColumns = [
    {
      path: "id",
      label: "ID",
    },
    {
      path: "username",
      label: "Name",
    },
    {
      path: "email",
      label: "Email Address",
    },
    {
      path: "created_at",
      label: "Date",
      content: (h: { created_at: string }) => (
        <span className="w-full">{utilities.formatDate(h.created_at)}</span>
      ),
    },
    {
      path: "status",
      label: "Status",
      content: (h: { status: string }) => (
        <button
          disabled
          className={
            h.status?.toLowerCase?.() === "settled"
              ? "!bg-green-600 status capitalize"
              : `!bg-yellow-600 capitalize status "`
          }
        >
          {h.status}
        </button>
      ),
    },
    {
      path: "type",
      label: "Reward Type",
    },
    {
      path: "value",
      label: "Reward",
    },
    {
      path: "balance",
      label: "Balance",
    },
  ];
  return (
    <React.Fragment>
      <Header title="Reward" />

      {user?.position === "cceo" || user?.position === "sales_admin" ? (
        <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
          {isPending && (
            <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
              <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
              <SyncLoader
                color="#3B82F6"
                loading={isPending}
                className="relative"
                size={30}
              />
            </div>
          )}
          <>
            <Table
              columns={salesAdminColumns}
              data={rewards}
              sortColumn={sortColumn}
              onSort={setSortColumn}
            />
            <CustomPagination
              totalPages={paginate?.totalPages}
              currentPage={currentPage}
              goToPage={goToPage}
            />
          </>
        </div>
      ) : (
        <>
          {balance.data > 0 && (
            <div className="lg:col-span-7 p-3 rounded-lg shadow-md mb-4">
              <h5 className="text-2xl">Congratulations {user?.username}! 🎉</h5>
              <p className="text-lg">
                You have done total revenue of{" "}
                <span className="font-semibold">
                  {utilities.currencyFormatter(
                    balance.data ?? 0,
                    country?.currency
                  )}
                </span>{" "}
              </p>
            </div>
          )}

          {rewardValue?.map((reward) => (
            <Reward
              title={reward.title}
              type={reward.type}
              status={reward.status}
            />
          ))}
        </>
      )}
    </React.Fragment>
  );
};

export default ScoreCard;
