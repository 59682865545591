import React, { useEffect, useState } from "react";
import Button from "../../../../../components/common/button";
import Modal from "../../../../../components/common/modal";

import toastService from "../../../../../services/toastService";

import marketingService from "../../../../../services/marketing.service";
import LoadingButton from "../../../../../components/common/loadingButton";
import { useNavigate } from "react-router-dom";
import {
  useCompleteChecklist,
  useScheduleAppointment,
} from "../../../../../hooks/sales/useAppointment";

const Drip = (props: { selectedPitch: any; handlePrev: () => void }) => {
  const { mutateSchedule } = useScheduleAppointment();
  const { selectedPitch, handlePrev } = props;
  console.log(selectedPitch);
  const [mail, setMail] = useState<{ data: string } | null>(null);
  const [drips, setDrips] = useState([]);
  const [dripType, setDripType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItems] = useState([]);
  const [selectedDrip, setSelectedDrip] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const getDrip = async () => {
      setLoading(true);
      const res = await marketingService.getDrips();
      const dripType = await marketingService.getAllDrips();

      setDripType(dripType.data.data);

      setDrips(res.data.data);
      setLoading(false);
    };
    getDrip();
  }, []);

  const handleDripType = (e: {
    currentTarget: { value: React.SetStateAction<string> };
  }) => {
    setSelectedDrip(e.currentTarget.value);
  };
  const { mutateCompleteChecklist } = useCompleteChecklist();
  const postData = async () => {
    mutateCompleteChecklist(selectedPitch.hospital_id, {
      onSuccess: (success) => {
        console.log(success);
        const scheduleData = {
          hospital_id: selectedPitch?.hospital_id,
          orders: "",
          when: "",
          frequency: "",
          amount: "",
          type: "onboard",
        };
        mutateSchedule(scheduleData, {
          onSuccess: () => {
            navigate("/pipeline?content=onboard");
          },
        });
      },
    });
  };

  const filteredData = drips.filter(
    (item: { driptype: string }) => item.driptype === selectedDrip
  );

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Select Drip</h3>

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="form-field">
            <label htmlFor={"drip"} className="form-field-label">
              Drip Type
            </label>

            <select
              name={"drip"}
              id={"drip"}
              onChange={handleDripType}
              className="form-field-input"
            >
              <option value="">Select Drip Type</option>
              {dripType.map((option: { id: string; name: string }) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="pitch-data">
        <div className="drip-grid">
          <LoadingButton visible={loading} message="Please wait" />
          {filteredData?.map(({ id, driptype, link }) => (
            <>
              <div
                className={`drip ${
                  selectedItems.includes(id) ? "selected" : ""
                }`}
                key={id}
                onClick={() => handleCopyClick(link)}
              >
                {driptype}
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="checklist-button">
        <Button label="Prev" onClick={handlePrev} />
        <Button
          disabled={selectedDrip === ""}
          type="button"
          label="Complete"
          onClick={postData}
        />
      </div>

      {mail && (
        <Modal handleClose={() => setMail(null)}>
          <div className="row">
            <div dangerouslySetInnerHTML={{ __html: mail?.data }} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Drip;

const handleCopyClick = (url: string) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toastService.success(
        "URL copied to clipboard. Please share with the hospital"
      );
    })
    .catch((error) => {
      toastService.error("Failed to copy URL to clipboard");
    });
};
