import React, { useEffect, useMemo, useState } from "react";
import Table from "../../../../components/common/table";
import CustomPagination from "../../../../components/common/pagination/Pagination";

import * as Dialog from "@radix-ui/react-dialog";
import {
  PaginatedData,
  SortColumn,
  TableColumn,
} from "../../../../ts-utils/interfaces";
import applyFilters from "../../../../helpers/urlState";
import { useLocation, useNavigate } from "react-router-dom";
import utilities from "../../../../utils/utilities";
import _ from "lodash";
import Header from "../../../../components/blocks/header";
import { Eye } from "lucide-react";

import {
  useApproveCadreRequest,
  useGetCadreRequest,
  useGetCadreList,
} from "../../../../hooks/agent/useAgent";
import toastService from "../../../../services/toastService";
import SearchQuery from "../../../../components/forms/searchQuery";
export default function CadreRequest() {
  const { requests } = useGetCadreRequest();

  const { mutateApproveCadreRequest, isPending: isSubmitting } =
    useApproveCadreRequest();

  const transformedRequest = useMemo(() => {
    return Array.isArray(requests)
      ? requests?.map((request: any) => ({
          ...request,
          create: request.created,
        }))
      : [];
  }, [requests]);

  const { cadreList } = useGetCadreList();

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialPageIndex = parseInt(searchParams.get("page") || "1", 10) - 1;
  const [currentPage, setCurrentPage] = useState(initialPageIndex + 1);

  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [openCadre, setOpenCadre] = React.useState<{
    name: string;
    bloodId: string;
    oxygenId: string;
    id: string;
    ref: string;
    blood: string;
    oxygen: string;
  } | null>(null);

  const initialSortColumn: SortColumn = { path: "id", order: "desc" };
  const [sortColumn, setSortColumn] = useState<SortColumn>(initialSortColumn);

  const handleView = (item: any) => {
    const findBloodId = cadreList?.find((cadre: { name: string }) => {
      return (
        cadre?.name?.split(" ").join("").toLowerCase() ===
        item?.blood?.toLowerCase()
      );
    });

    const findOxygenId = cadreList?.find((cadre: { name: string }) => {
      return (
        cadre?.name?.split(" ").join("").toLowerCase() ===
        item?.oxygen?.toLowerCase()
      );
    });

    setOpenCadre({
      ...item,
      oxygenId: findOxygenId?.id,
      bloodId: findBloodId?.id,
    });
  };

  function onSubmitCadre() {
    const payload = {
      id: openCadre?.id,
      ref: openCadre?.ref,
      blood: openCadre?.bloodId,
      oxygen: openCadre?.oxygenId,
    };

    mutateApproveCadreRequest(payload, {
      onSuccess: (data) => {
        toastService.success(data?.data?.message);
        setOpenCadre(null);
      },
      onError: (error: { message: string }) => {
        toastService.error("Failed to submit cadre request");
      },
    });
  }

  const columns: TableColumn[] = [
    {
      path: "create",
      label: "Date",
    },
    {
      path: "name",
      label: "Name",
    },
    {
      path: "city",
      label: "City",
    },
    {
      path: "state",
      label: "State",
    },
    {
      path: "blood",
      label: "Blood",
    },
    {
      path: "oxygen",
      label: "Oxygen",
    },

    {
      path: "_",
      label: "",
      content: (a: any) => (
        <Eye className="cursor-pointer" onClick={() => handleView(a)} />
      ),
    },
  ];

  const pageSize: number = 10;
  const getPaginatedData = (): PaginatedData => {
    const filtered = transformedRequest?.filter((request) => {
      return (
        request?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        request?.city?.toLowerCase().includes(search.toLowerCase()) ||
        request?.state?.toLowerCase().includes(search.toLowerCase()) ||
        request?.blood?.toLowerCase().includes(search.toLowerCase()) ||
        request?.oxygen?.toLowerCase().includes(search.toLowerCase())
      );
    });

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const paginated = utilities.paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered?.length, paginated };
  };

  const { totalCount, paginated }: PaginatedData = getPaginatedData();

  useEffect(() => {
    const pageFromQuery =
      Number(new URLSearchParams(window.location.search).get("page")) || 1;
    setCurrentPage(pageFromQuery);
  }, [currentPage]);
  const goToPage = (page: number) => {
    setCurrentPage(Math.min(Math.max(page, 1), totalCount));
    applyFilters(
      {
        page: Math.min(Math.max(page, 1), totalCount),
        search: search,
      },
      navigate,
      "cadre-request"
    );
  };

  return (
    <div>
      <Header title={`Cadre Requests - ${totalCount ?? 0}`} />
      <div className="hospitals-container-top">
        <SearchQuery
          name="search"
          placeholder="Search"
          value={search}
          onChange={(e: any) => {
            setSearch(e.currentTarget.value);
            applyFilters(
              {
                page: 1,
                search: e.currentTarget.value,
              },
              navigate,
              "cadre-request"
            );
          }}
        />
      </div>
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
        <>
          <Table
            columns={columns}
            data={paginated}
            sortColumn={sortColumn}
            onSort={setSortColumn}
          />
          <CustomPagination
            goToPage={goToPage}
            currentPage={currentPage}
            totalPages={
              Math.ceil(totalCount / pageSize) > 0
                ? Math.ceil(totalCount / pageSize)
                : 1
            }
          />
        </>
      </div>
      <Dialog.Root open={!!openCadre} onOpenChange={() => setOpenCadre(null)}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6 ">
              <div className="flex items-center justify-between mb-3">
                <Dialog.Title className="text-xl font-bold text-gray-800 text-center ">
                  Sales agent request for the following change in Cadre for{" "}
                  {openCadre?.name}
                </Dialog.Title>
                <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>

              <div className="space-y-3">
                <div className="flex justify-between items-center">
                  <span className="font-semibold inline-block">
                    Select Blood Cadre:
                  </span>
                  <div>{openCadre?.blood?.split(/(?=[A-Z])/).join(" ")}</div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-semibold inline-block">
                    Select Oxygen Cadre:
                  </span>
                  <div>{openCadre?.oxygen?.split(/(?=[A-Z])/).join(" ")}</div>
                </div>
                <button
                  disabled={isSubmitting}
                  onClick={onSubmitCadre}
                  className=" w-full mt-3 py-3 px-4 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg ring-offset-2 ring-indigo-600 focus:ring-2"
                >
                  {isSubmitting ? "Submitting..." : "Approve"}
                </button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
