import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import purchaseOrderServices from "../../services/purchasingOrder.service";
import { QuotationItemForBackend } from "../../ts-utils/interfaces";

export const useGetProduct = (item: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["product", item],
    queryFn: async () => {
      const response = await purchaseOrderServices.product(item);
      return response.data;
    },
  });

  const product: any = data?.data || [];

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, product, isPending, error };
};

export const useSendQuotations = (
  handleProgressChange?: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateQuotation,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await purchaseOrderServices.sendQuotations(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["orders"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateQuotation, isPending, error };
};

export const useUploadQuotation = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateUploadQuotation,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await purchaseOrderServices.uploadQuotation(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["orders"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateUploadQuotation, isPending, error };
};

export const useRequestItem = () => {
  const {
    mutate: mutateRequestItem,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await purchaseOrderServices.requestItem(data);
      return response;
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateRequestItem, isPending, error };
};

export const useGetEquipment = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["equipment"],
    queryFn: async () => {
      const response = await purchaseOrderServices.getEquipment();
      return response.data;
    },
  });

  const equipment: any = data?.data || [];

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, equipment, isPending, error };
};

export const useGetEquipmentList = (id: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["equipmentList", id],
    queryFn: async () => {
      const response = await purchaseOrderServices.getEquipmentList(id);
      return response.data;
    },
  });

  const equipmentList: any = data?.data?.equipments || [];

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, equipmentList, isPending, error };
};

export const useRequestEquipment = () => {
  const {
    mutate: mutateRequestEquipment,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await purchaseOrderServices.requestEquipment(data);
      return response;
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateRequestEquipment, isPending, error };
};

export const useGetOrders = (id: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["orders", id],
    queryFn: async () => {
      const response = await purchaseOrderServices.getAllOrders(id);
      return response.data;
    },
  });

  const orders: any = data?.data || [];

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, orders, isPending, error };
};

export const useGetPendingOrders = (id: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["pendingOrders", id],
    queryFn: async () => {
      const response = await purchaseOrderServices.getPendingOrders(id);
      return response.data;
    },
  });

  const pendingOrders: any = data?.data || [];

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, pendingOrders, isPending, error };
};

export const useTrackOrder = (
  order_id: string,
  dispatch: string,
  product: string
) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["trackOrder", order_id, dispatch, product],
    queryFn: async () => {
      const response = await purchaseOrderServices.trackOrder(
        order_id,
        dispatch,
        product
      );
      return response.data;
    },
  });

  const track: any = data?.data || [];

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, track, isPending, error };
};

export const useRequestTracking = (
  order_id: string,
  dispatch: string,
  product: string
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateRequestTracking,
    isPending,
    error,
  } = useMutation({
    mutationFn: async () => {
      const response = await purchaseOrderServices.requestTracking(
        order_id,
        dispatch,
        product
      );
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["orders"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateRequestTracking, isPending, error };
};

export const useSendQuipQuotations = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateSendQuipQuotations,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await purchaseOrderServices.sendQuipQuotation(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["orders"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateSendQuipQuotations, isPending, error };
};

export const useGetOrderHistory = (order_id: string, order_type: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["orderHistory", order_id, order_type],
    enabled: !!order_id,
    queryFn: async () => {
      const response = await purchaseOrderServices.getOrderHistory(
        order_id,
        order_type
      );
      return response.data;
    },
  });

  const orderHistory: any = data?.data || [];
  const hospitalInfo: any = data?.hospital || {};

  if (isError) {
    console.error(error);
  }

  return { isError, isSuccess, orderHistory, isPending, error, hospitalInfo };
};

export const useConfirmDelivery = () => {
  const {
    mutate: mutateDelivery,
    isPending,
    isError,
  } = useMutation({
    mutationFn: async (data: { id: string; product: string }) => {
      const response = await purchaseOrderServices.confirmDelivery(data);
      return response;
    },
  });

  return { mutateDelivery, isPending, isError };
};

export const useGetQuotations = (quotation: {
  hospital_ref: string;
  type: string;
}) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["quotation", quotation.hospital_ref, quotation.type],
    enabled: !!quotation.type && !!quotation.hospital_ref,
    queryFn: async () => {
      const response = await purchaseOrderServices.getQuotations(quotation);
      return response.data;
    },
  });

  const quotations = data?.items;

  return { isError, isSuccess, quotations, isPending, error };
};
export const useGetQuotationsDetails = (id: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["quotation-details", id],
    enabled: !!id,
    queryFn: async () => {
      const response = await purchaseOrderServices.getQuotationDetails(id);
      return response.data;
    },
  });

  const quotations = data?.items;
  return { isError, isSuccess, quotations, isPending, error };
};

export const useDismissQuotation = () => {
  const {
    mutate: mutateDismiss,
    isPending,
    isError,
  } = useMutation({
    mutationFn: async (payload: { quote_id: any; stockOrQuip: string }) => {
      const response = await purchaseOrderServices.dismissQuotation(payload);
      return response;
    },
  });

  return { mutateDismiss, isPending, isError };
};

export const usePlaceOrder = () => {
  const {
    mutate: mutatePlaceOrder,
    isPending,
    isError,
  } = useMutation({
    mutationFn: async (data: {
      hospital_id: string;
      payment: string;
      discount: string;
      total: number;
      requester: string;
      items?: QuotationItemForBackend[];
      item?: QuotationItemForBackend;
      channel_type: string;
      stockOrQuip: string;
    }) => {
      const response = await purchaseOrderServices.placeOrder(data);
      return response;
    },
  });

  return { mutatePlaceOrder, isPending, isError };
};
