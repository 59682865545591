import { useCallback, useEffect, useMemo } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../@/components/ui/table";
import { Button } from "../../../@/components/ui/button";

import CustomPagination from "../../common/pagination/Pagination";
import { useState } from "react";
import { Input } from "../../../@/components/ui/input";
import { CirclePlus } from "lucide-react";

import applyFilters from "../../../helpers/urlState";
import { useNavigate, useLocation } from "react-router-dom";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onClick?: () => void;
  btnTitle?: string;
}

export default function DataTable<TData, TValue>({
  columns,
  data,
  onClick,
  btnTitle,
}: DataTableProps<TData, TValue>) {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialPageIndex = parseInt(searchParams.get("page") || "1", 10) - 1;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageIndex: initialPageIndex,
      },
    },
  });

  const currentPage = table.getState().pagination.pageIndex + 1;
  const pathname = location.pathname.replace("/", "");
  const goToPage = useCallback(
    (page: number) => {
      table.setPageIndex(page - 1);
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
    },
    [pathname, table, navigate]
  );

  useEffect(() => {
    const currentPageInURL = parseInt(searchParams.get("page") || "1", 10);
    if (currentPageInURL !== currentPage) {
      table.setPageIndex(currentPageInURL - 1);
    }
  }, [table, searchParams, location.search, currentPage]);

  return (
    <>
      <div className="flex items-center pb-4 justify-between">
        <Input
          placeholder="Filter name..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) => {
            // Reset to the first page when the search input changes
            table.getColumn("name")?.setFilterValue(event.target.value);
            table.setPageIndex(0); // Reset the current page to 1 (0 index-based)
            applyFilters(
              { page: 1 }, // Ensure the URL reflects the reset page index
              navigate,
              pathname
            );
          }}
          className="max-w-sm"
        />

        {onClick && (
          <Button
            onClick={onClick}
            variant="default"
            className="px-4 !rounded-lg app-btn !mt-0"
          >
            <CirclePlus className="mr-2 h-4 w-4" />
            {btnTitle}
          </Button>
        )}
      </div>
      <div className="rounded-md border">
        {" "}
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      data-label={
                        cell.column.columnDef.header
                          ? cell.column.columnDef.header
                          : cell.column.columnDef.id
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <CustomPagination
        totalPages={table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex + 1}
        goToPage={goToPage}
      />
    </>
  );
}
