import React from "react";

import Chart from "react-apexcharts";
import { useGlobalContext } from "../../contexts/globalContext";

import { FaHospital } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {
  useGetAgentRevenue,
  useGetHospitalsCount,
  useGetHospitalsOrderCount,
  useGetTargetRevenue,
} from "../../hooks/useDashboard";
import { getPrivileges } from "../../services/authService";
import { ApexOptions } from "apexcharts";
import { Button } from "../../@/components/ui/button";

import * as Dialog from "@radix-ui/react-dialog";
import { Link } from "react-router-dom";
import AnimatedCounter from "../AnimatedCounter";
import { TbTargetArrow } from "react-icons/tb";

type TransformedRevenue = {
  [key: string]: any;
};
const transformRevenueObject = (revenue: {
  [x: string]: any;
  hasOwnProperty: (arg0: string) => any;
}) => {
  const transformed: TransformedRevenue = {};
  for (const key in revenue) {
    if (revenue.hasOwnProperty(key)) {
      const newKey = key.replace("_revenue", "");
      transformed[newKey] = revenue[key];
    }
  }
  return transformed;
};
const DashboardTopRow = () => {
  const permission = getPrivileges();
  const { country, user } = useGlobalContext();

  const date_range = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const startTimestamp = Math.floor(startOfMonth.getTime() / 1000);
    const endTimestamp = Math.floor(endOfMonth.getTime() / 1000);
    return `${startTimestamp},${endTimestamp}`;
  };

  // const [selectedMonth, setSelectedMonth] = useState(() => {
  //   return new Date().getMonth() + 1;
  // });

  const getUserId = (user: any) => {
    if (!permission.createAgent) {
      return user;
    }
    return "";
  };
  const { count } = useGetHospitalsCount(getUserId(user?.id) as string);

  const { revenue } = useGetAgentRevenue(user?.id as string, date_range());
  const { orderCount } = useGetHospitalsOrderCount(
    getUserId(user?.id) as string,
    date_range()
  );

  const allRevenue = {
    blood_revenue: revenue?.blood_revenue,
    oxygen_revenue: revenue?.oxygen_revenue,
    quip_revenue: revenue?.quip_revenue,
    stockbank_revenue: revenue?.stockbank_revenue,
  };

  const transformedRevenue = transformRevenueObject(allRevenue);
  const totalRevenue =
    Object.values(transformedRevenue).reduce((sum: number, value) => {
      const numberValue = Number(value);
      return sum + numberValue;
    }, 0) || 0;

  const { target } = useGetTargetRevenue(user?.id as string);

  const [open, setOpen] = React.useState(false);
  const handleViewCash = (item: any) => {
    setOpen(true);
  };

  // const handleMonthChange = (value: string) => {
  //   setSelectedMonth(Number(value));
  //   const currentYear = new Date().getFullYear();
  //   const selectedDate = new Date(`${currentYear}-${value}-01`);
  //   const startDate = new Date(currentYear, selectedDate.getMonth(), 1);
  //   const endDate = new Date(currentYear, selectedDate.getMonth() + 1, 0);

  //   const startTimestamp = Math.floor(startDate.getTime() / 1000);
  //   const endTimestamp = Math.floor(endDate.getTime() / 1000);

  //   console.log("Selected month:", value);
  //   console.log("Start timestamp:", startTimestamp);
  //   console.log("End timestamp:", endTimestamp);

  //   const dateRange = startTimestamp + "," + endTimestamp;
  //   console.log("Date range:", dateRange);
  //   setDateRange(dateRange);

  //   // Implement your month change logic here using startDate and endDate
  // };

  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row  w-full gap-3">
        <Card
          className="p-1 !bg-blue-100 rounded-lg shadow-md"
          sx={{
            minWidth: 125,
            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent className="h-full w-full space-y-4">
            <div className="flex w-full items-center justify-between">
              <div className="bg-blue-300 rounded-full p-3">
                <FaHospital className="text-white w-6 h-6" />
              </div>
              <div>
                <Button asChild className="bg-blue-400 hover:bg-blue-600">
                  <Link to={`/hospitals`}> View</Link>
                </Button>
              </div>
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">Number of Hospitals</h2>
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div className=" ">
                <h3 className="text-xl xl:text-2xl">{count ? count : 0}</h3>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card
          className="p-1 !bg-green-100 rounded-lg shadow-md"
          sx={{
            minWidth: 125,
            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent className="h-full w-full space-y-4">
            <div className="flex w-full">
              <div className="bg-green-300 rounded-full p-3">
                <GiMoneyStack className="text-white w-6 h-6" />
              </div>
              <div></div>
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">Monthly Target</h2>
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div className="flex w-full">
                <h3 className="text-xl xl:text-2xl">
                  <AnimatedCounter
                    amount={target?.revenue ?? 0}
                    prefix={country?.currency as string}
                    decimal={2}
                  />
                  {/* {utilities.currencyFormatter(
                    target?.revenue ?? 0,
                    country?.currency
                  )} */}
                </h3>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card
          className="p-1 !bg-orange-100 rounded-lg shadow-md"
          sx={{
            minWidth: 125,
            // maxWidth: { sm: 20 },
            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: "8px",
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent className="h-full w-full space-y-4">
            <div className="flex w-full items-center justify-between">
              <div className="bg-orange-300 rounded-full p-3">
                <GiTakeMyMoney className="text-white w-6 h-6" />
              </div>
              <div className="flex flex-col items-center gap-2">
                {/* <select
                  className="bg-orange-200 text-orange-800 rounded-md px-2 py-1"
                  onChange={(e) => handleMonthChange(e.target.value)}
                  value={selectedMonth}
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select> */}
                {totalRevenue > 0 && (
                  <Button
                    onClick={handleViewCash}
                    className="bg-orange-400 hover:bg-orange-600"
                  >
                    View
                  </Button>
                )}
              </div>
            </div>
            <div className="flex w-full">
              <h2 className="text-sm text-gray-500">Monthly Actual</h2>
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div className="w-full flex flex-col">
                <h3 className="text-xl xl:text-2xl">
                  <AnimatedCounter
                    amount={totalRevenue ?? 0}
                    prefix={country?.currency as string}
                    decimal={2}
                  />
                  {/* {utilities.currencyFormatter(
                    totalRevenue ?? 0,
                    country?.currency
                  )} */}
                </h3>
                <span className="opacity-55 text-sm ml-auto">
                  No of Hospital: {orderCount ?? 0}
                </span>
              </div>
            </div>
          </CardContent>
        </Card>
        {permission.createAgent && (
          <Card
            className="p-1 !bg-[#b70303]/10 rounded-lg shadow-md"
            sx={{
              minWidth: 125,
              // maxWidth: { sm: 20 },
              boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.25)",
              width: "100%",
              borderRadius: "8px",
              // padding: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent className="h-full w-full space-y-4">
              <div className="flex w-full items-center justify-between">
                <div className="bg-[#b70303]/30 rounded-full p-3">
                  <TbTargetArrow className="text-white w-6 h-6" />
                </div>
              </div>
              <div className="flex w-full">
                <h2 className="text-sm text-gray-500">
                  Active Hospital Target
                </h2>
                <div></div>
              </div>
              <div className="flex justify-between w-full">
                <div></div>
                <div className="w-full flex flex-col">
                  <h3 className="text-xl xl:text-2xl">
                    {target?.active_hospitals ?? 0}
                  </h3>
                  <span className="opacity-55 text-sm ml-auto">
                    Active Hospital Actual: {target?.active ?? 0}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
            <Dialog.Content
              aria-describedby={undefined}
              className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-4"
            >
              <div className="bg-white rounded-md shadow-lg px-4 py-6">
                <div className="flex items-center justify-end">
                  <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mx-auto"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Dialog.Close>
                </div>
                <div className="max-w-sm mx-auto space-y-3 ">
                  <Dialog.Title className="text-2xl font-bold text-gray-800 text-center ">
                    Cash breakdown
                  </Dialog.Title>
                </div>{" "}
                <div>
                  <RevenuePieChart data={transformedRevenue} />
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </React.Fragment>
  );
};
export default DashboardTopRow;

const RevenuePieChart = ({ data }: { data: Record<string, any> }) => {
  const labels = Object.keys(data);
  const values = Object.values(data).map((value) => Number(value));

  const options: ApexOptions = {
    chart: {
      type: "pie",
    },
    labels: labels,
    title: {
      // text: "Cash Breakdown",
      align: "center",
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },

    // dataLabels: {
    //   enabled: false, // Disable labels on the chart
    // },
    legend: {
      position: "bottom", // Change to 'bottom' if you prefer the legend at the bottom
    },
    dataLabels: {
      formatter: (val, opts) => {
        const name = opts.w.globals.labels[opts.seriesIndex];
        const value = opts.w.globals.series[opts.seriesIndex];
        return `${name}: ${value}`;
      },
    },
    colors: labels.map((label) => {
      if (label === "blood") return "#b70303"; // Red for blood
      if (label === "oxygen") return "#5286c3"; // Blue for oxygen
      if (label === "quip") return "#F16C03"; // Orange for quip
      if (label === "stockbank") return "#215200"; // Green for stockbank
      return "#999999"; // Default color for others
    }),
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = values;

  return <Chart options={options} series={series} type="pie" width="500" />;
};
