import { RiHospitalLine } from "react-icons/ri";
import { IoBriefcaseOutline } from "react-icons/io5";
import { MdOutlineContactPhone } from "react-icons/md";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { PiNotepadDuotone } from "react-icons/pi";

import { SiJfrogpipelines } from "react-icons/si";

interface DataItem {
  text: string;
  subText?: string;
  icon: any;
  number: number | string | null;
  link?: string;
}

const data: DataItem[] = [
  {
    text: "Current Month Pipeline",
    subText: "",
    icon: <RiHospitalLine />,
    number: null,
    link: `/exodus/current`,
  },
  {
    text: "Future Month Pipeline",
    subText: "",
    icon: <SiJfrogpipelines />,
    number: 0,
    link: `/exodus/future`
  },
  {
    text: "Lite Orders",
    subText: "",
    icon: <IoBriefcaseOutline />,
    number: 0,
    link: `/exodus/lite`
  },
  {
    number: "Log a contact",
    icon: <MdOutlineContactPhone />,
    text: "log a call",
    subText: "Log a call or visit",
    link: `/exodus/log-a-contact`,
  },
  {
    number: "Send Notes",
    icon: <MdOutlineStickyNote2 />,
    text: "send a note",
    subText: "Send a note to any hospital in the Arena",
    link: `/exodus/send-a-note`,
  },
  {
    number: "Send Drip",
    icon: <PiNotepadDuotone />,
    text: "send a drip",
    subText: "Send a drip easily",
    link: `/exodus/send-a-drip`,
  },
];

const hospitalDetails = [
  {
    name: "Allfort Hospital",
    location: "Lekki, Lagos",
    averageSpent: "1000",
    activeSchedule: "#8000 | Twice a month",
  },
  {
    name: "Therresa Hospital",
    location: "Lekki, Abuja",
    averageSpent: "2000",
    activeSchedule: "#5000 | Once a month",
  },
  {
    name: "Lagoon Hospital",
    location: "Lekki, Lagos",
    averageSpent: "3000",
    activeSchedule: "#10000 | Twice a month",
  },
  {
    name: "Eko Hospital",
    location: "Lekki, Lagos",
    averageSpent: "4000",
    activeSchedule: "#20000 | Once a month",
  },
  {
    name: "Reddington Hospital",
    location: "Lekki, Lagos",
    averageSpent: "5000",
    activeSchedule: "#30000 | Twice a month",
  },
  {
    name: "Lagos University Teaching Hospital",
    location: "Lekki, Lagos",
    averageSpent: "6000",
    activeSchedule: "#40000 | Once a month",
  },
  {
    name: "St. Nicholas Hospital",
    location: "Lekki, Lagos",
    averageSpent: "7000",
    activeSchedule: "#50000 | Twice a month",
  },
];

const Complains = [
  {
    complainsID: "1",
    incidentDate: "22-09-11",
    contactName: "Durotimi",
    contactPhone: "09033776421",
    category: "Price issue",
    complaintIssue: "Complaint Summary",
  },
  {
    complainsID: "2",
    incidentDate: "22-09-11",
    contactName: "Durotimi",
    contactPhone: "09033886421",
    category: "Price issue",
    complaintIssue: "Complaint Summary",
  },
  {
    complainsID: "3",
    incidentDate: "22-09-11",
    contactName: "Durotimi",
    contactPhone: "09033886421",
    category: "Price issue",
    complaintIssue: "Complaint Summary",
  },
  {
    complainsID: "4",
    incidentDate: "22-09-11",
    contactName: "Durotimi",
    contactPhone: "09033886421",
    category: "Price issue",
    complaintIssue: "Complaint Summary",
  },
];

const sendDrip = [
  {
    index: 1,
    NameOfHospital: "Hossana hospital",
    Address: "Sabo Bariga",
  },
  {
    index: 2,
    NameOfHospital: "Alifort",
    Address: "Sabo Yaba",
  },
];

export { hospitalDetails, data, Complains, sendDrip };
