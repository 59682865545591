"use client";

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useCloseAppointment,
  useGetSinglePendingRequest,
  useReserveAppointment,
  useScheduleAppointment,
} from "../../../../../hooks/sales/useAppointment";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../../@/components/ui/card";
import { Button } from "../../../../../@/components/ui/button";
import LogVisit from "../../../../../components/modals/logVisit";
import { Label } from "../../../../../@/components/ui/label";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../../@/components/ui/radio-group";
import { Textarea } from "../../../../../@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../@/components/ui/select";
import Header from "../../../../../components/blocks/header";
import { Input } from "../../../../../@/components/ui/input";
import { ReactComponent as UploadIcon } from "../../../../../assets/svg/upload.svg";
import { Separator } from "../../../../../@/components/ui/separator";
import * as Dialog from "@radix-ui/react-dialog";
import LogExpenses from "../../../../../components/modals/LogExpenses";
import { useGlobalContext } from "../../../../../contexts/globalContext";

interface LogData {
  location: string;
  address: string;
}

const formSchema = z.object({
  summary: z.string().min(1, "Meeting summary is required"),
  grade: z.enum(["1", "2", "3", "4", "5"]),
  key: z.enum(["Yes", "Maybe", "No"]),
  objectionType: z.enum(["Yes", "No", ""]).optional(),
  need_credit: z.enum(["Yes", "No", ""]).optional(),
  offer_status: z.enum(["Yes", "No", ""]).optional(),
  offerList: z.enum(["Data Reward", "Registration Reward"]).optional(),
  reason: z.string().optional(),
  slaFile: z.instanceof(File).optional(),
  offerFile: z.instanceof(File).optional(),
});

type FormData = z.infer<typeof formSchema>;

export default function OptimizedRequestInfo() {
  const { user } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const request_id = searchParams.get("id");

  const { pitchInfo, isPending } = useGetSinglePendingRequest(
    request_id as string
  );
  const [page, setPage] = useState(1);
  const [logData, setLogData] = useState<LogData | null>(null);
  const [address, setAddress] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [createExpense, setCreateExpense] = useState(false);

  const { mutate, isPending: isClosing } = useCloseAppointment();
  const { mutateReserve, isPending: isReserving } = useReserveAppointment();
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      summary: "",
      grade: "1",
      key: "Yes",
    },
  });

  const { handleSubmit, watch, control, setValue } = form;

  const summary = watch("summary");
  const statusType = watch("key");
  const objectionType = watch("objectionType");
  const offerType = watch("offer_status");
  const needCredit = watch("need_credit");
  const sla = watch("slaFile");
  const offerList = watch("offerList");
  const offerFile = watch("offerFile");
  const offerReason = watch("reason");

  let yesCondition =
    (statusType === "Yes" && !needCredit) || (needCredit === "Yes" && !sla);

  let maybeCondition =
    (statusType === "Maybe" && offerType === "") ||
    (offerType === "Yes" && (!offerList || !offerFile)) ||
    (needCredit === "Yes" && !sla) ||
    (offerType === "No" && !offerReason);

  let noCondition =
    (statusType === "No" && objectionType === "") ||
    (objectionType === "Yes" &&
      (offerType === "No" || offerType === "") &&
      !offerReason) ||
    (offerType === "Yes" && (!offerList || !offerFile)) ||
    (offerType === "Yes" && needCredit === "Yes" && !sla);

  const isCondition = () => {
    let condition = false;
    if (statusType === "Yes") {
      noCondition = false;
      maybeCondition = false;
      condition = yesCondition;
    }
    if (statusType === "No") {
      yesCondition = false;
      maybeCondition = false;
      condition = noCondition;
    }
    if (statusType === "Maybe") {
      noCondition = false;
      yesCondition = false;
      condition = maybeCondition;
    }
    return condition;
  };

  const isReserved = offerType === "No" || objectionType === "No";

  useEffect(() => {
    const fetchLocationDetails = async (
      latitude: string,
      longitude: string
    ) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=YOUR_API_KEY`
        );
        const data = await response.json();
        if (data.status === "OK" && data.results.length > 0) {
          setAddress(data.results[0].formatted_address);
        }
      } catch (error) {
        console.error("Failed to fetch location details:", error);
      }
    };

    const [latitude, longitude] = (pitchInfo.location ?? ",").split(",");
    fetchLocationDetails(latitude, longitude);
  }, [pitchInfo.location]);
  const { mutateSchedule } = useScheduleAppointment();

  const onSubmit = (data: FormData) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value !== undefined && value !== null && value !== "") {
        formData.append(key, value.toString());
      }
    });

    if (pitchInfo.id) {
      formData.append("id", pitchInfo.id);
    }

    if (logData?.location) {
      formData.append("location", logData.location as string);
    }

    if (isReserved) {
      const payload = {
        agent_id: pitchInfo?.user_id,
        request_by: user?.id,
        hospital_id: pitchInfo?.hospital_id,
      };
      mutateReserve(payload, {
        onSuccess: () => {
          setCreateExpense(true);
        },
      });
    } else {
      mutate(formData, {
        onSuccess: () => {
          const scheduleData = {
            hospital_id: pitchInfo?.hospital_id,
            orders: "",
            when: "",
            frequency: "",
            amount: "",
          };
          mutateSchedule(scheduleData);
          setCreateExpense(true);
        },
      });
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: "slaFile" | "offerFile"
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue(fieldName, file);
    }
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  const renderPage = () => {
    switch (page) {
      case 1:
        return (
          <Card className="w-full max-w-md py-5">
            <CardContent className="p-0">
              <div className="space-y-5">
                <div className="flex justify-between px-4">
                  <span className="font-bold">Hospital Name:</span>
                  <span>{pitchInfo.name}</span>
                </div>
                <Separator />
                <div className="flex justify-between px-4">
                  <span className="font-bold">Agent:</span>
                  <span>{pitchInfo.agent_name}</span>
                </div>
                <Separator />
                <div className="flex justify-between px-4">
                  <span className="font-bold">Location:</span>
                  <span>{pitchInfo.address}</span>
                </div>
                <Separator />
                <div className="flex justify-between px-4">
                  <span className="font-bold">Pitch location:</span>
                  <span>{address}</span>
                </div>
                <Separator />
                <div className="flex justify-between px-4">
                  <span className="font-bold">Pitch meeting summary</span>
                  <span
                    className="value link"
                    onClick={() => setOpenDialog(true)}
                  >
                    view
                  </span>
                </div>
                <Separator />
                <div className="flex justify-between px-4">
                  <span className="font-bold">Status:</span>
                  <span>{pitchInfo.onboard}</span>
                </div>
                <Separator />
                <div className="flex justify-between px-4">
                  <span className="font-bold">Closing Date:</span>
                  <span>{`${pitchInfo.visit_date} | ${pitchInfo.visit_time}`}</span>
                </div>
                <Separator />
              </div>
            </CardContent>
            <CardFooter className="w-full pt-3">
              <Button className="w-full" onClick={() => setPage(2)}>
                Log your location
              </Button>
            </CardFooter>
          </Card>
        );
      case 2:
        return (
          <LogVisit
            handleClose={() => setPage(1)}
            changePage={() => setPage(3)}
            setLogData={setLogData}
          />
        );
      case 3:
        return (
          <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-lg">
            <Card className="">
              <CardHeader>
                <CardTitle>Close Meeting</CardTitle>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="flex items-center justify-between">
                  <Label htmlFor="grade" className="font-bold">
                    Grade Self
                  </Label>
                  <Controller
                    name="grade"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex space-x-2"
                      >
                        {[1, 2, 3, 4, 5].map((value) => (
                          <div
                            key={value}
                            className="flex items-center space-x-2"
                          >
                            <RadioGroupItem
                              value={value.toString()}
                              id={`grade-${value}`}
                            />
                            <Label htmlFor={`grade-${value}`}>{value}</Label>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                  />
                </div>

                <Separator />
                <div className="flex items-center justify-between">
                  <Label htmlFor="summary" className="font-bold">
                    Close Meeting Summary
                  </Label>
                  <div className="flex flex-col w-1/2">
                    <Controller
                      name="summary"
                      control={control}
                      render={({ field }) => (
                        <Textarea
                          {...field}
                          placeholder="Meeting Summary"
                          className=""
                        />
                      )}
                    />
                    {form.formState.errors && (
                      <span className="text-xs text-red-500 mt-1">
                        {form.formState.errors.summary?.message}
                      </span>
                    )}
                  </div>
                </div>

                <Separator />
                <div className="flex items-center justify-between">
                  <Label htmlFor="key" className="font-bold">
                    Would they like to use LifeBank?
                  </Label>
                  <Controller
                    name="key"
                    control={control}
                    render={({ field }) => (
                      <Select
                        onValueChange={(value) => {
                          field.onChange(value);

                          setValue("objectionType", "");
                          setValue("offer_status", "");
                          setValue("need_credit", "No");
                          setValue("slaFile", undefined);
                          setValue("offerFile", undefined);
                          //   form.reset();
                        }}
                        defaultValue={field.value}
                      >
                        <SelectTrigger className="w-1/2">
                          <SelectValue placeholder="Select Status" />
                        </SelectTrigger>
                        <SelectContent>
                          {["Yes", "Maybe", "No"].map((status) => (
                            <SelectItem key={status} value={status}>
                              {status}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </div>

                <Separator />
                {statusType === "No" && (
                  <div className="flex items-center justify-between">
                    <Label htmlFor="objectionType" className="font-bold">
                      Did the objection sheet help?
                    </Label>
                    <Controller
                      name="objectionType"
                      control={control}
                      render={({ field }) => (
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value);
                            setValue("offer_status", "");
                            setValue("reason", "");

                            setValue("need_credit", "No");

                            setValue("slaFile", undefined);

                            setValue("offerFile", undefined);
                            setValue("offerList", undefined);
                          }}
                          defaultValue={field.value}
                        >
                          <SelectTrigger className="w-1/2">
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent>
                            {["Yes", "No"].map((option) => (
                              <SelectItem key={option} value={option}>
                                {option}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                )}

                {statusType === "Yes" && (
                  <>
                    <div className="flex items-center justify-between">
                      <Label htmlFor="need_credit" className="font-bold">
                        Do you need Credit?
                      </Label>
                      <Controller
                        name="need_credit"
                        control={control}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger className="w-1/2">
                              <SelectValue placeholder="Do you need Credit?" />
                            </SelectTrigger>
                            <SelectContent>
                              {["Yes", "No"].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                    <Separator />
                  </>
                )}

                {statusType === "Yes" && needCredit === "Yes" && (
                  <>
                    <div className="flex items-center justify-between">
                      <Label htmlFor="slaFile" className="font-bold">
                        Upload SLA
                      </Label>
                      <div className="flex items-center justify-stretch space-x-2 max-w-xs relative">
                        <Input
                          type="file"
                          id="slaFile"
                          onChange={(e) => handleFileChange(e, "slaFile")}
                          className="hidden absolute"
                        />
                        <div className="w-full flex items-center justify-center">
                          <Label
                            htmlFor="slaFile"
                            className="cursor-pointer flex items-center space-x-2 px-4 py-2 text-secondary-foreground rounded-md border border-dashed bg-gray-100"
                          >
                            {form.watch("slaFile")?.name ? (
                              <img
                                src={URL.createObjectURL(
                                  form.watch("slaFile") as File
                                )}
                                alt={(form.watch("slaFile") as File).name}
                              />
                            ) : (
                              <>
                                <UploadIcon className="" />
                              </>
                            )}
                          </Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {(statusType === "Maybe" ||
                  (statusType === "No" && objectionType === "Yes")) && (
                  <>
                    <div className="flex items-center justify-between">
                      <Label htmlFor="offer_status" className="font-bold">
                        Did they accept your offer?
                      </Label>
                      <Controller
                        name="offer_status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger className="w-1/2">
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent>
                              {["Yes", "No"].map((option) => (
                                <SelectItem key={option} value={option}>
                                  {option}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                    <Separator />
                  </>
                )}

                {offerType === "No" && (
                  <>
                    <div className="flex items-center justify-between">
                      <Label htmlFor="reason" className="font-bold">
                        Why did they say "No"?
                      </Label>
                      <Controller
                        name="reason"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            placeholder="Reason for rejecting the offer"
                            className="w-1/2"
                          />
                        )}
                      />
                    </div>
                    <Separator />
                  </>
                )}

                {offerType === "Yes" && statusType !== "Yes" && (
                  <>
                    <div className="flex items-center justify-between">
                      <Label htmlFor="offerList" className="font-bold">
                        Select offer
                      </Label>
                      <Controller
                        name="offerList"
                        control={control}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <SelectTrigger className="w-1/2">
                              <SelectValue placeholder="Select Offer" />
                            </SelectTrigger>
                            <SelectContent>
                              {["Data Reward", "Registration Reward"].map(
                                (option) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                )
                              )}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                    <Separator />
                    <div className="flex items-center justify-between">
                      <Label htmlFor="offerFile" className="font-bold">
                        Upload offer evidence
                      </Label>
                      <div className="flex items-center space-x-2 max-w-xs relative">
                        <Input
                          type="file"
                          id="offerFile"
                          onChange={(e) => handleFileChange(e, "offerFile")}
                          className="hidden absolute"
                        />

                        <div className="w-full flex items-center justify-center">
                          <Label
                            htmlFor="offerFile"
                            className="cursor-pointer flex items-center space-x-2 px-4 py-2 text-secondary-foreground rounded-md border border-dashed bg-gray-100"
                          >
                            {form.watch("offerFile")?.name ? (
                              <img
                                src={URL.createObjectURL(
                                  form.watch("offerFile") as File
                                )}
                                alt={(form.watch("offerFile") as File).name}
                              />
                            ) : (
                              <>
                                <UploadIcon className="" />
                              </>
                            )}
                          </Label>
                        </div>
                      </div>
                    </div>
                    <Separator />
                  </>
                )}
                {statusType !== "Yes" && offerType === "Yes" && (
                  <>
                    <>
                      <div className="flex items-center justify-between">
                        <Label htmlFor="need_credit" className="font-bold">
                          Do you need Credit?
                        </Label>
                        <Controller
                          name="need_credit"
                          control={control}
                          render={({ field }) => (
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                setValue("slaFile", undefined);
                              }}
                              defaultValue={field.value}
                            >
                              <SelectTrigger className="w-1/2">
                                <SelectValue placeholder="Do you need Credit?" />
                              </SelectTrigger>
                              <SelectContent>
                                {["Yes", "No"].map((option) => (
                                  <SelectItem key={option} value={option}>
                                    {option}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </div>
                      <Separator />
                    </>

                    {needCredit === "Yes" && (
                      <>
                        <div className="flex items-center justify-between">
                          <Label htmlFor="slaFile" className="font-bold">
                            Upload SLA
                          </Label>
                          <div className="flex items-center justify-stretch space-x-2 max-w-xs relative">
                            <Input
                              type="file"
                              id="slaFile"
                              onChange={(e) => handleFileChange(e, "slaFile")}
                              className="hidden absolute"
                            />
                            <div className="w-full flex items-center justify-center">
                              <Label
                                htmlFor="slaFile"
                                className="cursor-pointer flex items-center space-x-2 px-4 py-2 text-secondary-foreground rounded-md border border-dashed bg-gray-100"
                              >
                                {form.watch("slaFile")?.name ? (
                                  <img
                                    src={URL.createObjectURL(
                                      form.watch("slaFile") as File
                                    )}
                                    alt={(form.watch("slaFile") as File).name}
                                  />
                                ) : (
                                  <>
                                    <UploadIcon className="" />
                                  </>
                                )}
                              </Label>
                            </div>
                          </div>
                        </div>{" "}
                        <Separator />
                      </>
                    )}
                  </>
                )}
              </CardContent>

              <CardFooter className="flex justify-end">
                <Button
                  type="submit"
                  disabled={isClosing || isReserving || isCondition()}
                >
                  {isClosing || isReserving
                    ? "Submitting..."
                    : isReserved
                    ? "Close and send to reserve list"
                    : "Proceed to Onboard"}
                </Button>
              </CardFooter>
            </Card>
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div onClick={() => navigate(-1)} className="back">
        <i className={"fa fa-arrow-left"}></i> Back
      </div>
      <Header
        title={`${page === 4 ? "Check Nerve Usage " : ""}${pitchInfo?.name}`}
      />
      <div className="flex flex-col items-center justify-center min-h-screen">
        {renderPage()}
      </div>

      <Dialog.Root open={openDialog} onOpenChange={setOpenDialog}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-xl mx-auto px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-end">
                <Dialog.Close className="p-2 text-gray-400 rounded-md hover:bg-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="max-w-sm mx-auto space-y-3 ">
                <Dialog.Title className="text-2xl font-bold text-gray-800 text-center "></Dialog.Title>
              </div>
              <div>
                <div className="row">
                  <div className="pitch-data2">
                    <span className="label">Feedback:</span>
                    <span className="value"> {pitchInfo?.feedback}</span>
                  </div>

                  <div className="pitch-data2">
                    <span className="label">Pitch Note:</span>
                    <span className="value"> {pitchInfo?.pitch_note}</span>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <LogExpenses
        note={summary}
        destinationGeoCode={logData?.location as string}
        destinationAddress={
          pitchInfo?.address
            ? pitchInfo?.address
            : logData?.address
            ? (logData?.address as string)
            : null
        }
        createExpense={createExpense}
        setCreateExpense={setCreateExpense}
        hospital_id={pitchInfo?.hospital_id}
      />
    </div>
  );
}
