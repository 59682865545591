/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { FaHospital } from "react-icons/fa";

import ExodusArena from "../../../../../components/focuscards/exodusArena";
import Reminders from "../../../../../components/focuscards/reminders";

import LoadingButton from "../../../../../components/common/loadingButton";
import { Link } from "react-router-dom";

import {
  useGetDashboard,
  useGetHospitalsCount,
  useGetTargetRevenue,
} from "../../../../../hooks/useDashboard";
import { useGlobalContext } from "../../../../../contexts/globalContext";

import DashboardCard from "./DashboardCard";
import ActivityFeed from "../../activities/activityFeed";
import ChurnInfoCard from "../../churn/ChurnInfoCard";

export const Focus = () => {
  const { user, activeTab } = useGlobalContext();

  const { dashboard, isPending, isError } = useGetDashboard(user?.id as string);
  const { target } = useGetTargetRevenue(user?.id as string);

  const { count } = useGetHospitalsCount(user?.id as string);
  // const { pipeline, isPending: isPipelineLoading } = useGetPipeline(
  //   user?.city,
  //   user?.id
  // );

  // const { allHospitals, isPending: isHospitals } = useGetAllHospitals(
  //   user?.city as string,
  //   user?.id as string
  // );

  // const uniquePipeline = (pipeline: any[]) => {
  //   return pipeline?.filter(
  //     (v: { id: any; hospital_id: any }, i: any, a: any[]) => {
  //       const hasSameId = a.findIndex((t: { id: any }) => t.id === v.id) === i;
  //       const hasSameHospitalId = v.hospital_id
  //         ? a.findIndex(
  //             (t: { hospital_id: any }) => t.hospital_id === v.hospital_id
  //           ) === i
  //         : true;
  //       return hasSameId && hasSameHospitalId;
  //     }
  //   );
  // };

  // const PitchedCount = useMemo(() => {
  //   if (!isPipelineLoading) {
  //     return pipeline?.pitches?.filter(
  //       (item: { sub_status: string }) => item.sub_status !== "To be contacted"
  //     ).length;
  //   }
  //   return 0;
  // }, [isPipelineLoading, pipeline?.pitches]);

  // const uniqueCloseCount = useMemo(() => {
  //   if (!isPipelineLoading) {
  //     return uniquePipeline(pipeline?.close)?.length;
  //   }
  //   return 0;
  // }, [isPipelineLoading, pipeline?.close]);

  // const uniqueExodusCount = useMemo(() => {
  //   if (!isPipelineLoading) {
  //     return uniquePipeline(pipeline?.exodus)?.length;
  //   }
  //   return 0;
  // }, [isPipelineLoading, pipeline?.exodus]);

  // const uniqueHospitalsCount = useMemo(() => {
  //   if (!isHospitals) {
  //     return uniquePipeline(allHospitals)?.length;
  //   }
  //   return 0;
  // }, [isHospitals, allHospitals]);

  // const uniquePipelineActive = useMemo(() => {
  //   if (!isPipelineLoading) {
  //     return uniquePipeline(pipeline?.active)?.length;
  //   }
  //   return 0;
  // }, [isPipelineLoading, pipeline?.active]);

  // const [selectedTimeFrame, setSelectedTimeFrame] = useState("monthly");

  // const handleTimeFrameChange = (e: {
  //   target: { value: React.SetStateAction<string> };
  // }) => {
  //   setSelectedTimeFrame(e.target.value);
  // };
  // const handleTimeFrameChange = (value: string) => {
  //   setSelectedTimeFrame(value);
  // };
  // Define a variable to store the divisor based on the selected time frame
  // let divisor = 1;

  // Update the divisor based on the selected time frame
  // if (selectedTimeFrame === "daily") {
  //   divisor = 30;
  // } else if (selectedTimeFrame === "weekly") {
  //   divisor = 4;
  // }

  if (isPending)
    return (
      <div className="flex flex-col  justify-between items-center w-full p-5 sm:p-10 h-screen">
        <LoadingButton
          visible={isPending}
          message="Fetching Data... Please wait"
        />
      </div>
    );

  if (isError)
    return (
      <div className="flex flex-col sm:flex-row justify-between items-center w-full p-5 sm:p-10">
        <div className="flex flex-col justify-center items-center gap-5 sm:gap-10 mx-5 sm:mx-10">
          <h1 className="text-4xl text-red-500">Error Fetching Data</h1>
        </div>
      </div>
    );
  const notActive =
    Number(dashboard?.hospital ?? 0) - Number(dashboard?.active ?? 0);

  const cardData = [
    {
      title: "Active Hospitals",
      value: dashboard?.active ?? 0,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
    {
      title: "Non-active Hospitals",
      // title: "Number of Hospitals in Exodus",
      value: notActive,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
    {
      title: "Active Hospital Target",
      value: target?.active_hospitals ?? 0,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
    {
      title: "Actual Hospitals",
      value: dashboard?.hospital ?? 0,
      icon: <FaHospital className="text-white w-6 h-6" />,
    },
  ];

  return (
    <div className="flex flex-col w-full mb-10">
      <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center w-full">
        <div
          className={
            activeTab !== "focus"
              ? "flex flex-col-reverse lg:flex-row justify-between w-full pr-1 gap-x-2"
              : "flex lg:flex-row flex-col justify-between w-full pr-1 gap-x-2"
          }
        >
          {activeTab === "focus" ? (
            <div className="lg:flex justify-center xl:justify-start lg:w-full">
              <div className="grid md:grid-cols-2 gap-3 mt-3 w-full">
                {cardData.map((card, index) => (
                  <div key={index} className="h-full">
                    <DashboardCard {...card} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full">
              <ActivityFeed pageSize={3} />
            </div>
          )}

          <div
            className={`lg:-mt-52 ${
              window.innerWidth <= 640 ? "mt-0" : ""
            } lg:ml-auto`}
          >
            <div
              className={`hidden lg:flex justify-center flex-col items-center gap-4 border-4 border-white p-4 rounded-lg mt-4 sm:mt-0 sm:flex-row ${
                window.innerWidth <= 640 ? "bg-purple-900" : ""
              }`}
            >
              <div className="border-t-4 border-white w-10 "></div>
              <p className="lg:text-white text-center lg:text-3xl font-bold">
                Your Hospitals &gt;&gt;
                <Link
                  to="/hospitals"
                  className="lg:text-white lg:text-3xl font-bold italic underline underline-offset-4 px-3"
                >
                  {" "}
                  {count ?? 0}
                </Link>
              </p>
            </div>
            <div className="border-2 border-purple-900 bg-gray-100 p-4 rounded-lg max-w-full lg:max-w-2xl mt-2 sm:mt-0">
              <div className="flex flex-col gap-5">
                {dashboard && (
                  <>
                    <ExodusArena exodus={dashboard?.exodus ?? 0} />
                    {/* <ChurnInfoCard /> */}
                    <Reminders
                      pitch={dashboard?.pitch ? dashboard?.pitch : 0}
                      close={dashboard?.close ? dashboard?.close : 0}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
