import {
  useQuery,
  useMutation,
  keepPreviousData,
  useQueryClient,
} from "@tanstack/react-query";
import appointmentService from "../services/appointmentService";
import hospitalsService from "../services/allHospital.service";
import hospitalService from "../services/hospital.service";
import activeHospitalService from "../services/activeHospital.service";
import exodusServices from "../services/exodus.service";
import { AxiosResponse } from "axios";

import { useGlobalContext } from "../contexts/globalContext";
import utilities from "../utils/utilities";
interface ResponseData {
  data: any;
  meta: any;
}

export const useGetHospitals = (
  page: number,
  limit: number,
  status: string,
  name: string
) => {
  const { user } = useGlobalContext();
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["hospitals", page, limit, status, name],
    enabled: user?.position === "customer_success",
    queryFn: async () => {
      const response = (await hospitalsService.getAllHospitals(
        status ? (user?.id as string) : "",
        page,
        limit,
        status,
        name
      )) as AxiosResponse<ResponseData>;
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const hospitals: any = data?.data?.data || [];
  const paginate: any = data?.data?.meta;

  return { isError, isSuccess, hospitals, paginate, isPending };
};

export const useGetSingleHospital = (id: string) => {
  const { isError, isSuccess, data, isFetching, isPending, refetch } = useQuery(
    {
      queryKey: ["single-hospital", id],
      enabled: !!id,
      queryFn: async () => {
        const response = await hospitalsService.getHospital(id);
        return response.data as any;
      },
    }
  );

  const hospital: any = data?.data;

  return { isError, isSuccess, hospital, isFetching, isPending, refetch };
};

export const useGetHospitalHistory = (id: string) => {
  const { isError, isPending, data, isFetching } = useQuery({
    queryKey: ["hospital-history", id],
    enabled: !!id,
    queryFn: async () => {
      const response: any = await hospitalsService.getHospitalHistory(id);
      return response;
    },
  });

  const hospitalHistory: any = data?.data?.data || [];

  return { isError, isPending, hospitalHistory, isFetching };
};

export const useCreateCallLog = (hospital_ref: string) => {
  const {
    isError,
    isSuccess,
    mutate: mutateCallLog,
    isPending,
  } = useMutation({
    mutationKey: ["call-logs"],
    mutationFn: async (data: any) => {
      const response: any = await hospitalsService.createCallLog(
        data,
        hospital_ref
      );
      return response;
    },
  });

  return { isError, isSuccess, mutateCallLog, isPending };
};

export const useCallCount = (ref_id: string, status: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["call-count", ref_id, status],
    queryFn: async () => {
      const response: any = await hospitalsService.callCount(ref_id, status);
      return response;
    },
  });

  const callCount: any = data?.data?.count ?? 0;
  const callHistory: any = data?.data?.data || [];

  return { isError, isSuccess, callCount, callHistory, isPending };
};

export const useGetStatusHistoryCalls = (id: string) => {
  const { isError, isSuccess, data, isFetching, isPending } = useQuery({
    queryKey: ["status-history-calls", id],
    enabled: !!id,
    queryFn: async () => {
      const response: any = await hospitalsService.getStatusHistoryCalls(id);
      return response;
    },
  });

  const statusHistoryCalls: any = data?.data?.data || [];

  return { isError, isSuccess, statusHistoryCalls, isFetching, isPending };
};

export const useGetCurrentActiveSchedule = (hospital_ref: string) => {
  const { isError, isSuccess, data, isFetching } = useQuery({
    queryKey: ["current-active-schedule", hospital_ref],
    queryFn: async () => {
      const response: any = await hospitalsService.currentActiveSchedule(
        hospital_ref
      );
      return response;
    },
  });

  const review = data?.data?.data;

  return { isError, isSuccess, review, isFetching };
};

export const useUpdateDangerHospitals = () => {
  const {
    isError,
    isSuccess,
    mutate: updateDangerHospitals,
    isPending,
  } = useMutation({
    mutationKey: ["danger-hospitals"],
    mutationFn: async (data: any) => {
      const response = await hospitalsService.updateDangerHospitals(data);
      return response;
    },
  });

  return { isError, isSuccess, updateDangerHospitals, isPending };
};

export const useGetDangerTrack = (ref: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["danger-track", ref],
    queryFn: async () => {
      const response = await hospitalsService.getDangerTrack(ref);
      return response as any;
    },
  });

  const dangerTrack = data?.data?.data;

  return { isError, isSuccess, dangerTrack, isPending };
};

export const useUpdateDangerTrack = () => {
  const {
    isError,
    isSuccess,
    mutate: updateDangerTrack,
    isPending,
  } = useMutation({
    mutationKey: ["danger-track"],
    mutationFn: async (data: any) => {
      const response: any = await hospitalsService.updateDangerTrack(data);
      return response;
    },
  });

  return { isError, isSuccess, updateDangerTrack, isPending };
};

// confirm pre active mastery
export const useConfirmMastery = () => {
  const {
    isError,
    isSuccess,
    mutate: confirmMastery,
    isPending,
  } = useMutation({
    mutationKey: ["confirm-mastery"],
    mutationFn: async (data: any) => {
      const response = await hospitalsService.confirmMastery(data);
      return response;
    },
  });

  return { isError, isSuccess, confirmMastery, isPending };
};

export const useCompleteOnboarding = () => {
  const {
    isError,
    isSuccess,
    mutate: completeOnboarding,
    isPending,
  } = useMutation({
    mutationKey: ["complete-onboarding"],
    mutationFn: async (data: any) => {
      const response: any = await hospitalsService.completeOnboarding(data);
      return response;
    },
  });

  return { isError, isSuccess, completeOnboarding, isPending };
};

// newly active complete schedule
export const useCompleteSchedule = () => {
  const {
    isError,
    isSuccess,
    mutate: completeSchedule,
    isPending,
  } = useMutation({
    mutationKey: ["complete-schedule"],
    mutationFn: async (data: any) => {
      const response = await hospitalsService.completeSchedule(data);
      return response;
    },
  });

  return { isError, isSuccess, completeSchedule, isPending };
};

export const useCompleteMastery = () => {
  const {
    isError,
    isSuccess,
    mutate: completeMastery,
    isPending,
  } = useMutation({
    mutationKey: ["complete-mastery"],
    mutationFn: async (data: any) => {
      const response = await hospitalsService.completeMastery(data);
      return response;
    },
  });

  return { isError, isSuccess, completeMastery, isPending };
};

export const useCheckMissedMaintenance = (hospital_ref: any) => {
  const { isError, isSuccess, data, isFetching } = useQuery({
    queryKey: ["missed-maintenance", hospital_ref],
    queryFn: async () => {
      const response = await hospitalsService.checkMissedMaintenance(
        hospital_ref
      );
      return response;
    },
  });

  return { isError, isSuccess, data, isFetching };
};

export const useCheckManualOrder = (hospital_ref: any) => {
  const { isError, isSuccess, data, isFetching } = useQuery({
    queryKey: ["manual-order", hospital_ref],
    queryFn: async () => {
      const response = await hospitalsService.checkManualOrder(hospital_ref);
      return response;
    },
  });

  return { isError, isSuccess, data, isFetching };
};

export const useGetProcurementCycle = (hospital_ref: any) => {
  const { isError, isSuccess, data, isFetching } = useQuery({
    queryKey: ["procurement-cycle", hospital_ref],
    queryFn: async () => {
      const response: any = await hospitalsService.getProcurementCycle(
        hospital_ref
      );
      return response;
    },
  });

  const procurementCycle = data?.data?.procurement_cycle;

  return { isError, isSuccess, procurementCycle, isFetching };
};

export const useGetActiveHospitals = () => {
  const { isError, isSuccess, data, isFetching } = useQuery({
    queryKey: ["active-hospitals"],
    queryFn: async () => {
      const response: any = await appointmentService.getSuccessActive();
      return response;
    },
  });

  const activeHospitals: any = data?.data?.data || [];

  return { isError, isSuccess, activeHospitals, isFetching };
};

export const useActiveHospital = () => {
  const { user } = useGlobalContext();
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["active-hospital"],
    enabled: user?.position === "sales_rep",
    queryFn: async () => {
      const response = (await activeHospitalService.getActiveHospital(
        user?.id as string
      )) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const activeHospitals: any = data?.data?.data || [];
  const activePaginate: any = data?.data?.meta;

  return { isError, isSuccess, activeHospitals, activePaginate, isPending };
};

export const useGetAllHospitals = (city: string, id: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["all-hospitals", city, id],
    queryFn: async () => {
      const response: any = await hospitalService.getAllHospitals(city, id);
      return response;
    },
  });

  const allHospitals = data?.data?.hospitals || [];

  return { isError, isSuccess, allHospitals, isPending };
};

export const useGetSingleActiveHospital = (id: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["single-hospital", id],
    queryFn: async () => {
      const response: any = await exodusServices.singleExodus(id);
      return response;
    },
  });

  const singleHospital: any = data?.data;

  return { isError, isSuccess, singleHospital, isPending };
};

export const useGetFacilityTypes = () => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["facility-types"],
    queryFn: async () => {
      const response: any = await hospitalService.getFacilityTypes();
      return response?.data;
    },
  });

  const facilityResponse = data?.data || {};

  const facilityTypes = utilities.mapItemsFromObjects(facilityResponse);

  return { isError, isSuccess, facilityTypes, isPending };
};

export const useGetFacilitySizes = () => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["facility-sizes"],
    queryFn: async () => {
      const response: any = await hospitalService.getFacilitySizes();
      return response?.data;
    },
  });

  const facilityResponse = data?.data || {};

  const facilitySizes = utilities.mapItemsFromObjects(facilityResponse);

  return { isError, isSuccess, facilitySizes, isPending };
};

export const useAddFacilityTypes = () => {
  const queryClient = useQueryClient();
  const {
    isError,
    isSuccess,
    mutate: addFacilityTypes,
    isPending,
  } = useMutation({
    mutationKey: ["add-facility-types"],
    mutationFn: async (data: any) => {
      const response: any = await hospitalService.addFacilityType(data);
      return response;
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["facility-types"],
      });
    },
  });

  return { isError, isSuccess, addFacilityTypes, isPending };
};

export const useAddFacilitySizes = () => {
  const queryClient = useQueryClient();
  const {
    isError,
    isSuccess,
    mutate: addFacilitySizes,
    isPending,
  } = useMutation({
    mutationKey: ["add-facility-sizes"],
    mutationFn: async (data: any) => {
      const response: any = await hospitalService.addFacilitySize(data);
      return response;
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["facility-sizes"],
      });
    },
  });

  return { isError, isSuccess, addFacilitySizes, isPending };
};

export const useGetHospitalCancelledOrder = (filters: {
  page: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  hospital_id?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["cancelled-order"],
    queryFn: async () => {
      const response: any = await hospitalService.getHospitalCancelledOrder(
        filters
      );
      return response?.data;
    },
    placeholderData: keepPreviousData,
  });

  const cancelledOrder = data?.data || [];
  const paginate: any = data?.data?.meta;

  // const facilitySizes = utilities.mapItemsFromObjects(facilityResponse);

  return { isError, isSuccess, isPending, cancelledOrder, paginate };
};

export const useGetHospitalRating = (filters: {
  page: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  hospital_id?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["rating"],
    queryFn: async () => {
      const response: any = await hospitalService.getHospitalRating(filters);
      return response?.data;
    },
    placeholderData: keepPreviousData,
  });

  const rating = data?.data || [];
  const paginate: any = data?.data?.meta;

  // const facilitySizes = utilities.mapItemsFromObjects(facilityResponse);

  return { isError, isSuccess, isPending, rating, paginate };
};

export const useUpdateHospitalAddress = () => {
  const {
    isError,
    isSuccess,
    mutate: mutateUpdateHospitalAddress,
    isPending,
  } = useMutation({
    mutationKey: ["update-address"],
    mutationFn: async (data: any) => {
      const response = await hospitalService.updateHospitalAddress(data);
      return response;
    },
  });

  return { isError, isSuccess, mutateUpdateHospitalAddress, isPending };
};
