import React, { useState } from "react";
// @ts-ignore
import Joi from "joi-browser";

import LoadingButton from "../common/loadingButton";

import toastService from "../../services/toastService";
import { SelectOption } from "../../ts-utils/interfaces";

import { useScheduleAppointment } from "../../hooks/sales/useAppointment";
import { useNavigate } from "react-router-dom";
interface ScheduleFormProps {
  hospital_id: string;
  loading: boolean;
  width: boolean;
}

interface FormData {
  orders: string;
  when: string;
  frequency: string;
  amount: string;
}

const ScheduleForm = (props: ScheduleFormProps) => {
  const navigate = useNavigate();
  const [data, setData] = useState<FormData>({
    orders: "",
    when: "",
    frequency: "",
    amount: "",
  });

  const { mutateSchedule, isPending } = useScheduleAppointment();

  const schema = {
    orders: Joi.string().required().label("Number of orders"),
    when: Joi.string().required().label("When is it needed?"),
    frequency: Joi.string().required().label("Frequency"),
    amount: Joi.string().required().label("Amount"),
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors: { [key: string]: string } = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  const getApiData = () => {
    const { hospital_id } = props;

    const apiData = {
      ...data,
      hospital_id,
    };

    return apiData;
  };

  const doSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const apiData = getApiData();
    mutateSchedule(apiData, {
      onSuccess: () => {
        toastService.success("Pipeline updated successfully!");
        navigate("/pipeline?content=close");
      },
      onError: (error: { message: string }) => {
        toastService.error(error.message || "Failed to update pipeline");
      },
    });
  };

  function renderSelect({
    name,
    label,
    options,
    placeholder,
    compulsory,
    value,
    onChange,
    error,
  }: {
    name: string;
    label: string;
    options: SelectOption[];
    placeholder: string;
    compulsory: boolean;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    error?: string;
  }): React.ReactNode {
    return (
      <div className="form-group mb-3 space-y-1 px-2">
        <label htmlFor={name}>
          {compulsory && <i className="fa fa-asterisk error"></i>}
          {label}
        </label>
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={`form-control ${error ? "is-invalid" : ""}`}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
  function renderInput({
    type,
    name,
    placeholder,
    label,
    compulsory,
    value,
    onChange,
    error,
  }: {
    type: string;
    name: string;
    placeholder: string;
    label: string;
    compulsory: boolean;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
  }): React.ReactNode {
    return (
      <div className="form-group mb-3 space-y-1 px-2">
        <label htmlFor={name}>
          {compulsory && <i className="fa fa-asterisk error"></i>}
          {label}
        </label>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`form-control ${error ? "is-invalid" : ""}`}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }

  function renderButton(label: string): React.ReactNode {
    return (
      <button disabled={!!validate()} type="submit" className="btn btn-primary">
        {label}
      </button>
    );
  }

  return (
    <div {...props}>
      <form onSubmit={doSubmit} className="flex flex-col gap-2 w-full">
        <h2 className="form-header">
          Fields marked <i className="fa fa-asterisk error"></i> are required
        </h2>
        <div className="row">
          <div className="col col-lg-12">
            {renderInput({
              type: "text",
              name: "orders",
              placeholder: "e.g. Blood",
              label: "What is your order?",
              compulsory: true,
              value: data.orders,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, orders: e.currentTarget.value });
              },
            })}
          </div>
        </div>
        <div className="row">
          <div className={"col col-lg-12"}>
            {renderInput({
              type: "text",
              name: "when",
              placeholder: "e.g. In 30days",
              label: "When is it needed?",
              compulsory: true,
              value: data.when,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, when: e.currentTarget.value });
              },
            })}
          </div>
        </div>
        <div className="row">
          <div className={"col col-lg-12"}>
            {renderSelect({
              name: "frequency",
              placeholder: "Select Credit Request",
              label: "Credit Request",
              options: freqTypes,
              compulsory: true,
              value: data.frequency,
              onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                setData({ ...data, frequency: e.currentTarget.value });
              },
            })}
          </div>
        </div>
        <div className="row">
          <div className={"col col-lg-12"}>
            {renderInput({
              type: "text",
              name: "amount",
              placeholder: "e.g. 1000",
              label: "Amount",
              compulsory: true,
              value: data.amount,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, amount: e.currentTarget.value });
              },
            })}
          </div>
        </div>
        <div className="flex w-full items-center justify-between mb-5">
          <LoadingButton visible={isPending} message="Please wait" />
          {renderButton("Schedule")}
        </div>
      </form>
    </div>
  );
};

const freqTypes = [
  { id: "daily", name: "Daily" },
  { id: "weekly", name: "Weekly" },
  { id: "monthly", name: "Monthly" },
];

export default ScheduleForm;
