import React, { useMemo, useState } from "react";
// @ts-ignore
import Joi from "joi-browser";

import Modal from "../common/modal";
import withAgents from "../hoc/withAgents";

import { SelectOption } from "../../ts-utils/interfaces";
import toastService from "../../services/toastService";

import utilities from "../../utils/utilities";

import { useSetTarget } from "../../hooks/useDashboard";
import LoadingButton from "../common/loadingButton";
import { useGetAllAgent } from "../../hooks/agent/useAgent";
import { getPrivileges } from "../../services/authService";

interface SetTargetsFormProps {
  agents: Array<{ id: string; username: string }>;
  handleClose: () => void;
}

interface SetTargetsFormState {
  data: {
    [key: string]: string;
    agent_id: string;
    total_hospitals: string;
    onboarded_hospitals: string;
    active_hospitals: string;
    revenue: string;
    newly_unboarded_revenue: string;
    active_revenue: string;
    digital_marketplace: string;
  };
  errors: { [key: string]: string };
}
interface Schema {
  [key: string]: Joi.ObjectSchema;
}
const SetTargetsForm: React.FC<SetTargetsFormProps> = (props) => {
  const permission = getPrivileges();
  const { mutateSetTarget, isPending } = useSetTarget();

  // const { agents } = useGetAllAgents();
  const { agent } = useGetAllAgent(permission.createAgent as boolean);

  // filter agent with position sales_rep or sales_lead
  const filterAgents = useMemo(
    () =>
      agent
        ?.filter((agent: { position: string }) =>
          ["sales_rep", "sales_lead"].includes(agent.position)
        )
        .map((agent: { id: string; username: string }) => {
          return {
            id: agent.id,
            name: agent.username,
          };
        }) || [],
    [agent]
  );

  const [data, setData] = useState<SetTargetsFormState["data"]>({
    agent_id: "",
    total_hospitals: "",
    onboarded_hospitals: "",
    active_hospitals: "",
    revenue: "",
    newly_unboarded_revenue: "",
    active_revenue: "",
    digital_marketplace: "",
  } as SetTargetsFormState["data"]);
  const [errors, setErrors] = useState<SetTargetsFormState["errors"]>({});

  const schema: Schema = {
    agent_id: Joi.string().required().label("Agent"),
    total_hospitals: Joi.number()
      .min(1)
      .required()
      .label("Total New Hospitals"),
    onboarded_hospitals: Joi.number()
      .min(1)
      .required()
      .label("Monthly Onboarded Hospitals"),
    active_hospitals: Joi.number().min(1).required().label("Active Hospitals"),
    revenue: Joi.number().min(50000).required().label("Monthly Revenue"),
    newly_unboarded_revenue: Joi.number()
      .min(25000)
      .required()
      .label("Revenue From Newly Onboarded Hospitals"),
    active_revenue: Joi.number()
      .min(25000)
      .required()
      .label("Revenue From Active Hospitals"),
    digital_marketplace: Joi.number()
      .min(1)
      .required()
      .label("Hospitals that used our digital marketplace"),
  };
  function validateProperty(
    input: EventTarget & (HTMLInputElement | HTMLSelectElement)
  ): string | undefined {
    const { name, value } = input;
    const { error } = Joi.validate({ [name]: value }, { [name]: schema[name] });
    return error ? error.details[0].message : undefined;
  }

  const getErrorMessage = (e: any): string => {
    return e?.response?.data?.message || e.message || "Could not set targets";
  };
  function validate() {
    const { error } = Joi.validate(data, schema, { abortEarly: false });
    if (!error) return null;

    const errors: { [key: string]: string } = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget;

    const newData = { ...data };
    const newErrors = { ...errors };

    const errorMessage = validateProperty(input);
    newData[input.name] = input.value;
    newErrors[input.name] = errorMessage || "";

    setData(newData);
    setErrors(newErrors);
  };

  const handleSelectAgent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const input = e.currentTarget;

    const newData = { ...data };
    const newErrors = { ...errors };

    const errorMessage = validateProperty(input);
    newData[input.name] = input.value;
    newErrors[input.name] = errorMessage || "";

    setData(newData);
    setErrors(newErrors);
  };

  // const mapAgents = (): SelectOption[] => {
  //   return filterAgents.map((a: { id: string; username: string }) => ({
  //     id: a.id,
  //     name: a.username,
  //   }));
  // };

  const postData = async (): Promise<void> => {
    mutateSetTarget(data, {
      onSuccess: () => {
        props.handleClose();
      },
      onError: (e) => {
        throw new Error(getErrorMessage(e));
      },
    });
    // const response = await setTarget(data, handleProgressChange);
    // const result: any = response.data;
    // if (!result.success) throw new Error(result.description);

    // props.handleClose();
  };

  const doSubmit = (): void => {
    toastService.promise(postData(), {
      loading: "Uploading Targets...",
      success: () => "Targets Set Successfully",
      error: (e: any) => getErrorMessage(e),
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    doSubmit();
  };

  const currentMonthLabel: string = utilities.getMonthLabel();

  function renderSelect({
    name,
    label,
    options,
    placeholder,
    compulsory,
    value,
    onChange,
    error,
  }: {
    name: string;
    label: string;
    options: SelectOption[];
    placeholder: string;
    compulsory: boolean;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    error: string;
  }): React.ReactNode {
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={`form-control ${error ? "is-invalid" : ""}`}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }

  function renderInput({
    type,
    name,
    placeholder,
    label,
    compulsory,
    value,
    onChange,
    error,
  }: {
    type: string;
    name: string;
    placeholder: string;
    label: string;
    compulsory: boolean;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error: string;
  }): React.ReactNode {
    return (
      <div className="form-group mb-3 space-y-3 px-2">
        <label htmlFor={name}>
          {compulsory && <i className="fa fa-asterisk error"></i>}
          {label}
        </label>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`form-control ${error ? "is-invalid" : ""}`}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }

  function renderButton(label: string): React.ReactNode {
    return (
      <button disabled={!!validate()} type="submit" className="btn btn-primary">
        {label}
      </button>
    );
  }

  return (
    <Modal handleClose={props.handleClose}>
      <div className="container">
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <h3 className="modal-title text-center primary">
            Set <span className="error">Targets</span> for {currentMonthLabel}
          </h3>

          {renderSelect({
            name: "agent_id",
            label: "Select Agent",
            options: filterAgents,
            placeholder: "Select Agent",
            compulsory: true,
            value: data.agent_id,
            onChange: handleSelectAgent,
            error: errors.agent_id,
          })}

          {renderInput({
            type: "number",
            name: "total_hospitals",
            placeholder: "e.g. 5",
            label: "Expected Total New Hospitals",
            compulsory: true,
            value: data.total_hospitals,
            onChange: handleChange,
            error: errors.total_hospitals,
          })}

          {renderInput({
            type: "number",
            name: "digital_marketplace",
            placeholder: "e.g. 10",
            compulsory: true,
            label: "Hospitals to Use Our Digital Marketplace",
            value: data.digital_marketplace,
            onChange: handleChange,
            error: errors.digital_marketplace,
          })}

          <div className="row">
            <div className="col-lg-6">
              {renderInput({
                type: "number",
                name: "active_hospitals",
                placeholder: "e.g. 5",
                label: "Hospitals to Move to Active",
                compulsory: true,
                value: data.active_hospitals,
                onChange: handleChange,
                error: errors.active_hospitals,
              })}
            </div>

            <div className="col-lg-6">
              {renderInput({
                type: "number",
                name: "onboarded_hospitals",
                placeholder: "e.g. 5",
                label: "Total Hospitals to Onboard",
                compulsory: true,
                value: data.onboarded_hospitals,
                onChange: handleChange,
                error: errors.onboarded_hospitals,
              })}
            </div>
          </div>

          {renderInput({
            type: "number",
            name: "revenue",
            placeholder: "e.g. 50,000",
            compulsory: true,
            // currencyFormat: true,
            label: "Expected Total Revenue",
            value: data.revenue,
            onChange: handleChange,
            error: errors.revenue,
          })}

          <div className="row">
            <div className="col-lg-6">
              {renderInput({
                type: "number",
                name: "active_revenue",
                placeholder: "e.g. 25,000",
                compulsory: true,
                // currencyFormat: true,
                label: "Revenue From Active Hospitals",
                value: data.active_revenue,
                onChange: handleChange,
                error: errors.active_revenue,
              })}
            </div>

            <div className="col-lg-6">
              {renderInput({
                type: "number",
                name: "newly_unboarded_revenue",
                placeholder: "e.g. 25,000",
                // currencyFormat: true,
                compulsory: true,
                label: "Revenue From Newly Onboarded Hospitals",
                value: data.newly_unboarded_revenue,
                onChange: handleChange,
                error: errors.newly_unboarded_revenue,
              })}
            </div>
          </div>

          {/* <div className="modal-buttons">{renderButton("Set")}</div> */}
          <div className="d-flex justify-content-end ">
            {isPending ? (
              <LoadingButton visible message="Please wait" />
            ) : (
              renderButton("Set")
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default withAgents(SetTargetsForm);
