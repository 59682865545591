import React, { useEffect, useState } from "react";

import hospitalService from "../../services/hospital.service";
import toastService from "../../services/toastService";

import utilities from "../../utils/utilities";

function withFacilityType(Component) {
  return function WithFacilityType(props) {
    const [facilityType, setFacilityType] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFacilityTypesData = async () => {
      try {
        setLoading(true);

        const response = await hospitalService.getFacilityTypes();
        const responseData = response?.data?.data || {};
        const facilityTypes = utilities
          .mapItemsFromObjects(responseData)
          .map((t) => ({ id: t.id, name: t.category }));

        setFacilityType(facilityTypes);
        setLoading(false);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.description ?? error.message;
        toastService.error(errorMessage);
        setLoading(false);
      }
    };

    useEffect(() => {
      getFacilityTypesData();
    }, []);

    return (
      <Component facilityType={facilityType} loading={loading} {...props} />
    );
  };
}

export default withFacilityType;
