import { useState, RefObject } from "react";
import { SlashIcon, ReloadIcon } from "@radix-ui/react-icons";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../../../@/components/ui/breadcrumb";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../../@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../@/components/ui/form";
import { Input } from "../../../../@/components/ui/input";

import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../@/components/ui/radio-group";

import { Textarea } from "../../../../@/components/ui/textarea";
import EventDetails from "./EventDetails";
import {
  useCreateEvent,
  useGetSingleEvent,
} from "../../../../hooks/marketing/useEvents";
import ProgressLoader from "../../../../components/common/progressLoader";
import { usePlacesWidget } from "react-google-autocomplete";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "../../../../components/common/loadingButton";
import toastService from "../../../../services/toastService";
import Flatpickr from "react-flatpickr";

export function BreadcrumbWithCustomSeparator({
  event_id,
}: {
  event_id: string | null;
}) {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbLink href="/events">Events</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>
        <BreadcrumbItem>
          <BreadcrumbPage className="font-bold">
            {!event_id ? "Create Event" : "Event Details"}{" "}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
}
const FormSchema = z.object({
  title: z.string().min(2, {
    message: "Enter the name of your event",
  }),

  type: z.enum(["virtual", "physical"], {
    required_error: "You need to select an event type.",
  }),
  date: z.date({
    required_error: "A event date is required.",
  }),
  start_time: z.string({
    required_error: "A event start time is required.",
  }),
  end_time: z.string({
    required_error: "A event end time is required.",
  }),
  location: z.string({
    required_error: "A event location is required.",
  }),

  longitude: z.string(),
  latitude: z.string(),

  description: z.string().min(10, {
    message: "Description must be at least 10 characters.",
  }),

  speaker: z.string().min(2, {
    message: "Please enter a speaker name",
  }),
});
export function InputForm() {
  const router = useNavigate();
  const [country] = useState("ng");
  const [progress, setProgress] = useState(0);
  const [header, setHeader] = useState<File | null>(null);
  const [headerError, setHeaderError] = useState(false);

  const handleProgressChange = (progress: any) => {
    setProgress(progress);
  };
  const { mutate, isPending } = useCreateEvent(handleProgressChange);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: "onChange",
    defaultValues: {
      title: "",
      type: "virtual",
      date: new Date(),
      start_time: "",
      end_time: "",
      description: "",
      location: "",
      speaker: "",
      longitude: "",
      latitude: "",
    },
  });

  const { setValue } = form;

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const maxSize = 1024 * 1024 * 10;
      if (file.size <= maxSize) {
        setHeader(file);
      } else {
        console.log("error");
        toastService.error(
          "File size exceeds the limit, please choose image less than 10MB"
        );
      }
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDQjd9fzSdMQzaCXmjXMlfJILG53ZVJs5M",
    onPlaceSelected: (place: any) => {
      const latitudePlace = place.geometry.location.lat().toString();
      const longitudePlace = place.geometry.location.lng().toString();
      const address = place.formatted_address;
      setValue("location", address);
      setValue("latitude", latitudePlace);
      setValue("longitude", longitudePlace);
    },

    inputAutocompleteValue: "country",
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country },
    },
  });
  function onSubmit(data: z.infer<typeof FormSchema>) {
    // const speakers = data.speaker.split(",").map((s) => s.trim());
    // console.log("Speakers:", speakers);
    if (!header) {
      setHeaderError(true);
      return;
    }
    const date = dayjs(new Date(data.date)).format("YYYY/MM/DD");

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("category", "no category");
    formData.append("type", data.type);
    formData.append("date", date);
    formData.append("start_time", data.start_time);
    formData.append("end_time", data.end_time);
    formData.append("location", data.location);
    formData.append("lat", data.latitude);
    formData.append("long", data.longitude);
    formData.append("description", data.description);
    formData.append("speaker", data.speaker);
    if (header) {
      formData.append("header", header);
    }

    mutate(formData, {
      onSuccess: (data: any) => {
        const eventDetails = data.data.id;
        router(`/events/create-event?id=${eventDetails.id}`);

        localStorage.setItem("event", JSON.stringify(eventDetails));
        form.reset();
      },
      onError: (error: any) => {
        console.log("error", error);
        setProgress(0);
      },
    });
  }

  return (
    <Form {...form}>
      <ProgressLoader progress={progress} />
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="md:w-2/3 space-y-6"
      >
        <div className="space-y-5">
          <div className="grid md:grid-cols-6">
            <div></div>
            <h2 className="text-2xl col-span-5">Event Details</h2>
          </div>
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-col md:flex-row md:items-center gap-3 w-full">
                  <FormLabel className="md:w-1/6">Event Title</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter the name of your event"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-col md:flex-row md:items-center gap-3 w-full">
                  <FormLabel className="md:w-1/6">Event Category</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a category" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="blood">Blood bank</SelectItem>
                      <SelectItem value="oxygen">Oxygen</SelectItem>
                      <SelectItem value="support">Medical Support</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          /> */}
        </div>
        <div className="space-y-5">
          <div className="grid md:grid-cols-6">
            <div></div>
            <h2 className="text-2xl col-span-5">Data & Time</h2>
          </div>
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <div className="flex  flex-col md:flex-row md:items-center gap-3 w-full">
                  <FormLabel className="md:w-1/6">Event Type</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex item-center"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="virtual" />
                        </FormControl>
                        <FormLabel className="font-normal cursor-pointer">
                          Virtual
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="physical" />
                        </FormControl>
                        <FormLabel className="font-normal cursor-pointer">
                          Physical
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />

          <div
            className="grid grid-cols-6
          items-center"
          >
            <div className="hidden md:block">Session(s)</div>
            <div className="flex items-center col-span-5">
              <div className="flex gap-2 flex-wrap">
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Start Date</FormLabel>
                      <Flatpickr
                        value={field.value}
                        options={{
                          mode: "single", // Enables date range selection
                          dateFormat: "m/d/Y", // Customize the format as per your requirement
                          altInput: true,
                          altFormat: "F j, Y",
                          minDate: "today",
                        }}
                        onChange={(value) => field.onChange(value[0])}
                        className="w-full border p-2 rounded-md"
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="start_time"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Start Time</FormLabel>
                      <FormControl>
                        <Input type="time" {...field} />
                      </FormControl>
                      <div className="flex items-center justify-end">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="end_time"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>End Time</FormLabel>
                      <FormControl>
                        <Input type="time" {...field} />
                      </FormControl>
                      <div className="flex items-center justify-end">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-5">
          <div className="grid md:grid-cols-6">
            <div></div>
            <h2 className="text-2xl col-span-5">Location</h2>
          </div>
          {/* <input
            type="text"
            ref={ref as unknown as RefObject<HTMLInputElement>}
          /> */}{" "}
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <div className="flex  flex-col md:flex-row md:items-center gap-3">
                  <FormLabel className="md:w-1/6">
                    Where will your event take place?
                  </FormLabel>

                  <Input
                    {...field}
                    placeholder="Please enter a location"
                    ref={ref as unknown as RefObject<HTMLInputElement>}
                  />
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
           
            )}
          /> */}
        </div>
        <div className="space-y-5">
          <div className="grid md:grid-cols-6">
            <div></div>
            <h2 className="text-2xl col-span-5">Additional Information</h2>
          </div>
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <div className="flex  flex-col md:flex-row md:items-center  gap-3 w-full">
                  <FormLabel className="md:w-1/6">Event Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe what's special about your event & other important details."
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />{" "}
          <FormField
            control={form.control}
            name="speaker"
            render={({ field }) => (
              <FormItem>
                <div className="flex  flex-col md:flex-row md:items-center gap-3">
                  <FormLabel className="md:w-1/6">Speaker Name </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Please enter a speaker name"
                      {...field}
                    />
                  </FormControl>
                </div>
                <div className="flex items-center justify-end">
                  <FormMessage />
                </div>
                <div className="flex items-center justify-end">
                  <FormDescription>
                    Add multiple speakers by separating them with a comma
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
        </div>
        <div className="space-y-5">
          <div className="grid md:grid-cols-6">
            <div></div>
            <h2 className="text-2xl col-span-5">Upload Header</h2>
          </div>

          <FormItem>
            <div className="flex  flex-col md:flex-row md:items-center  gap-3">
              <FormLabel className="md:w-1/6"></FormLabel>
              <FormControl>
                <Input
                  type="file"
                  accept=".jpeg, .png, .jpg, .gif"
                  placeholder="Please upload a header image"
                  onChange={uploadImage}
                />
              </FormControl>
            </div>
            {headerError && (
              <div className="flex items-center justify-end">
                <span className="text-red-400 text-sm">
                  Please upload a header image
                </span>
              </div>
            )}

            <div className="grid md:grid-cols-6">
              <div></div>
              <div className="col-span-5">
                <FormDescription>
                  Feature Image must be at least 1170 pixels wide by 504 pixels
                  high. Valid file formats: JPG, GIF, PNG.
                </FormDescription>
              </div>
            </div>
          </FormItem>
        </div>
        <div className="flex justify-end">
          <Button disabled={!form.formState.isValid || isPending} type="submit">
            {isPending && <ReloadIcon className="animate-spin mr-2 h-4 w-4" />}
            {isPending ? "Please wait..." : " Save & Continue"}
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default function CreateEvents() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const event_id = searchParams.get("id");
  const { event, isPending, baseUrl } = useGetSingleEvent(event_id);

  if (isPending && event_id) {
    return (
      <LoadingButton
        visible={isPending}
        message="Fetching Event... Please wait"
      />
    );
  }
  return (
    <div className="bg-[beige] p-3 md:p-5">
      <BreadcrumbWithCustomSeparator event_id={event_id} />

      {event && event_id ? (
        <EventDetails eventDetail={event[0]} baseUrl={baseUrl} />
      ) : (
        <div className="mt-4">
          <h1 className="font-semibold text-4xl mt-2 mb-4">
            {!event_id && "Create a New Event"}
          </h1>
          <div className="mx-auto max-w-6xl ">
            <InputForm />
          </div>
        </div>
      )}
    </div>
  );
}
