import { useNavigate } from "react-router-dom";
import { PipelineItem } from "../../../../ts-utils/interfaces";
import { cn } from "../../../../@/lib/utils";

const ChurnPipeline = ({
  activeCategory,
  onActiveChange,
  data,
}: any): JSX.Element => {
  const pipelineItems: PipelineItem[] = [
    {
      title: "Full Churn Assigned",
      tag: "assign",
      color: "#424200",
      icon: "hospital-o",
      sortKey: "created_at",
      data: data?.assign,
    },
    {
      title: "Prospect",
      tag: "pitches",
      color: "#441AB7",
      icon: "newspaper-o",
      sortKey: "pitch_date",
      data: data?.pitch,
    },
    {
      title: "Reboard Pitch",
      tag: "close",
      color: "#b71C1C",
      icon: "plus-circle",
      sortKey: "prospect_date",
      data: data?.close,
    },
    {
      title: "Closed",
      tag: "onboard",
      color: "#424200",
      icon: "book",
      sortKey: "onboard_date",
      data: data?.onboard,
    },

    {
      title: "Reboarded",
      tag: "Imported",
      color: "#09A5C5",
      icon: "ticket",
      sortKey: "stalled_date",
      data: data?.imported,
    },
  ];

  const router = useNavigate();
  const navigate = (path: string) => {
    router(`/full-churn?content=${path}`);
    // history.push(`/pipeline?content=${path}`);
  };
  return (
    <ul id="breadcrumb">
      {pipelineItems.map((pipelineItem: PipelineItem) => {
        const isActive: boolean =
          pipelineItem.tag.toLowerCase() === activeCategory.tag.toLowerCase();
        return (
          <li
            key={pipelineItem.title}
            className={cn(isActive ? "active" : "inactive", "text-sm")}
            style={{ width: `calc(100%/5)` }}
            onClick={() => {
              window.sessionStorage.setItem(
                "churn",
                JSON.stringify(pipelineItem)
              );
              navigate(pipelineItem.tag);
              onActiveChange(pipelineItem);
            }}
          >
            <span
              className="arrowLeft"
              style={{
                borderColor: pipelineItem.color,
                borderLeftColor: "transparent",
              }}
            ></span>
            <p
              className="flex gap-2"
              style={{ backgroundColor: pipelineItem.color }}
            >
              <i className={"fa fa-" + pipelineItem.icon}></i>{" "}
              <span>{pipelineItem.title} </span>
              <span>{pipelineItem.data ? pipelineItem.data : "0"}</span>
            </p>
            <span
              className="arrowRight"
              style={{ borderLeftColor: pipelineItem.color }}
            ></span>
          </li>
        );
      })}
    </ul>
  );
};

export default ChurnPipeline;
