import React, { useEffect, useMemo, useState } from "react";
import {
  useGetProduct,
  useRequestItem,
} from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ItemsCard from "./ItemsCard";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removeFromCart,
  updateItemBrand,
  updateItemPacks,
  updateItemQuantity,
} from "../../../../redux/slices/Cart";
import RequestItem from "./RequestItem";
import toastService from "../../../../services/toastService";
import { SyncLoader } from "react-spinners";

export default function PurchaseItem() {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state;

  const { product, isPending } = useGetProduct(item);

  const [selectedBrand, setSelectedBrand] = useState<{ [key: string]: string }>(
    {}
  );

  const [selectedPackage, setSelectedPackage] = useState<{
    [key: string]: string;
  }>({});

  const cart =
    useSelector((state: { cart: any }) => state.cart) ?? ([] as any[]);
  const [key, setKey] = useState(0);
  // Manage quantities for each item individually
  const [quantities, setQuantities] = useState<{ [key: string]: number }>(() =>
    cart.items.reduce(
      (acc: { [key: string]: number }, item: { id: string; qty: number }) => ({
        ...acc,
        [item.id]: item.qty || 1,
      }),
      {}
    )
  );

  const [error, setError] = React.useState<{ [key: string]: string }>({});

  const handleQuantityChange = (
    e: { target: { value: string } },
    id: string
  ) => {
    const newQuantity = e.target.value;

    // Allow the input to be empty
    if (newQuantity === "") {
      setQuantities((prev) => ({ ...prev, [id]: 0 }));
      return;
    }

    const parsedQuantity = Number(newQuantity);

    // Ensure the parsed quantity is a valid number and greater than 0
    if (!isNaN(parsedQuantity) && parsedQuantity >= 1) {
      setQuantities((prev) => ({ ...prev, [id]: parsedQuantity }));
      dispatch(updateItemQuantity({ id, qty: parsedQuantity }));
    }

    // Clear the error if quantity is valid
    setError((prev) => ({
      ...prev,
      [id]: "",
    }));
  };

  // const handleIncrement = (id: string) => {
  //   setQuantities((prev) => {
  //     const currentQuantity = prev[id] || 0;
  //     const newQuantity = currentQuantity + 1;

  //     // Ensure newQuantity is a valid number
  //     if (!isNaN(newQuantity)) {
  //       dispatch(updateItemQuantity({ id, qty: newQuantity }));
  //       return { ...prev, [id]: newQuantity };
  //     }

  //     return prev;
  //   });
  // };

  const handleIncrement = (id: string) => {
    // First, calculate the new quantity outside the setState function
    const currentQuantity = quantities[id] ?? 0;
    const newQuantity = currentQuantity + 1;

    // Update the state immediately
    setQuantities((prev) => ({ ...prev, [id]: newQuantity }));

    // Dispatch the update action after the state has been set
    dispatch(updateItemQuantity({ id, qty: newQuantity }));

    // Clear the error if quantity is valid
    setError((prev) => ({
      ...prev,
      [id]: "",
    }));
  };

  const handleDecrement = (id: string) => {
    const currentQuantity = quantities[id] ?? 1;
    const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1;

    // Update the state immediately
    setQuantities((prev) => ({ ...prev, [id]: newQuantity }));
  };

  const transformedProduct = useMemo(() => {
    return product?.map(
      (item: {
        id: string;
        name: any;
        description: any;
        price: any;
        icon_url: any;
        brand: string;
        packsize: any;
        cartonsize: any;
        qty: any;
      }) => {
        const selectedItem = cart.items.find(
          (cartItem: { id: string }) => cartItem.id === item.id
        );

        return {
          id: item.id,
          name: item.name,
          description: item.description,
          price: item.price,
          icon_url: item.icon_url,
          itemBrand: item.brand,
          packsize: item.packsize,
          cartonsize: item.cartonsize,
          qty: item.qty,
          brand:
            selectedBrand[item.id] ||
            selectedItem?.brand ||
            item.brand.split(",")[0],

          quantityType:
            selectedPackage[item.id] || selectedItem?.quantityType || "packs",
        };
      }
    );
  }, [cart.items, product, selectedBrand, selectedPackage]);

  const dispatch = useDispatch();

  const isItemInCart = (item: any) => {
    return cart.items.some((cartItem: { id: any }) => cartItem.id === item.id);
  };

  const handleAddToCart = (prod: any) => {
    const quantity = quantities[prod.id];

    if (quantity === undefined || quantity === 0) {
      setError((prev) => ({
        ...prev,
        [prod.id]: "Please add a quantity before adding to cart",
      }));
      return;
    }

    // Clear the error if quantity is valid
    setError((prev) => ({
      ...prev,
      [prod.id]: "",
    }));

    const isItemInCart = cart?.items?.some(
      (cartItem: { id: any }) => cartItem.id === prod.id
    );

    // Get the current quantity or default to 1 if not specified
    const currentQuantity = quantities[prod.id] || 1;

    if (isItemInCart) {
      dispatch(removeFromCart(prod.id));
    } else {
      dispatch(
        addToCart({
          ...prod,
          qty: currentQuantity, // Ensure quantity is added to the item
        })
      );

      // Update the item quantity in the store
      dispatch(updateItemQuantity({ id: prod.id, qty: currentQuantity }));

      setKey((prev) => prev + 1);
    }
  };

  const handleSelectedBrand = (newBrand: string, id: string) => {
    setSelectedBrand((prevSelectedBrand) => ({
      ...prevSelectedBrand,
      [id]: newBrand,
    }));
    dispatch(updateItemBrand({ id: id, brand: newBrand }));
  };

  const handlePackageChange = (pack: string, id: string) => {
    setSelectedPackage((prevSelectedPackage) => ({
      ...prevSelectedPackage,
      [id]: pack,
    }));
    dispatch(updateItemPacks({ id: id, quantityType: pack }));
  };

  const { mutateRequestItem, isPending: isRequesting } = useRequestItem();
  const [inputText, setInputText] = useState("");

  const hospitalId = useSelector(
    (state: { cart: any }) => state.cart.hospital_id
  );
  const data = {
    hospital_id: hospitalId,
    items: inputText,
  };

  const handleSubmit = async () => {
    mutateRequestItem(data, {
      onSuccess: () => {
        toastService.success(
          "Request sent successfully. We will notify you when the item is available"
        );
        setInputText("");
        navigate("/purchasing-orders/category");
      },
      onError: (error: { message: string }) => {
        toastService.error("Failed to send request");
      },
    });
  };

  useEffect(() => {
    const updatedQuantities = cart.items.reduce(
      (acc: { [key: string]: number }, item: { id: string; qty: number }) => ({
        ...acc,
        [item.id]: item.qty || 1,
      }),
      {}
    );
    setQuantities(updatedQuantities);
  }, [cart.items]);

  if (isPending) {
    return (
      <div>
        <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh">
          <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
          <SyncLoader size={30} color="blue" className="relative" />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 shadow-sm p-3">
      {transformedProduct.length === 0 ? (
        <RequestItem
          itemName={item}
          handleSubmit={handleSubmit}
          setInputText={setInputText}
          inputText={inputText}
          isPending={isRequesting}
        />
      ) : (
        <>
          <ItemsCard
            key={key}
            items={transformedProduct}
            selectItem={handleAddToCart}
            isItemInCart={isItemInCart}
            handleSelectedBrand={handleSelectedBrand}
            handlePackageChange={handlePackageChange}
            quantities={quantities}
            handleQuantityChange={handleQuantityChange}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            error={error}
          />

          <div className="mt-8 justify-between sm:flex items-center">
            <div className="flex gap-2 items-center">
              <span className="inline-flex items-center justify-center gap-1 py-2 px-3 font-medium text-sm text-center text-white bg-indigo-600 rounded-lg sm:mt-0">
                {cart?.items?.length}
              </span>
              <span className="font-semibold">Items Added</span>
            </div>
            <Link
              to="/purchasing-orders/cart"
              className="inline-flex items-center justify-center gap-1 py-2 px-3 mt-2 font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg sm:mt-0"
            >
              View Cart
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
