import React, { Key, useState } from "react";
import { useNavigate } from "react-router";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";

interface SmallCardProp {
  Text: string;
  subText: string;
  link?: string | undefined;
}
const SmallCard = ({ Text, subText, link }: SmallCardProp) => {
  const navigate = useNavigate();
  return (
    <div
      role={link && "button"}
      onClick={() => navigate(link as string)}
      className="w-full p-1 h-[65px] bg-[#F7F3FE] border border-black shadow-white shadow-sm flex flex-col items-center justify-around rounded"
    >
      <p className="text-[#49209F] font-bold text-center text-[0.8rem] truncate w-full">
        {Text}
      </p>
      <p className="font-bold text-center text-[0.8rem] truncate w-full">
        {subText}
      </p>
    </div>
  );
};
interface successRelation {
  Text: string;
  subText: string;
  btnText: string;
  onClick: any;
}

const SuccessRelation = ({
  Text,
  subText,
  btnText,
  onClick,
}: successRelation) => {
  return (
    <div
      className="bg-[white] shadow-white border border-black shadow-sm rounded  h-[90px] flex items-center justify-between w-full cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-col w-9/12 p-2">
        <div className="text-[17px] font-bold text-[#49209F]">{Text}</div>
        <div>{subText}</div>
      </div>
      <div className="bg-[#b5b1b1] h-[90px] flex items-center  text-[20px]  text-center cursor-pointer rounded-tr rounded rounded-br p-2">
        {btnText}
      </div>
    </div>
  );
};

interface contactInformationCardProp {
  text: string;
  icon: any;
  mailtext: string;
  subText: string;
  locationText: string;
  phoneText: string;
}

const ContactInformationCard = ({
  text,
  mailtext,
  phoneText,
  subText,
  locationText,
}: contactInformationCardProp) => {
  return (
    <div className="h-[200px] bg-[#F7F3FE] shadow-white shadow-sm rounded-lg border border-black">
      <div className="flex flex-row items-center gap-2">
        <p className="font-bold text-lg p-2 pr-2 text-[#49209F]">{text}</p>
      </div>
      <div className="flex flex-col justify-between space-y-3">
        <div className="lg:p-2 sm:p-1 flex ">
          <p className="text-[#49209F] flex items-center p-1">
            <IoMdMail />
          </p>
          <p className="font-bold text-right sm:text-[0.8rem] lg:text-[0.9rem] ml-4 truncate">
            {mailtext}
          </p>
        </div>
        <div className="lg:p-2 sm:p-1 flex">
          <p className="text-[#49209F] flex items-center p-1">
            <FaPhone />{" "}
          </p>
          <p className="font-bold ml-4 sm:text-[0.8rem] lg:text-[0.9rem] truncate">
            {phoneText}
          </p>
        </div>
        <div className="lg:p-2 sm:p-1 flex">
          <p className="text-[#49209F] flex items-center p-1">
            <FaLocationDot />
          </p>
          <p className="font-bold text-[14px] ml-4  sm:text-[0.8rem] lg:text-[0.9rem] truncate">
            {locationText}
          </p>
        </div>
      </div>
    </div>
  );
};

interface AverageCardProp {
  text: string;
  subText: string | number;
  icon: any;
}

const AverageCard = ({ text, subText, icon }: AverageCardProp) => {
  return (
    <div className="bg-[white] flex flex-col shadow-white shadow-sm rounded-lg border border-black  h-[200px] items-center justify-around">
      <p className="text-2xl text-[#49209F]">
        {" "}
        <img src={icon} alt="imageIcon" />
      </p>
      <p className="text-2xl text-center"> {text} </p>
      <p className="lg:text-4xl sm:text-2xl font-bold text-[#49209F] text-center capitalize">
        {typeof subText === "number" ? `₦${subText}` : subText || 0}
      </p>
    </div>
  );
};

const ProductCard = ({
  headerText,
  items,
}: {
  headerText: string;
  items: any;
}) => {
  return (
    <div className="h-[240px] bg-[#FFFF] shadow-white shadow-sm flex flex-col border border-black rounded-lg">
      <div className="p-1">
        <p className="text-[#49209F] text-[20px] font-bold p-1">{headerText}</p>
      </div>
      <div className="flex flex-col justify-between">
        {items.map(
          (item: { image: string; text: string; icon: any }, index: number) => (
            <div key={index} className="flex  items-center p-1 ml-4">
              <div className="w-[40px] h-[40px] ml-1 mr-2 justify-center flex items-center bg-white shadow-white rounded shadow">
                <img alt="" src={item.image} className=" h-[30px] w-[30px]" />
              </div>

              <div className="flex justify-between items-center w-full">
                <p className="font-bold text-[16px]">{item.text}</p>
                <p className="text-2xl font-bold">{item.icon}</p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const RewardStation = ({
  headerText,
  items,
}: {
  headerText: string;
  items: any;
}) => {
  return (
    <div className="h-[240px] bg-[#F7F3FE] shadow-white shadow-sm flex flex-col border border-black  rounded-lg">
      <div className="p-1">
        <p className="text-[#49209F] text-[20px] font-bold p-1">{headerText}</p>
      </div>
      <div className="flex flex-col justify-between">
        {items.map(
          (
            item: {
              image: string;
              text: string;
              textValue: any;
              subText: string;
            },
            index: number
          ) => (
            <div key={index} className="flex  items-center p-1 ml-4">
              <div className="w-[40px] h-[40px] ml-1 mr-2 justify-center flex items-center bg-white shadow-white rounded shadow">
                <img alt="" src={item.image} className=" " />
              </div>

              <div className="flex justify-between items-center w-full">
                <div className="flex flex-col">
                  <p className="font-bold lg:text-[16px] sm:text-[14px]">
                    {item.text}
                  </p>
                  <p className="text-[12px]">{item.subText}</p>
                </div>
                <p className="lg:text-2xl sm:text-lg font-bold text-[#49209F]">
                  {item.textValue}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

interface HospitalActivityStreamProps {
  headerText?: string;
  recentActivity?: string;
  items: any[];
  setType?: React.Dispatch<React.SetStateAction<string>>;
  type?: string;
  status?: string;
}

const HospitalActivityStream = ({
  items,
  setType,
  type,
  status,
}: HospitalActivityStreamProps) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      `/hospitals/activities?id=${
        items[selectedItem]?.items[0].order_by
      }&type=${type}&page=1&status=${status ?? "exodus"}`
    );
  };

  if ((items as any).items?.length === 0) {
    return (
      <div className="flex flex-col h-[360px] bg-white shadow-white border border-black shadow-sm p-1 rounded-lg">
        <p className="text-[20px] text-[#49209F] text-center font-bold p-1">
          Activities
        </p>
        <div className="flex flex-col justify-between flex-grow">
          <p className="text-[16px] text-center font-bold mt-4">
            No Activities to show
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col h-[360px] bg-white shadow-white border border-black shadow-sm p-1 rounded-lg max-h-96">
        <p className="text-[20px] text-[#49209F] text-center font-bold p-1">
          Recent Activities
        </p>
        <div className="flex justify-evenly w-full mb-2">
          <div className="flex items-center w-full gap-1 mt-2">
            {(items as any).map(
              (
                product: {
                  value: string | null | undefined;
                  color: any;
                  label:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<
                        any,
                        string | React.JSXElementConstructor<any>
                      >
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                index: number
              ) => {
                const productName = (product.value ?? "").toLowerCase();
                const productColor = product.color;

                return (
                  <button
                    role="tab"
                    aria-selected={selectedItem === index ? true : false}
                    aria-controls={`tabpanel-${index + 1}`}
                    key={product.value}
                    onClick={() => {
                      setSelectedItem(index);
                      setType && setType(productName);
                    }}
                    style={
                      selectedItem === index
                        ? {
                            backgroundColor: productColor,
                            borderColor: productColor,
                          }
                        : {
                            backgroundColor: "transparent",
                            borderColor: "#49209F",
                          }
                    }
                    className={`flex items-center justify-center w-full p-1.5 rounded-sm ${
                      selectedItem === index
                        ? "text-white border"
                        : "bg-transparent text-[#49209F] border"
                    }`}
                  >
                    {product.label}
                  </button>
                );
              }
            )}
          </div>
          {/* <button
            className={`lg:text-[16px] sm:text-[20px] p-1 text-[#49209F] font-bold cursor-pointer ${
              selectedOption === "All" ? "underline" : ""
            }`}
            onClick={() => {
              handleOptionClick("All");
            }}
          >
            All
          </button>
          <button
            className={`lg:text-[16px] sm:text-[20px] p-1 text-[#49209F] font-bold cursor-pointer ${
              selectedOption === "Hospital activities" ? "underline" : ""
            }`}
            onClick={() => {
              handleOptionClick("Hospital activities");
            }}
          >
            Hospital activities
          </button> */}
          {/* <Button
            className={`lg:text-[16px] sm:text-[20px] p-1 text-[#49209F] font-bold cursor-pointer ${
              selectedOption === "Exodus activities" ? "underline" : ""
            }`}
            onClick={() => {
              handleOptionClick("Exodus activities");
            }}
          >
            Exodus activities
          </Button> */}
        </div>
        <div className="overflow-y-auto flex-grow">
          <table className="table-auto text-left w-full">
            <thead className="text-gray-600 font-medium border-b">
              <tr>
                <th className="py- pr-6">{items[selectedItem]?.title}</th>
                <th className="py- pr-6">Status</th>
                <th className="py- pr-6">Time</th>
                <th className="py- pr-6">Payment</th>
              </tr>
            </thead>
            {items[selectedItem]?.items?.length > 0 ? (
              <tbody className="text-gray-600 divide-y">
                {items[selectedItem]?.items?.map(
                  (
                    item: {
                      payment: string;
                      id: string | number;
                      urgency: string | number;
                      cylinder: string | number;
                      order_state: string | number;
                      created_at: string | number;
                      hospital_name: string;
                    },
                    idx: Key | null | undefined
                  ) => (
                    <tr key={idx}>
                      <td
                        className="whitespace-nowrap"
                        data-label={items[selectedItem]?.title}
                      >
                        <div className="flex flex-col">
                          <p>#{item.id}</p>
                          <p>
                            <span className="capitalize">{item.urgency}</span>{" "}
                            {item.cylinder}
                          </p>
                        </div>
                      </td>

                      <td
                        className="whitespace-nowrap text-indigo-600"
                        data-label="Status"
                      >
                        {item.order_state}
                      </td>
                      <td className="whitespace-nowrap" data-label="Time">
                        <span className={`rounded-full font-semibold text-xs`}>
                          {item.created_at}
                        </span>
                      </td>
                      <td className="whitespace-nowrap" data-label="Time">
                        <span className={`rounded-full font-semibold text-xs`}>
                          {item.payment}
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5} className="py-4 text-center">
                    No Activity
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>{" "}
        <div className="mt-3 w-full flex justify-end">
          {items[selectedItem]?.items?.length > 0 && (
            <button
              className="text-right underline mb-2 text-[#49209F] font-bold text-[17px]"
              onClick={handleNavigate}
            >
              View all activities {">>"}
            </button>
          )}

          {/* <div className="flex justify-between  mb-auto">
            <p
              className="rounded bg-white p-1  shadow-[white] shadow-md border border-[#49209F] cursor-pointer"
              // onClick={handleComplain}
            >
              All Complains
            </p>
            <p
              className="rounded bg-white p-1  shadow-white shadow-md border border-[white] cursor-pointer"
              // onClick={handleSIngleExodusOrder}
            >
              All Orders
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export {
  SmallCard,
  ContactInformationCard,
  AverageCard,
  ProductCard,
  RewardStation,
  HospitalActivityStream,
  SuccessRelation,
};
