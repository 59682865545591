import React, { useState } from "react";

import Select from "react-select";

const ReactSelect = ({
  options,
  isDisabled,
  onChange,
  className,
  placeholder,
  defaultValue,
  isLoading,
}: {
  options: { value: string; label: string }[];
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  isLoading?: boolean;
}) => {
  const [isClearable] = useState(false);

  const defaultOption = options.find((option) => option.value === defaultValue);
  return (
    <>
      <Select
        className={className}
        classNamePrefix="select"
        value={defaultOption ? defaultOption : undefined}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable
        name="options"
        options={options}
        placeholder={placeholder}
        onChange={(e) =>
          onChange && onChange((e as { value: string }).value || "")
        }
      />
    </>
  );
};

export default ReactSelect;
