import React from "react";
import { Outlet } from "react-router-dom";

import "./scss/app.css";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { CfLog } from "@causalfoundry/js-sdk";

const firebaseConfig = {
  apiKey: "AIzaSyCvmoLNO3zHaP2O3qubS9PDfBVCoUsfnaI",
  authDomain: "onehp-44be5.firebaseapp.com",
  projectId: "onehp-44be5",
  storageBucket: "onehp-44be5.appspot.com",
  messagingSenderId: "487943454778",
  appId: "1:487943454778:web:5ca141494d4594edadf503",
  measurementId: "G-7ZVJBQ5DZ7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Check for service worker support
if ("serviceWorker" in navigator) {
  // Register service worker
  navigator.serviceWorker
    .register("service.js")
    .then((registration) => {
      // Get token
      getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey:
          "BNcktNenKvALMRa3XJFFvEpu5j66W-rgaeM8TQ9acZN34YWl-b0eoGw1E-jd4nT11ZKHxlOwnWLdEMg0n573RDk",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server along with localStorage user_id and update the UI if necessary
            const userId = JSON.parse(localStorage.getItem("oh_toks") ?? "")
              ?.value.id;
            if (userId) {
              sendTokenToServer(currentToken, userId);
            }
          } else {
            // Show permission request UI
            alert(
              "No notification token available. Allow permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    })
    .catch((error) => {
      console.error("Service worker registration failed:", error);
    });
} else {
  // Service workers are not supported in this browser
  console.log("Service workers are not supported in this browser.");
}

function sendTokenToServer(currentToken: string, userId: string) {
  const url = "https://one.lifebank.ng/api/v1/authentication/save-sub_new";
  // Data to be sent in the POST request
  const data = {
    token: currentToken,
    userId: userId,
  };
  // console.log(data);
  // Configuration for the fetch request
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  // Send the POST request
  fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      // console.log("Token and UserID sent successfully:", data);
    })
    .catch((error) => {
      console.error("Error sending token and userId to server:", error);
    });
}

(() => {
  try {
    new CfLog(
      "cfkeydoO4BYeRGN3SNxHQlJpxslAdN3SNNhkyLr8EdtDdWMRF2NQur8bYXVQeLHD",
      {
        activateNudgeMechanism: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
})();

const App: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
