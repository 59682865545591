import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import utils from "../../utils/utilities";
import { QuotationState } from "../../ts-utils/interfaces";

const initialState = {
  id: utils.makeid(),
  data: [],
  hospital_id: "",
  paymentMethod: "",
  quotationType: "",
} satisfies QuotationState as QuotationState;

const quotationSlice = createSlice({
  name: "quotations",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    addHospitalId: (state, action: PayloadAction<string>) => {
      state.hospital_id = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
    },
    setQuotationType: (state, action: PayloadAction<string>) => {
      state.quotationType = action.payload;
    },
  },
});

export const { setData, addHospitalId, setPaymentMethod, setQuotationType } =
  quotationSlice.actions;

export default quotationSlice.reducer;
