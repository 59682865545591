import React, { useState } from "react";

import AddProduct from "../../../components/forms/addProduct";
import SearchQuery from "../../../components/forms/searchQuery";
import ProductListTable from "../../../components/tables/productListTable";

import Button from "../../../components/common/button";
import Header from "../../../components/blocks/header";

import { getPrivileges } from "../../../services/authService";
import { useGetProductList } from "../../../hooks/useProductList";

const Products = () => {
  const { productList, isPending } = useGetProductList();
  const [search, setSearch] = useState("");
  const [addProduct, setAddProduct] = useState(false);

  const handleAddProduct = () => setAddProduct((prev) => !prev);

  const privileges = getPrivileges();

  return (
    <React.Fragment>
      <Header title={"Products - " + productList.length} />

      {privileges.addFacility && addProduct && (
        <AddProduct
          productList={productList}
          handleClose={handleAddProduct}
          loading={isPending}
        />
      )}

      <div className="hospitals-container">
        <div className="hospitals-container-top">
          <SearchQuery
            name="search"
            placeholder="Search"
            value={search}
            onChange={(e: {
              currentTarget: { value: React.SetStateAction<string> };
            }) => setSearch(e.currentTarget.value)}
          />

          <div className="right-options">
            {privileges.addProductType && (
              <Button
                icon="plus"
                label="Add Product"
                onClick={handleAddProduct}
              />
            )}
          </div>
        </div>
        <div className="hospitals-container-bottom table-responsive">
          <ProductListTable
            search={search}
            productList={productList}
            loading={isPending}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Products;
