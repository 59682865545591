import { SetStateAction, useState } from "react";

import Button from "../common/button";
import Modal from "../common/modal";
import ProgressLoader from "../common/progressLoader";

import prospectService from "../../services/prospects.service";
import toastService from "../../services/toastService";

import { useGlobalContext } from "../../contexts/globalContext";
import ReactSelect from "../common/ReactSelect";
import { useGetCadreList } from "../../hooks/agent/useAgent";

const ValidateProspect = ({
  selectedProspect,
  handleClose,
  handleExit,
  blood,
  oxygen,
}: {
  selectedProspect: string | null;
  handleClose: () => void;
  handleExit?: () => void;
  blood: string;
  oxygen: string;
}) => {
  const { cadreList } = useGetCadreList();
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(1);
  const [requestChange, setRequestChange] = useState("");

  const { user } = useGlobalContext();

  const [cadre, setCadre] = useState({
    blood: "",
    oxygen: "",
  });

  const validate = async () => {
    const apiData = {
      user_id: user?.id,
      hospital_id: selectedProspect,
      blood: cadre.blood,
      oxygen: cadre.oxygen,
      reason: requestChange,
    };

    const res = await prospectService.validateHospital(apiData, handleProgress);
    if (!res.data.ok) throw new Error(res.data.description);

    handleClose();
    handleExit && handleExit();
  };

  const handleProgress = (progress: SetStateAction<number>) => {
    setProgress(progress);
  };

  const handleValidation = () => {
    toastService.promise(validate(), {
      loading: "Validating...",
      success: () => "Validated Successfully",
      error: (e: { message: string }) => e.message,
    });
  };

  return (
    <Modal handleClose={handleClose}>
      <ProgressLoader progress={progress} />
      {page === 1 && (
        <>
          <div className="w-100 text-center">
            <i className="fa fa-star confirm-validate-icon"></i>
          </div>

          <p className="confirm-validate-text">This are your current cadres:</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <span style={{ fontWeight: "600" }}>Blood:</span>{" "}
              <span
                style={{
                  border: "2px solid #b80304",
                  borderRadius: "3px",
                  padding: "5px",
                }}
              >
                {blood}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Oxygen:</span>{" "}
              <span
                style={{
                  border: "2px solid #0679f8",
                  borderRadius: "3px",
                  padding: "5px",
                }}
              >
                {oxygen}
              </span>
            </p>
          </div>
          <h3 className="modal-title confirm-validate-title">
            Are you fine with these cadres?
          </h3>

          <div className="modal-center-buttons">
            <Button label="Yes" onClick={() => setPage(2)} />

            <Button
              label="No"
              className="app-btn close-btn"
              // onClick={handleClose}
              onClick={() => setPage(3)}
            />
          </div>
        </>
      )}

      {page === 2 && (
        <>
          <div className="w-100 text-center">
            <i className="fa fa-exclamation-circle confirm-validate-icon"></i>
          </div>

          <h3 className="modal-title confirm-validate-title">Are You Sure?</h3>

          <p className="confirm-validate-text">
            You won't be able to reverse this
          </p>

          <div className="modal-center-buttons">
            <Button label="Yes" onClick={handleValidation} />

            <Button
              label="No"
              className="app-btn close-btn"
              onClick={() => setPage(3)}
            />
          </div>
        </>
      )}

      {page === 3 && (
        <div
          className="w-100 text-center"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            justifySelf: "center",
            marginTop: "2rem",
          }}
        >
          <div>
            <div>
              <ReactSelect
                placeholder="Select Blood Cadre"
                // defaultValue={blood}
                options={
                  Array.isArray(cadreList)
                    ? cadreList?.map((cadre: { id: string; name: string }) => ({
                        value: cadre.name,
                        label: cadre.name,
                      }))
                    : []
                }
                onChange={(value) =>
                  setCadre({
                    ...cadre,
                    blood: value,
                  })
                }
              />
              <ReactSelect
                placeholder="Select Oxygen Cadre"
                // defaultValue={oxygen}
                options={
                  Array.isArray(cadreList)
                    ? cadreList?.map((cadre: { id: string; name: string }) => ({
                        value: cadre.name,
                        label: cadre.name,
                      }))
                    : []
                }
                onChange={(value) => {
                  setCadre({
                    ...cadre,
                    oxygen: value,
                  });
                }}
              />
            </div>
            {/* <Select
              placeholder="Select Blood Cadre"
              options={cadreTypes}
              value={bloodCadre}
              onChange={(e: {
                currentTarget: { value: SetStateAction<string> };
              }) => setBloodCadre(e.currentTarget.value)}
            />
            <Select
              placeholder="Select Oxygen Cadre"
              options={cadreTypes}
              value={oxygenCadre}
              onChange={(e: {
                currentTarget: { value: SetStateAction<string> };
              }) => setOxygenCadre(e.currentTarget.value)}
            /> */}
          </div>
          <textarea
            id="summary"
            name="requestCadre"
            onChange={(e) => setRequestChange(e.target.value)}
            placeholder="Reason for changing cadre"
            rows={5}
            cols={50}
            className="form-field-input-text"
          ></textarea>

          {/* <div
              className="status primary"
              onClick={() => setShowNewCadre(!showNewCadre)}
            >
              Submit
            </div> */}

          <Button label="Submit and Validate" onClick={() => setPage(2)} />
        </div>
      )}
    </Modal>
  );
};

export default ValidateProspect;
