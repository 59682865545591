import { useState } from "react";
import { useLocation } from "react-router-dom";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import { useNavigate } from "react-router";
import { AiOutlineContacts } from "react-icons/ai";
import { MdOutlineHome } from "react-icons/md";
import LogVisit from "../../../../components/LogHospitalVisit";
import { GoDash } from "react-icons/go";
type State = {
  state: {
    id: string;
    name: string;
    address: string;
    lastOrder: string;
    nerveOrder: number;
    averageMonthlySpent: number;
    ref_id: string;
  };
};

const ContactHospital = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { state } = location;

  const prop = (state as State)?.state || state;
  const { id, name, address, lastOrder, nerveOrder, ref_id } = prop;

  const navigate = useNavigate();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCall = () => {
    navigate(`/hospitals/log/call`, {
      state: {
        ref_id: ref_id,
        mode: "call",
        id: id,
        name: name,
      },
    });
  };

  const formatDate = (item: string) => {
    const dateObj = new Date(item);

    // Array of month names
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    var day = dateObj.getDate();
    var monthIndex = dateObj.getMonth();
    var year = dateObj.getFullYear();

    // Format the date
    var formattedDate = day + " " + monthNames[monthIndex] + " " + year;

    return formattedDate;
  };

  // const nerveItem = singleExodus?.ordercounts?.find(
  //   (item: { channel: string }) => item.channel === "Nerve"
  // );

  // const nerveCount = nerveItem ? Number(nerveItem.total_sum) : 0;
  return (
    <div className=" w-full">
      <ExodusHeader
        Text={"Log A Contact"}
        subText={`| Hospital > Log a contact  -> ${name}`}
      />
      <div className="w-11/12 mx-auto mb-4">
        <div className="w-10/12 flex flex-row m-4">
          <p className="text-8xl text-[#49209F] mt-4">
            <GoDash />
          </p>
          <div className="flex-col flex justify-center">
            <p className="font-bold pt-2 pb-2 mt-4 text-2xl capitalize">
              Log a contact to {name}
            </p>
          </div>
        </div>

        <div className="bg-[#FFFFFF] w-11/12 mx-auto mt-20 h-[400px] shadow-white shadow-sm rounded relative">
          <div className="top-[-50px] h-[100px] p-4 justify-between left-0 right-0 w-11/12 mx-auto flex bg-[#F5F1FE] shadow-white shadow-sm rounded absolute items-center">
            <p className="text-[16px] font-bold text-[#4D26A0] w-4/12">
              {name}
            </p>
            <p className="text-[#7d60c3] mx-auto w-1/12 text-4xl">|</p>
            <p className="text-[16px] w-6/12"> {address ?? "N/A"} </p>
          </div>
          <div className="flex mx-auto pt-16 mt-8 justify-between items-center w-11/12">
            <div className="text-[#4D26A0] flex font-bold sm:text-[15px] items-center lg:text-[16px]">
              {" "}
              <p>Nerve Order : </p>{" "}
              <span className="ml-1 sm:text-[15px] lg:text-[16px] text-black font-bold">
                {nerveOrder}
                {/* {user?.country === "Nigeria"
                  ? "₦"
                  : " " || user?.country === "Kenya"
                  ? "KSh"
                  : " " || user?.country === "Ethopia"
                  ? "ETB"
                  : " "}
                {formatNumberWithCommas(+MonthlySpend)} */}
              </span>
            </div>
            {/* <div className="text-[#4D26A0] flex font-bold lg:text-[16px] items-center sm:text-[15px]">
              {" "}
              <p>Active Average Spend : </p>{" "}
              <span className="sm:text-[15px] lg:text-[16px] text-black font-bold">
                {user?.country === "Nigeria"
                  ? "₦"
                  : " " || user?.country === "Kenya"
                  ? "KSh"
                  : " " || user?.country === "Ethopia"
                  ? "ETB"
                  : " "}
                {formatNumberWithCommas(scheduleAmount ?? 0)}
              </span>{" "}
            </div> */}
            <div className="text-[#4D26A0] flex font-bold lg:text-[16px] items-center sm:text-[15px]">
              {" "}
              <p>Date of Last Order : </p>{" "}
              <span className="ml-1 sm:text-[15px] lg:text-[16px] text-black font-bold">
                {lastOrder ? formatDate(lastOrder) : "N/A"}
              </span>
            </div>
          </div>
          <hr className="border-t border-black my-4 " />
          <div className="w-9/12 flex justify-between mx-auto gap-4 pt-4">
            <div
              className="shadow-white shadow-sm rounded w-5/12 flex flex-col items-center h-[200px] justify-around cursor-pointer bg-[#4D26A0] text-[white]"
              onClick={handleCall}
            >
              <span className="text-[#4D26A0]">
                <AiOutlineContacts size={70} className="text-white" />
              </span>
              <p className="text-2xl">Log a call</p>
            </div>
            <div
              className="shadow-white shadow-sm rounded w-5/12 flex flex-col items-center h-[200px] justify-around cursor-pointer bg-[#4D26A0] text-[white]"
              onClick={handleOpen}
            >
              <span className="text-[#4D26A0]">
                <MdOutlineHome size={70} className="text-[#fff]" />
              </span>
              <p className="text-2xl text-[#fff]">Log a visit</p>
            </div>
            {isOpen && (
              <LogVisit
                setIsOpen={setIsOpen}
                name={name}
                id={id}
                ref_id={ref_id}
                address={address}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHospital;
