import httpService from "../services/httpService";

export const requestExpense = (data: any) => {
  const url = "logistics/expense/log";

  return httpService.post(url, data);
};

export const logisticsExpenses = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  proposed_date: string;
}) => {
  const queryParams = new URLSearchParams();
  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("userid", filters.user_id);
  }
  if (
    filters.proposed_date !== null &&
    filters.proposed_date !== undefined &&
    filters.proposed_date !== ""
  ) {
    queryParams.append("date_incured", filters.proposed_date);
  }
  return httpService.get(`/logistics/expense/logs?${queryParams.toString()}`);
};

export const logisticEstimatedCost = (userId: string) => {
  return httpService.get(`/logistics/expense/estimated_cost?userid=${userId}`);
};

export const settleExpense = (data: { id: string; approverid: string }) => {
  return httpService.put(`/logistics/expense/settle/${data.id}`, {
    approverid: data.approverid,
  });
};

export const getAllDisbursement = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
  proposed_date: string;
}) => {
  const queryParams = new URLSearchParams();
  if (
    filters.page !== null &&
    filters.page !== undefined &&
    filters.page !== 0 &&
    filters.page !== ""
  ) {
    queryParams.append("page", filters.page.toString());
  }
  if (
    filters.limit !== null &&
    filters.limit !== undefined &&
    filters.limit !== 0
  ) {
    queryParams.append("limit", filters.limit.toString());
  }

  if (
    filters.name !== null &&
    filters.name !== undefined &&
    filters.name !== ""
  ) {
    queryParams.append("name", filters.name.toLowerCase());
  }
  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("userid", filters.user_id);
  }

  if (
    filters.proposed_date !== null &&
    filters.proposed_date !== undefined &&
    filters.proposed_date !== ""
  ) {
    queryParams.append("proposed_date", filters.proposed_date);
  }
  return httpService.get(
    `/logistics/disbursement/logs?${queryParams.toString()}`
  );
};
export const getTotalAmountDisbursed = (filters: {
  user_id: string;
  proposed_date: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("userid", filters.user_id);
  }

  if (
    filters.proposed_date !== null &&
    filters.proposed_date !== undefined &&
    filters.proposed_date !== ""
  ) {
    queryParams.append("proposed_date", filters.proposed_date);
  }
  return httpService.get(
    `/logistics/disbursement/logs/total?${queryParams.toString()}`
  );
};
export const getTotalAmountSpent = (filters: {
  user_id: string;
  proposed_date: string;
}) => {
  const queryParams = new URLSearchParams();

  if (
    filters.user_id !== null &&
    filters.user_id !== undefined &&
    filters.user_id !== ""
  ) {
    queryParams.append("userid", filters.user_id);
  }

  if (
    filters.proposed_date !== null &&
    filters.proposed_date !== undefined &&
    filters.proposed_date !== ""
  ) {
    queryParams.append("date_incured", filters.proposed_date);
  }
  return httpService.get(
    `/logistics/expense/estimated_cost?${queryParams.toString()}`
  );
};

export const logDisbursement = (data: any) => {
  const url = "logistics/disbursement/log";

  return httpService.post(url, data);
};

const logisticService = {
  requestExpense,
  logisticsExpenses,
  logisticEstimatedCost,
  settleExpense,
  getAllDisbursement,
  getTotalAmountDisbursed,
  getTotalAmountSpent,
  logDisbursement,
};

export default logisticService;
