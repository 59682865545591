import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import prospectService from "../../services/prospects.service";
import toastService from "../../services/toastService";

export const useNerveMessage = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["nerve-message", filters],
    queryFn: async () => {
      const response = await prospectService.nerveUsageMessages(filters);
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

  const messages: any = data?.data || [];
  const paginate: any = data?.meta || {};

  return { isError, isSuccess, messages, isPending, paginate };
};

export const useNerveClaims = (city: string) => {
  const { isError, isSuccess, data, isPending, isFetching } = useQuery({
    queryKey: ["nerve-claims", city],
    queryFn: async () => {
      const response = await prospectService.getNerveClaims(city);
      return response.data;
    },
    placeholderData: keepPreviousData,
    refetchInterval: 15000,
    refetchIntervalInBackground: true,
  });

  const claims: any = data || [];
  const paginate: any = data?.meta || {};

  return { isError, isSuccess, claims, isPending, isFetching, paginate };
};

export const useConfirmClaim = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: { id: string; user_id: string }) => {
      const response = await prospectService.confirmClaim(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success(data?.data?.message);
      queryClient.refetchQueries({ queryKey: ["nerve-claims"] });
    },
    onError: (error: { response: { data: { message: string } } }) => {
      toastService.error(
        error.response.data.message || "Failed to update pipeline"
      );
    },
  });

  return { mutate, isPending, error };
};
