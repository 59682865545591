import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import marketingService from "../../services/marketing.service";
import { AxiosResponse } from "axios";
//
import toastService from "../../services/toastService";

interface ResponseData {
  [x: string]: any;
  description: string;
  data: any;
  meta: any;
}

export const useGetTools = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["tools"],
    queryFn: async () => {
      const response =
        (await marketingService.getAllTools()) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const tools: any = (data as AxiosResponse<ResponseData>)?.data?.data || [];

  const baseUrl = (data as AxiosResponse<ResponseData>)?.data?.baseUrl;
  if (isError) {
    // toastService.error((error as AxiosError)?.message);
    toastService.error("No tools found");
  }

  return { isError, isSuccess, tools, baseUrl, isPending, error };
};

export const useCreateTool = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.createTool(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Tool created successfully!");
      queryClient.refetchQueries({
        queryKey: ["tools"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to create tool");
    },
  });

  return { mutate, isPending, error };
};

// update events
export const useUpdateTool = (id: string | number) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateUpdate,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.editTool(data, id as string);
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Tool updated successfully!");

      queryClient.refetchQueries({
        queryKey: ["tools"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to update tool");
    },
  });

  return { mutateUpdate, isPending, error };
};
