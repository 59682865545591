import React, { useState } from "react";
import ItemsCard from "./ItemsCard";
import {
  addToCart,
  clearCart,
  removeFromCart,
  updateItemQuantity,
} from "../../../../redux/slices/Cart";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../@/components/ui/button";
import TransitionsModal from "../../../../components/modals/mod/CustomModal";
import { useSendQuotations } from "../../../../hooks/purchasingOrder/usePurchaseOrder";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import PaymentMethods from "./PaymentMethod";
import toastService from "../../../../services/toastService";

const PurchasingCart = () => {
  const navigate = useNavigate();
  const { mutateQuotation, isPending } = useSendQuotations();
  const cart =
    useSelector((state: { cart: any }) => state?.cart) ?? ([] as any[]);

  const hospitalId = useSelector(
    (state: { cart: any }) => state.cart.hospital_id
  );

  const [paymentType, setPaymentType] = useState("");
  const [isContinue, setIsContinue] = useState(false);

  const [quantities, setQuantities] = useState<{ [key: string]: number }>(() =>
    cart.items.reduce(
      (acc: { [key: string]: number }, item: { id: string; qty: number }) => ({
        ...acc,
        [item.id]: item.qty || 1,
      }),
      {}
    )
  );
  const dispatch = useDispatch();

  const isItemInCart = (item: any) => {
    return cart.items.some((cartItem: { id: any }) => cartItem.id === item.id);
  };

  const handleClick = (prod: any) => {
    const isItemInCart = cart?.items?.some(
      (cartItem: { id: any }) => cartItem.id === prod.id
    );

    // Get the current quantity or default to 1 if not specified
    const currentQuantity = quantities[prod.id] || 1;

    if (isItemInCart) {
      dispatch(removeFromCart(prod.id));
    } else {
      dispatch(
        addToCart({
          ...prod,
          qty: currentQuantity, // Ensure quantity is added to the item
        })
      );

      // Update the item quantity in the store
      dispatch(updateItemQuantity({ id: prod.id, qty: currentQuantity }));
    }
  };

  const handleQuantityChange = (
    e: { target: { value: string } },
    id: string
  ) => {
    const newQuantity = e.target.value;

    // Allow the input to be empty
    if (newQuantity === "") {
      setQuantities((prev) => ({ ...prev, [id]: 0 }));
      return;
    }

    const parsedQuantity = Number(newQuantity);

    // Ensure the parsed quantity is a valid number and greater than 0
    if (!isNaN(parsedQuantity) && parsedQuantity >= 1) {
      setQuantities((prev) => ({ ...prev, [id]: parsedQuantity }));
      dispatch(updateItemQuantity({ id, qty: parsedQuantity }));
    }
  };

  const handleIncrement = (id: string) => {
    setQuantities((prev) => {
      const currentQuantity = prev[id] || 0;
      const newQuantity = currentQuantity + 1;

      // Ensure newQuantity is a valid number
      if (!isNaN(newQuantity)) {
        dispatch(updateItemQuantity({ id, qty: newQuantity }));
        return { ...prev, [id]: newQuantity };
      }

      return prev;
    });
  };

  const handleDecrement = (id: string) => {
    setQuantities((prev) => {
      const currentQuantity = prev[id] || 0;
      const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1;

      // Ensure newQuantity is a valid number
      if (!isNaN(newQuantity)) {
        dispatch(updateItemQuantity({ id, qty: newQuantity }));
        return { ...prev, [id]: newQuantity };
      }

      return prev;
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const [period, setPeriod] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const handleSubmitQuotation = (period: string) => {
    setPeriod(period);
    const payload = {
      order_time: period,
      hospital_id: hospitalId,
      items: cart.items.map(
        (item: { id: any; name: any; qty: any; quantity: any }) => ({
          item_id: item.id,
          name: item.name,
          qty: item.qty || item.quantity,
        })
      ),
      channel_type: "web",
      payment_method: paymentType,
    };

    mutateQuotation(payload, {
      onSuccess: () => {
        handleCloseModal();
        dispatch(clearCart());
        setOpenAlert(true);
      },
    });
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
    navigate("/purchasing-orders");
  };

  function handlePaymentType(paymentType: any): void {
    setPaymentType(paymentType);
  }

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="purchasing-order-header">
        <h2>Purchasing Cart</h2>
      </div>
      <ItemsCard
        items={cart.items}
        selectItem={handleClick}
        isItemInCart={isItemInCart}
        handleQuantityChange={handleQuantityChange}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
        quantities={quantities}
      />
      {cart.items.length > 0 ? (
        <div className="mt-12 items-start justify-between sm:flex w-full">
          <Button
            onClick={() => setIsOpen(true)}
            className="inline-flex items-center justify-center gap-1  font-medium text-sm text-center text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg sm:mt-0 w-full"
          >
            Send for Quotation
          </Button>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center h-96">
            <h2 className="text-center text-2xl font-semibold text-gray-600">
              Your cart is empty
            </h2>
          </div>
        </>
      )}

      {/* Success alert */}
      <TransitionsModal
        open={openAlert}
        setOpen={setOpenAlert}
        handleCloseModal={handleCloseAlert}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
        footer={
          <div className="flex items-center justify-center w-1/2 gap-3">
            <Button
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-210 w-full active:translate-y-2 active:scale-95"
              type="button"
              onClick={handleCloseAlert}
            >
              Close
            </Button>
          </div>
        }
      >
        <div className="flex flex-col justify-center items-center space-y-8">
          <h2 className="font-semibold lg:text-3xl mb-8">
            Quotation Request Received!
          </h2>
          <div className="">
            <FaCheckCircle className="w-20 h-20 text-green-400 bg-white" />
          </div>
          <p className="text-base text-gray-600 text-center">
            Dear customer, Your Quotation request has been received, We will
            revert with your Quotation {period}
          </p>
        </div>
      </TransitionsModal>

      <TransitionsModal
        open={isOpen}
        setOpen={setIsOpen}
        handleCloseModal={handleCloseModal}
        title={"Get Quotation"}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "100%",
          bgcolor: "white",
          boxShadow: 24,
          borderRadius: "10px",
        }}
      >
        {!isContinue && (
          <>
            <PaymentMethods
              handlePaymentType={handlePaymentType}
              paymentType={paymentType}
            />

            <div className="w-full flex items-center justify-center mt-3">
              <button
                onClick={() => {
                  if (!paymentType) {
                    toastService.error("Please select a payment method");
                    return;
                  }
                  setIsContinue(true);
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-400 ease-in-out duration-300 delay-150"
              >
                Continue
              </button>
            </div>
          </>
        )}
        {isContinue && (
          <div className="lg:h-96">
            <h2 className="font-semibold lg:text-3xl mb-8">
              When do you need your quotation?
            </h2>

            <div className="grid lg:grid-cols-3 w-full gap-4">
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 24 hours")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">24 </span> In 24 hours
              </button>
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 3 days")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">3</span> In Three days
              </button>
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 7 days")}
                className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg">7</span> In Seven days
              </button>
              <div className="hidden lg:block"></div>
              <button
                disabled={isPending}
                onClick={() => handleSubmitQuotation("In 10 days")}
                className="lg:h-32 bg-smokeWhite border flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
              >
                <span className="font-semibold text-lg  ">10</span>{" "}
                <span className="flex group-hover:text-white">
                  {" "}
                  In Ten days
                </span>
              </button>
              <div></div>
            </div>
          </div>
        )}
      </TransitionsModal>
    </div>
  );
};

export default PurchasingCart;
