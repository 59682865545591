import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import marketingService from "../../services/marketing.service";
import { AxiosResponse } from "axios";
//
import toastService from "../../services/toastService";

interface ResponseData {
  description: string;
  data: any;
  meta: any;
}

export const useGetStory = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["story"],
    queryFn: async () => {
      const response =
        (await marketingService.getAllStories()) as AxiosResponse<ResponseData>;
      return response?.data;
    },
  });

  const story: any = data?.data.reverse() || [];

  if (isError) {
    // toastService.error((error as AxiosError)?.message);
    toastService.error("No story found");
  }

  return { isError, isSuccess, story, isPending, error };
};

// create events
export const useCreateStory = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.createStory(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Story created successfully!");
      queryClient.refetchQueries({
        queryKey: ["story"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to create story");
    },
  });

  return { mutate, isPending, error };
};

// update events
export const useUpdateStory = (
  id: string | number,
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateUpdate,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.editStory(
        id,
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Story updated successfully!");
      queryClient.refetchQueries({
        queryKey: ["story"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to update story");
    },
  });

  return { mutateUpdate, isPending, error };
};
