import React from "react";

import cardPayment from "../../../../images/CardPayment.png";
import cash from "../../../../images/Cash.png";
import bankbuilding from "../../../../images/bankbuilding.png";
import { useGlobalContext } from "../../../../contexts/globalContext";

import countries from "../../../../utils/countries.json";
const PaymentMethods = ({
  handlePaymentType,
  paymentType,
}: {
  handlePaymentType: (paymentType: string) => void;
  paymentType: string;
}) => {
  const { user } = useGlobalContext();

  const getPaymentMethodsByCountry = (country: any) => {
    const methods = [
      {
        value: "3",
        label: "POS",
        icon: cardPayment,
        subLabel: "Pay via a Point of Sale Machine",
        countries: [countries.ethiopia, countries.kenya],
      },
      {
        value: "4",
        label: "Cheque",
        subLabel: "Pay via a cashier's check",
        icon: bankbuilding,
        countries: [countries.ethiopia, countries.kenya],
        active: paymentType === "Cheque" ? "bg-bloodRed" : "",
      },
      {
        value: "5",
        label: "MPESA",
        icon: "tablet-cellphone",
        subLabel: "Pay via MPESA",
        countries: [countries.kenya],
      },
      {
        value: "6",
        label: "Pay On Delivery",
        subLabel: "Pay in cash upon receipt of products",
        icon: cash,
        countries: [countries.nigeria, countries.ethiopia, countries.kenya],
        active: paymentType === "Pay On Delivery" ? "bg-oxygenBlue" : "",
      },
    ];

    return methods.filter((m) => m.countries.indexOf(country) !== -1);
  };

  let payment = [];

  if (user?.country.toLowerCase() === countries.nigeria.value) {
    payment = getPaymentMethodsByCountry(countries.nigeria);
  } else if (user?.country.toLowerCase() === countries.ethiopia.value) {
    payment = getPaymentMethodsByCountry(countries.ethiopia);
  } else {
    payment = getPaymentMethodsByCountry(countries.kenya);
  }

  function getBackgroundColorClass(paymentType: string) {
    switch (paymentType) {
      case "Pay Now":
        return "bg-consumableGreen";
      case "Cheque":
        return "bg-bloodRed";
      case "Pay on Delivery":
        return "bg-oxygenBlue";
      default:
        return "bg-gray-500";
    }
  }

  return (
    <>
      <div className="w-full md:px-10">
        <div
          className={`flex justify-start bg-white shadow-md  border-2 rounded-xl p-3 w-full`}
        >
          <div
            className={`bg-red-400 p-2 rounded-xl flex flex-col justify-center items-center`}
          >
            <img src={cardPayment} alt="icon" className="object-contain" />
          </div>

          <div className="flex flex-col items-start justify-center ml-4">
            <h1 className="text-xl font-bold">SELECT PAYMENT METHOD</h1>
            <h1 className="text-sm font-medium">
              Please select your preferred payment method
            </h1>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 md:px-10 mt-10 w-full">
        {payment ? (
          payment.map((method) => (
            <PaymentCard
              key={method.value}
              iconBackgroundColor={getBackgroundColorClass(method.value)}
              img={method.icon}
              title={method.label}
              subtitle={method.subLabel}
              onClick={() => handlePaymentType(method.label)}
              active={method.active}
            />
          ))
        ) : (
          <div className="flex justify-center items-center w-full h-96">
            <h1 className="text-2xl font-bold text-gray-500">
              No payment methods available
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentMethods;

interface PaymentCardProps {
  iconBackgroundColor: string;
  img: string;
  title: string;
  subtitle: string;
  onClick?: () => void;
  active?: boolean | string;
}

const PaymentCard = ({
  iconBackgroundColor,
  img,
  title,
  subtitle,
  onClick,
  active,
}: PaymentCardProps) => {
  return (
    <div
      role="button"
      className={
        active
          ? `${active} flex justify-around items-center w-full text-white p-2 md:px-5 px-3 rounded-full shadow-md bg-blue-400`
          : " flex justify-around items-center w-full bg-iconWhiteBg p-2 md:px-5 px-3 rounded-full shadow-md hover:bg-blue-100"
      }
      onClick={onClick}
    >
      <div
        className={`flex flex-col justify-center items-center ${iconBackgroundColor} rounded-md p-2`}
      >
        <img src={img} alt="" className="w-5 h-5 object-cover" />
      </div>
      <div className="flex flex-col justify-center items-center w-full ">
        <div className="text-xl font-bold">{title}</div>
        <div className="text-lg">{subtitle}</div>
      </div>
    </div>
  );
};
