import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../../../components/blocks/header";
import { useGlobalContext } from "../../../contexts/globalContext";
import { SyncLoader } from "react-spinners";
import { getPrivileges } from "../../../services/authService";
import { useLocation, useNavigate } from "react-router-dom";
import ProductTable, { ProductTableColumn } from "../../../components/common/ProductTable";
import applyFilters from "../../../helpers/urlState";
import { useGetCancelledOrders } from "../../../hooks/agent/useAgent";

const MessageLeadPage = () => {
  const permission = getPrivileges();
  const { user } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const pathname = location.pathname.replace("/", "");

  const [currentPage, setCurrentPage] = useState(1);

  const getUserId = useCallback(
    (user: any) => {
      if (!permission.createAgent) {
        return user;
      }
      return "";
    },
    [permission.createAgent]
  );
  const pageSize: number = 10;

  const filters = {
    user_id: getUserId(user?.id) as string,
    limit: pageSize,
    page: currentPage,
    name: "",
  };
  const { cancelledOrders, isPending, paginate } =
    useGetCancelledOrders(filters);

  function renderCell(order: any, column: ProductTableColumn) {
    switch (column.accessor) {
      case "hospital_name":
        return <p className="font-bold">{order[column.accessor]}</p>;
      case "tym":
        const date = new Date(order[column.accessor] * 1000);

        return (
          <span>
            {date.getDate().toString().padStart(2, "0")}-
            {(date.getMonth() + 1).toString().padStart(2, "0")}-
            {date.getFullYear()}
          </span>
        );

      default:
        return <span>{order[column.accessor] ?? "N/A"}</span>;
    }
  }
  const columns: ProductTableColumn[] = [
    { header: "Hospital", accessor: "hospital_name" },
    { header: "Date", accessor: "tym" },
    { header: "Title", accessor: "title" },
    { header: "Reason", accessor: "reason" },
    { header: "Quantity", accessor: "qty" },
  ];

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname]
  );
  return (
    <React.Fragment>
      <Header title="Your Hospital that Cancelled This Month" />
      <div className="">
        {isPending ? (
          <div className="fixed top-0 bottom-0 left-0 right-0 flex mx-auto items-center justify-center h-dvh z-30">
            <div className="bg-gray-700 absolute inset-0 opacity-50"></div>
            <SyncLoader
              color="#3B82F6"
              loading={isPending}
              className="relative"
              size={30}
            />
          </div>
        ) : (
          <>
            <div className="mx-auto px-4 md:px-8 mb-5">
              <ProductTable
                data={cancelledOrders}
                columns={columns}
                renderCell={renderCell}
                totalPages={paginate?.totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                noDataMessage={
                  "You currently don't have any pending cancelled orders"
                }
              />
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default MessageLeadPage;
