import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import utils from "../../utils/utilities";

const initialState = {
  id: utils.makeid(),
  data: {},
  preExodusCount: 0,
};

const pipelineSlice = createSlice({
  name: "pipeline",
  initialState,
  reducers: {
    setPipeline: (
      state,
      action: PayloadAction<{
        color: string;
        data: string;
        icon: string;
        sortKey: string;
        tag: string;
        title: string;
      }>
    ) => {
      state.data = action.payload;
    },
    setPreExodusCount: (state, action) => {
      state.preExodusCount = action.payload;
    },
  },
});

export const { setPipeline, setPreExodusCount } = pipelineSlice.actions;

export default pipelineSlice.reducer;
