import { AxiosProgressEvent } from "axios";
import http from "./httpService";

export const getAllEvents = () => {
  return http.get("/marketing/event");
};

// get single event
export const getEvent = (eventId: string) => {
  return http.get(`/marketing/event/${eventId}`);
};

export const createEvent = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/marketing/create/event", data, options);
};

export const archiveEvent = (
  eventId: string,
  handleProgressChange?: (arg0: number) => any
) => {
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange &&
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.patch(`/marketing/archive/event/${eventId}`, options);
};

export const updateEvent = (
  eventId: string,
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post(`/marketing/update/event/${eventId}`, data, options);
};

export const createPostEvent = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  //
  const options = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/marketing/post/event", data, options);
};

export const getAllTools = () => {
  return http.get("/marketing/tool");
};

export const createTool = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/marketing/create/tool", data, options);
};

export const editTool = (data: any, id: string) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };
  return http.post(`/marketing/update/tool/${id}`, data, options);
};

export const getAllStories = () => {
  return http.get("/marketing/stories");
};

export const createStory = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/marketing/create/story", data, options);
};
export const editStory = (
  data: any,
  handleProgressChange: (arg0: number) => any,
  id: any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };
  return http.post(`/marketing/update/story/${id}`, data, options);
};

export const getDrips = () => {
  return http.get("/marketing/drip/all");
};

export const getAllDrips = () => {
  return http.get("/marketing/drip/type");
};

export const createDrip = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/marketing/create/drip", data, options);
};

export const sendAcquisition = (data: any) => {
  // const options = {
  //   onUploadProgress: (progressEvent) =>
  //     handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  // };

  return http.post("/marketing/send/acquisition", data);
};

export const createDazzle = (
  data: any,
  handleProgressChange: (arg0: number) => any
) => {
  const options = {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      handleProgressChange(
        (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
      ),
  };

  return http.post("/marketing/dazz", data, options);
};

export const getAllDazz = () => {
  return http.get("/marketing/dazz");
};

const marketingService = {
  getAllEvents,
  getEvent,
  createEvent,
  createPostEvent,
  getAllTools,
  editStory,
  createTool,
  editTool,
  getAllStories,
  createStory,
  sendAcquisition,
  createDazzle,
  createDrip,
  getAllDazz,
  getAllDrips,
  getDrips,
  archiveEvent,
  updateEvent,
};

export default marketingService;
