import React from "react";
import { useNavigate } from "react-router";

interface Text {
  text: string;
}

const Modal = ({ text }: Text) => {
  const history = useNavigate();

  const handleClick = () => {
    history("/exodus");
  };

  return (
    <div className=" top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 flex items-center justify-center modal-backdrop ">
      <div className="bg-[#fff]  h-2/5 w-5/12 rounded-md flex flex-col justify-start">
        <div className="bg-[#D4C7F1] text-[#4D26A0] text-[8xl] rounded-t-md text-center font-extrabold p-3 w-full">
          <p className="text-2xl">Success!</p>
        </div>
        <div className="flex flex-col mx-auto mb-4 items-center h-3/5 justify-center">
          <p className="text-2xl mb-8 text-center">{text}</p>
          <button
            className="border border-red-500 p-2 flex mx-auto  items-center justify-center w-[100px] cursor-pointer"
            onClick={handleClick}
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
