import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import agentService from "../../services/agent.service";
import { AxiosResponse } from "axios";
import toastService from "../../services/toastService";
import utilities from "../../utils/utilities";
import { getPrivileges } from "../../services/authService";

const privileges = getPrivileges();
export const useAddAgent = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateAddAgent,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: {
      username: string;
      email: string;
      password: string;
      confirmPassword: string;
      position: string;
      city: string;
      country: string;
    }) => {
      const response = await agentService.createAgent(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["agents"],
      });
    },
    onError: (error: {
      response: {
        data: { description: string };
      };
    }) => {
      toastService.error(
        error.response.data.description || "Failed to create agent"
      );
    },
  });

  return { mutateAddAgent, isPending, error };
};

export const useGetCityAgent = (city: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["city-agent", city],
    queryFn: async () => {
      const response = (await agentService.getCityAgents(
        city
      )) as AxiosResponse;
      return response;
    },
  });

  const cityAgent: any = (data as AxiosResponse)?.data?.data || [];

  let mappedAgents: any[] = utilities.mapItemsFromObjects(cityAgent);
  //   let parsedKey: any[] = utilities.mapKeyToNumber(mappedAgents, "id");

  if (isError) {
    // toastService.error((error as AxiosError)?.message);
    toastService.error("No City Agent found");
  }

  return { isError, isSuccess, mappedAgents, isPending, error };
};
export const useGetSalesAgent = (city: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["sales-agent", city],
    enabled: !!city,
    queryFn: async () => {
      const response = (await agentService.getSaleAgents(
        city.toLowerCase()
      )) as AxiosResponse;
      return response;
    },
  });

  const salesAgent: any = (data as AxiosResponse)?.data?.data || [];

  let mappedAgents: any[] = utilities.mapItemsFromObjects(salesAgent);

  return { isError, isSuccess, mappedAgents, isPending, error };
};

export const useAssignAgent = (
  handleProgressChange?: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateAssignAgent,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await agentService.reassign(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["agent"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateAssignAgent, isPending, error };
};

export const useGetAgentAdminHospitals = (
  page?: number | string,
  limit?: number,
  status?: string,
  name?: string,
  userId?: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["agent", page, limit, status, name],
    // enabled: permission.createAgent,
    queryFn: async () => {
      const response = (await agentService.getAgentAdminHospitals(
        page,
        limit,
        status,
        name,
        userId
      )) as AxiosResponse;
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const hospitals: any = data?.data?.data || [];
  const paginate: any = data?.data?.meta;

  return { isError, isSuccess, hospitals, paginate, isPending };
};

export const useGetAllAgents = (
  page?: number,
  limit?: number,
  search?: string,
  position?: string,
  status?: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["agents", page, limit, search, position, status],
    queryFn: async () => {
      const response = await agentService.getAgents(
        page,
        limit,
        search,
        position,
        status
      );
      return response;
    },
    enabled: privileges.createAgent,
    placeholderData: keepPreviousData,
  });

  const agents: any = data?.data?.data || [];
  const paginate: any = data?.data?.meta;

  return { isError, isSuccess, agents, isPending, paginate };
};

const fetchAllAgents = async (
  limit?: number,
  search?: string,
  position?: string
) => {
  let currentPage = 1;
  let totalPages = 1;
  let allAgents: any[] = [];

  while (currentPage <= totalPages) {
    const response: any = await agentService.getAgents(
      currentPage,
      limit,
      search,
      position
    );

    allAgents = [...allAgents, ...response?.data?.data];
    totalPages = response.data.meta.totalPages;
    currentPage++;
  }

  return allAgents;
};
export const useGetAllAgent = (
  privilege: boolean,
  limit?: number,
  search?: string,
  position?: string
) => {
  const { isError, isSuccess, data, isLoading } = useQuery({
    enabled: privilege,
    queryKey: ["agents", limit, search, position],
    queryFn: () => fetchAllAgents(1000, search, position),
  });

  return { isError, isSuccess, agent: data || [], isLoading };
};

export const useGetRewardList = (
  type: string,
  page: number,
  limit: number,
  status: string,
  user_id?: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["reward-list", type, page, limit, status, user_id],
    queryFn: async () => {
      const response = await agentService.getRewardList(
        type,
        page,
        limit,
        status,
        user_id
      );
      return response;
    },
  });

  const rewards: any = data?.data?.data || [];
  const paginate: any = data?.data?.meta;

  return { isError, isSuccess, rewards, isPending, paginate };
};

export const useGetRewardBalance = (userId: string) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["reward-balance", userId],
    queryFn: async () => {
      const response = await agentService.getRewardBalance(userId);
      return response;
    },
  });

  const balance: any = data?.data || {};

  return { isError, isSuccess, balance, isPending };
};

export const useGetRewardValues = (
  type: string,
  status: string,
  userid?: string
) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["reward-values", type, status, userid],
    queryFn: async () => {
      const response = await agentService.getRewardValue(type, status, userid);
      return response;
    },
  });

  const values: any = data?.data || {};

  return { isError, isSuccess, values, isPending };
};

export const useGetHospitalHistory = () => {
  const {
    mutate: mutatePostHospitalHistory,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: { refId: string; hospitalId: string }) => {
      const response = await agentService.getHospitalOrderHistory(data);
      return response;
    },
    onSuccess: (data: any) => {},
    onError: (error: { message: string }) => {},
  });

  return { mutatePostHospitalHistory, isPending, error };
};

export const usePostCadre = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutatePostCadre,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: {
      ref: string;
      blood: string;
      oxygen: string;
    }) => {
      const response = await agentService.postCadre(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["cadre-request"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutatePostCadre, isPending, error };
};

export const useGetCadreRequest = () => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["cadre-request"],
    queryFn: async () => {
      const response = await agentService.getCadreRequest();
      return response;
    },
  });

  const requests: any = data?.data || [];

  return { isError, isSuccess, requests, isPending };
};

export const useGetCadreList = () => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["cadre-list"],
    queryFn: async () => {
      const response = await agentService.getCadreList();
      return response;
    },
  });

  const cadreList: any = data?.data ?? [];

  return { isError, isSuccess, cadreList, isPending };
};

export const useApproveCadreRequest = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateApproveCadreRequest,
    isPending,
    error,
  } = useMutation({
    mutationKey: ["approve-cadre"],
    mutationFn: async (data: any) => {
      console.log(data);
      const response = await agentService.approveCadreRequest(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["cadre-request"],
      });
    },
    onError: (error: { message: string }) => {},
  });

  return { mutateApproveCadreRequest, isPending, error };
};

export const useGetCancelledOrders = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  user_id?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["cancelled-orders", filters],
    queryFn: async () => {
      const response: any = (await agentService.cancelledOrders(
        filters
      )) as AxiosResponse<any[]>;
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const cancelledOrders: any = data?.data?.data ?? [];
  const paginate: any = data?.data?.meta ?? {};

  return { isError, isSuccess, cancelledOrders, isPending, paginate };
};

export const useDisableAgent = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateDisableAgent,
    isPending,
    error,
  } = useMutation({
    mutationKey: ["disable-agent"],
    mutationFn: async (data: any) => {
      console.log(data);
      const response = await agentService.disableAgent(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["agents"],
      });
      toastService.success(data?.data?.message);
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message);
    },
  });

  return { mutateDisableAgent, isPending, error };
};

export const useAssignHospitalAgent = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateAssignHospitalAgent,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await agentService.assignAgent(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["hospitals"],
      });
      toastService.success(data?.data?.message);
    },
    onError: (error: { response: { data: { message: string } } }) => {
      console.log(error);
      toastService.error(error.response?.data?.message);
    },
  });

  return { mutateAssignHospitalAgent, isPending, error };
};

export const useGetReservedHospitals = (filters: {
  page?: number | string;
  limit?: number;
  name?: string;
  status?: string;
}) => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["reserve", filters],
    queryFn: async () => {
      const response: any = (await agentService.reservedHospitals(
        filters
      )) as AxiosResponse<any[]>;
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const reservedHospitals: any = data?.data?.data ?? [];
  const paginate: any = data?.data?.meta ?? {};

  return { isError, isSuccess, reservedHospitals, isPending, paginate };
};

export const useAcceptReserveRequest = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateAcceptReserveRequest,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await agentService.acceptReserveRequest(data);
      return response;
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ["reserve"],
      });
      toastService.success(data?.data?.message);
    },
    onError: (error: { response: { data: { message: string } } }) => {
      console.log(error);
      toastService.error(error.response?.data?.message);
    },
  });

  return { mutateAcceptReserveRequest, isPending, error };
};
