import React from "react";
import Button from "../../../../../components/common/button";
import appointmentService from "../../../../../services/appointmentService";
import toastService from "../../../../../services/toastService";

const Training = (props) => {
  const { selectedPitch, selectedResponse, handleNext } = props;

  const postData = async () => {
    const res = await appointmentService.confirmTraining(
      selectedPitch.hospital_id
    );
    if (res.data.status !== "success") throw new Error(res.data.message);
    if (res.data.status === "success") handleNext();
  };

  const handleTraining = (e) => {
    e.preventDefault();
    toastService.promise(postData(), {
      loading: "sending data...",
      success: () => `Training for ${selectedPitch.name} has been completed!`,
      error: (e) =>
        e?.response?.data?.message || e?.message || "Could not confirm!",
    });
  };

  return (
    <div>
      <div className="pitch-data">
        <span className="label">Has {selectedPitch?.name} been trained?</span>

        {selectedResponse?.training?.status === "No" && (
          <div>
            <span className="nerve">Click yes to confirm training</span>
            <span className="check_button" onClick={handleTraining}>
              Yes
            </span>
          </div>
        )}

        {selectedResponse?.training?.status === "Yes" && (
          <div>
            <span className="nerve">Trained, Click Next to continue</span>
          </div>
        )}
      </div>
      <div className="checklist-button">
        {/* <Button label="Prev" onClick={handlePrev} /> */}
        {selectedResponse?.training?.status === "Yes" && (
          <Button label="Skip" onClick={handleNext} />
        )}
      </div>
    </div>
  );
};

export default Training;
