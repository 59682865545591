import React from "react";
import { useNavigate } from "react-router";
import { CiLocationOn } from "react-icons/ci";
import ExodusButton from "../../../../components/exodusComponent/ExodusButton";
import toastService from "../../../../services/toastService";

const Location = ({
  setIsOpenLocation,
  address,
  name,
  cord,
  id,
  contactType,
  error,
}: {
  setIsOpenLocation: (arg0: boolean) => void;
  address: string | null;
  name: string;
  cord: string | null;
  id: string;
  contactType: string;
  error: string | null;
}) => {
  const navigate = useNavigate();

  const handleNote = () => {
    if (error) {
      toastService.error(error);
      return;
    }
    navigate("/exodus/call", {
      state: { name, id, address, cord, contactType },
    });
  };
  const handleClose = () => {
    setIsOpenLocation(false);
  };
  return (
    <div className=" top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 flex items-center justify-center modal-backdrop ">
      <div className="bg-[#fff]  h-[450px] lg:w-5/12 sm:w-9/12  rounded-md flex flex-col justify-start relative">
        <div className="bg-[#D4C7F1] text-[#4D26A0] text-[8xl] rounded-t-md text-center font-extrabold p-3 w-full">
          <p className="text-2xl">Log Your Visit</p>
        </div>

        <div
          className="absolute flex items-center justify-center right-1 top-1 cursor-pointer  h-[40px] w-[40px] bg-[#4D26A0] text-center rounded-full"
          // onClick={handleClick}
          onClick={handleClose}
        >
          <p className="text-2xl text-white ">X</p>
        </div>

        <div className="flex flex-col items-center justify-evenly">
          <div className="p-4">
            <h1>
              This is required before you can proceed to the next stage of the
              acquisition process
            </h1>
          </div>
          <div className="w-10/12 p-2 bg-[#EEE8FA]">
            <div className="flex justify-between p-2">
              <p className="text-[#066304] font-extrabold lg:text-[20px] sm:text-[14px]">
                Your current location
              </p>
              <p className="lg:text-[20px] sm:text-[14px]">{cord}</p>
            </div>
            <div
              className="rounded w-11/12 p-2 pb-2 mx-auto "
              style={{
                borderColor: "#4D26A0",
                borderRadius: "4px",
                borderWidth: "2px",
              }}
            >
              <div className="flex justify-between w-full">
                <div className="mt-2 w-2/12">
                  <p>
                    <CiLocationOn size={40} />
                  </p>
                </div>
                <div className="w-9/12">
                  <p className="lg:text-[18px] sm:text-[15px] text-[#4D26A0] font-bold">
                    {/* {address} */}
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-center mt-8">
                <div className="w-2/12"></div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 w-10/12 mt-4 mx-auto justify-between ">
            <div className="w-4/12">
              <ExodusButton text={"back"} onClick={handleClose} />
            </div>
            <div className="w-7/12">
              <ExodusButton text={"Submit meeting note"} onClick={handleNote} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
