import React from "react";

import OptimizedRequestInfo from "./optimized";

const RequestInfo = () => {
  return (
    <div>
      <OptimizedRequestInfo />
    </div>
  );
};

export default RequestInfo;
