import React from "react";
import { Link } from "react-router-dom";

import { useGlobalContext } from "../../contexts/globalContext";

const HeaderContainer = () => {
  const { user, activeTab, setActiveTab } = useGlobalContext();

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const isSalesUser =
    user?.position === "sales_lead" ||
    user?.position === "sales_rep" ||
    user?.position === "marketing" ||
    user?.position === "customer_success";
  return (
    <div className="bg-[#5D12D2] py-2 flex flex-col sm:flex-row justify-between items-center px-5 shadow-md w-full  sm:h-48 ">
      <div className="flex flex-col justify-start  items-start ">
        <h1 className="text-white text-4xl font-bold text-uppercase px-7 text-center sm:text-left">
          One Hospital
        </h1>
        <p className="text-white text-lg font-bold px-7 w-full text-center sm:text-left">
          <br></br>
          Our Job is to add value our hospital-customers always
          {/* "Adding value to our hospital-customers always */}
        </p>

        <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start sm:justify-between px-7 mt-4 gap-6  w-full sm:w-auto">
          <button
            className={`text-white font-semibold ${
              activeTab === "focus" ? "border-b-2 border-white" : ""
            }`}
            onClick={() => handleActiveTab("focus")}
          >
            Your focus
          </button>

          {isSalesUser && (
            <button
              // to="/active-feed"
              className={`text-white font-semibold ${
                activeTab === "history" ? "border-b-2 border-white" : ""
              }`}
              onClick={() => handleActiveTab("history")}
            >
              History
            </button>
          )}

          <Link
            to="/hospitals"
            className={`text-white font-semibold ${
              activeTab === "hospital-view" ? "border-b-2 border-white" : ""
            }`}
            onClick={() => handleActiveTab("hospital-view")}
          >
            Hospital View
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer;
