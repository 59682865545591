import { ApiResponse } from "../../ts-utils/types";
import { useEffect, useState } from "react";

import { getAllFeedback } from "../../services/hospital.service";
// import { error as toastError } from "../../services/toastService";

interface Props {
  hospitalId: string;
}

interface Feedback {
  feedback: string;
}

const HospitalFeedback: React.FC<Props> = ({ hospitalId }: Props) => {
  const [feedback, setFeedback] = useState<Feedback[]>([]);

  const getHospitalFeedback = async (): Promise<void> => {
    try {
      let response: ApiResponse = await getAllFeedback(hospitalId);
      let result: any = response.data;

      setFeedback(result.hospitals);
    } catch (e: any) {
      // let errorMessage: string = "No feedback history!";
      // toastError(errorMessage);
    }
  };

  useEffect(() => {
    getHospitalFeedback();

    // eslint-disable-next-line
  }, []);

  return (
    <ul className="complaint-modal-items no-list">
      {Array.isArray(feedback)
        ? feedback?.map((f: Feedback) => (
            <li key={f.feedback} className="complaint-modal-items-single">
              <i className="fa fa-info-circle"></i> <span>Feedback:</span>{" "}
              {f.feedback}
            </li>
          ))
        : []}
    </ul>
  );
};

export default HospitalFeedback;
