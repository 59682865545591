import { data } from "../../../../contant";
// import { useGetAllExodus } from "../../../../hooks/useExodus";
// import { useState } from "react";

// import { useGlobalContext } from "../../../../contexts/globalContext";
import { useNavigate } from "react-router-dom";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import LoadingButton from "../../../../components/common/loadingButton";
import React from "react";

const Exodus = () => {
  const navigate = useNavigate();
  // const { user } = useGlobalContext();

  // const filters = {
  //   user_id: user?.id as string,
  //   status: "exodus",
  // };
  // const { paginate, isPending } = useGetAllExodus({ ...filters }, "exodus");

  const handleClick = (item: any) => {
    navigate(item.link);
  };

  // const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="w-full">
      <ExodusHeader
        Text={"Exodus Arena"}
        subText={"'Adding value to our hospital-customer always'"}
      />
      {false ? (
        <div className="flex flex-col justify-between items-center w-full p-5 sm:p-10">
          <LoadingButton
            visible={false}
            message="Fetching Data... Please wait"
          />
        </div>
      ) : (
        <>
          <p className="text-[#3C3C3C] mt-8 mb-8 text-2xl ml-8 w-10/12 mx-auto pb-4">
            Welcome to Exodus Arena
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto w-10/12 mb-8">
            {data?.map((item, index) => (
              <div
                key={index}
                className="flex flex-row bg-[#FFFFFF] hover:bg-[#f0eded] h-[170px] shadow-white shadow-lg lg:w-12/12  item-center rounded-lg cursor-pointer"
                onClick={() =>
                  item.text !== "Average Spend" && handleClick(item)
                }
                // onMouseEnter={() =>
                //   item.text === "Average Spend" && setIsHovered(true)
                // }
                // onMouseLeave={() =>
                //   item.text === "Average Spend" && setIsHovered(false)
                // }
              >
                {index < data.length - 3 && (
                  <div className=" flex-col justify-around    bg-[#49209F] rounded-l-lg p-4 flex items-center text-white text-4xl lg:w-[100px] md:w-[80px]">
                    {item.icon}
                  </div>
                )}
                <div className="flex flex-col items-center my-auto w-full ">
                  {index >= data?.length - 3 && (
                    <div className="flex flex-col items-center justify-around text-[40px] text-[#49209F]">
                      {item.icon}
                    </div>
                  )}
                  <div className="p-2 font-extrabold lg:text-2xl sm:text-lg text-center">
                    <div className="p-2 font-extrabold lg:text-2xl sm:text-lg text-center">
                      {/* {item.text === "Number of Hospitals in Exodus" ? (
                        paginate?.totalItems
                      ) : item.text === "Average Spend" ? (
                        <>
                          <div className="relative">
                            <div
                              className={`absolute inset-0 flex items-center justify-center transition-transform duration-500 ${
                                isHovered ? "transform rotate-y-180" : ""
                              }`}
                            >
                              <p
                                className={`${isHovered ? "hidden" : "block"}`}
                              >
                                Your Pipeline
                              </p>
                              <div className="flex gap-2">
                                <button
                                  onClick={() =>
                                    handleClick({
                                      ...item,
                                      link: `/exodus/current`,
                                    })
                                  }
                                  className={`${
                                    isHovered ? "block" : "hidden"
                                  } bg-green-500 hover:bg-green-600 text-sm text-white py-2 px-4 rounded`}
                                >
                                  Current Month Pipeline
                                </button>{" "}
                                <button
                                  onClick={() =>
                                    handleClick({
                                      ...item,
                                      link: `/exodus/future`,
                                    })
                                  }
                                  className={`${
                                    isHovered ? "block" : "hidden"
                                  } bg-blue-500 hover:bg-blue-600 text-sm text-white py-2 px-4 rounded`}
                                >
                                  Future Month Pipeline
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : item.text === "Possible Revenue For Exodus" ? (
                        `Lite Orders`
                      ) : (
                        <p className="font-semibold capitalize">{item.text}</p>
                      )} */}

                      <p className="font-semibold capitalize">{item.text}</p>
                    </div>
                  </div>
                  <p className="p-2 text-center font-normal text-1xl">
                    {item.subText}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Exodus;
