import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import marketingService from "../../services/marketing.service";
import { AxiosResponse } from "axios";
import toastService from "../../services/toastService";

interface ResponseData {
  [x: string]: any;
  description: string;
  data: any;
  meta: any;
}

export const useGetDazzle = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["dazzle"],
    queryFn: async () => {
      const response =
        (await marketingService.getAllDazz()) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const dazzle: any = (data as AxiosResponse<ResponseData>)?.data?.data || [];

  const baseUrl = (data as AxiosResponse<ResponseData>)?.data?.baseurl;

  if (isError) {
    // toastService.error((error as AxiosError)?.message);
    toastService.error("No dazzle found");
  }

  return { isError, isSuccess, dazzle, baseUrl, isPending, error };
};

export const useCreateDazzle = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.createDazzle(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Dazzle created successfully!");
      queryClient.refetchQueries({
        queryKey: ["dazzle"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to create dazzle");
    },
  });

  return { mutate, isPending, error };
};
