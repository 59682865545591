import React, { Dispatch, SetStateAction } from "react";

import { ReloadIcon } from "@radix-ui/react-icons";

const RequestItem = ({
  itemName,
  handleSubmit,
  setInputText,
  inputText,
  isPending,
}: {
  itemName: string;
  handleSubmit: () => void;
  setInputText: Dispatch<SetStateAction<string>>;
  inputText: string;
  isPending: boolean;
}) => {
  return (
    <div className="max-w-md mx-auto bg-white p-8 border rounded-lg shadow-md mt-20 text-blue-600 ">
      <h2 className="text-2xl font-bold mb-4 text-center">Hey!!!</h2>
      <p className="text-center mb-3">
        Would you want us to remind you when the{" "}
        <span className="capitalize font-semibold text-xl">{itemName}</span>{" "}
        stock is available?
      </p>
      {inputText === "" && (
        <p className="text-red-500 text-sm">Please enter a product name</p>
      )}
      <div className="flex items-center w-full">
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Product Name"
          className="w-full p-2 border border-gray-300 rounded-l-md focus:outline-none text-gray-600"
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-600 text-white px-4 py-2 rounded-r-md hover:bg-consumableGreen focus:outline-none"
          disabled={!inputText.trim() || isPending}
        >
          {isPending && <ReloadIcon className="w-5 h-5 animate-spin mr-2" />}
          {isPending ? "" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default RequestItem;
