import { useEffect, useState } from "react";
import { ApiResponse, ComponentProps } from "../../ts-utils/types";
import { SelectOption, State } from "../../ts-utils/interfaces";

import { error as toastError } from "../../services/toastService";

import { useGlobalContext } from "../../contexts/globalContext";
import { getCities } from "../../services/dashboard.service";

function withCities(Component: any): (props: any) => JSX.Element {
  return function WithComplaints(props: ComponentProps): JSX.Element {
    const [cities, setCities] = useState<SelectOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { user } = useGlobalContext();

    const getCityMatch = (states: State[], stateName: string) => {
      return states.find((state: State) => {
        return state.name.toLowerCase() === stateName.toLowerCase();
      });
    };

    const addMissingCities = (states: State[]): State[] => {
      const allStates: State[] = states;
      const rivers: State | undefined = getCityMatch(allStates, "Rivers State");
      const isNigeria: boolean = user?.country.toLowerCase() === "nigeria";

      if (isNigeria && !rivers)
        allStates.push({ name: "Rivers State", state_code: "RI" });

      allStates.sort((a: State, b: State): number => {
        if (a.state_code < b.state_code) return -1;
        return a.state_code > b.state_code ? 1 : 0;
      });

      return allStates;
    };

    const mapCities = (states: any[]): SelectOption[] => {
      const allStates: State[] = addMissingCities(states);

      return allStates.map((state: State) => {
        let id: string = state.name?.split(" ")?.[0]?.toLowerCase();
        let finalId: string = id === "federal" ? "abuja" : id;

        return { id: finalId, name: state.name };
      });
    };

    const getAllCities = async (): Promise<void> => {
      try {
        setLoading(true);

        let response: ApiResponse = await getCities(user?.country as string);
        let result: any = response.data;
        let states: SelectOption[] = result.error
          ? []
          : mapCities(result.data.states);

        setCities(states);
        setLoading(false);
      } catch (ex: any) {
        let errorMessage: string = ex?.response?.data?.msg ?? ex.message;

        toastError(errorMessage);
        setLoading(false);
      }
    };

    useEffect(() => {
      getAllCities();

      // clean up
      return () => {
        setCities([]);
        setLoading(false);
      };

      // eslint-disable-next-line
    }, []);

    return <Component cities={cities} loading={loading} {...props} />;
  };
}

export default withCities;
