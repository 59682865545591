import React from "react";
import { FaMoneyBillWaveAlt } from "react-icons/fa";
import { RiChatCheckFill } from "react-icons/ri";
import utilities from "../../../../utils/utilities";
import { Separator } from "../../../../@/components/ui/separator";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
// import { useGlobalContext } from "../../../../contexts/globalContext";
// import formatCost from "../../utils/formatCost";
// import convertToNumber from "../../utils/convertToNumber";

dayjs.extend(customParseFormat);

interface QuotationCardProps {
  id: string;
  channel?: string;
  status: string;
  date: string;
  total: string;
  order_by?: string;
  validity: string;
  onClick?: () => void;
  tym: string;
}

// const getTimestampFromDate = (dateString: string) => {
//   // Parse the date string with dayjs
//   return dayjs(dateString, "DD/MM/YYYY HH:mm:ss").valueOf(); // Convert to milliseconds timestamp
// };
const QuotationCard = ({
  id,
  channel,
  status,
  date,
  total,
  order_by,
  validity,
  onClick,
  tym,
}: QuotationCardProps) => {
  const handleClick = () => {
    onClick && onClick();
  };
  // const [countdown, setCountdown] = useState<string>("");

  // useEffect(() => {
  //   const countDownDate = getTimestampFromDate(date); // Now the correct format
  //   const interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const distance = countDownDate - now;

  //     if (distance > 0) {
  //       const hours = Math.floor(
  //         (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //       );
  //       const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //       const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //       const formattedCountdown = `${String(hours).padStart(2, "0")}:${String(
  //         minutes
  //       ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  //       setCountdown(formattedCountdown);
  //     } else {
  //       setCountdown("00:00:00");
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [date]);

  return (
    <div
      role="button"
      className="bg-white hover:bg-slate-100 border border-gray-300 rounded-lg p-4 shadow-md cursor-pointer space-y-3"
      onClick={handleClick}
    >
      <div>
        <h2 className="text-xl">Quotation #{id}</h2>
        <p className="text-lg font-semibold">
          Quotation Requested Valid till:{" "}
          {dayjs(date, "DD/MM/YYYY HH:mm:ss").format("DD-MM-YY hh:mm A")}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <RiChatCheckFill className="text-gray-600" />
          <p className="text-gray-600">Status:</p>
          <span
            className={
              status === "ready"
                ? "text-green-500 capitalize"
                : "text-yellow-500 capitalize"
            }
          >
            {status}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <FaMoneyBillWaveAlt className="text-gray-600" />
          <p className="text-gray-600">Price:</p>
          <span>{utilities.currencyFormatter(parseFloat(total), "")}</span>
        </div>
      </div>
      {/* 
      {countdown !== "" && (
        <div className="">
          <div className="flex items-center space-x-2">
            <FaClock className="text-gray-600" />
            <p className="text-gray-600">Time Remaining:</p>
            <span>{countdown}</span>
          </div>
        </div>
      )} */}
      <Separator className="my-4" />
      <div className="">
        <p className="text-gray-600 italic">
          Note: Quotation is only available till the date above. After this
          time, a new request must be placed.
        </p>
      </div>
    </div>
  );
};

export default QuotationCard;
