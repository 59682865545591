import React, { useState } from "react";
import Button from "../../../../../components/common/button";
import Modal from "../../../../../components/common/modal";
import { ReactComponent as UploadIcon } from "../../../../../assets/svg/upload.svg";

import { Document, Page, pdfjs } from "react-pdf";
import { useUploadSla } from "../../../../../hooks/sales/useAppointment";

// Set the worker URL for the react-pdf package (should be imported only once)
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SLA = (props: {
  selectedPitch: any;
  selectedResponse: any;
  handlePrev: any;
  handleNext: any;
}) => {
  const { selectedPitch, selectedResponse, handlePrev, handleNext } = props;
  const [offer, setOffer] = useState(null);
  const [sla, setSla] = useState(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");

  // const handleOffer = (value: React.SetStateAction<null>) => {
  
  //   setOffer(value);
  // };

  const handleSla = (value: React.SetStateAction<null>) => {
    setSla(value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    console.log(selectedFile);
    if (selectedFile) {
      setFile(selectedFile as File);
      setFileName(selectedFile.name);
    }
  };

  const { mutateUploadSla, isPending } = useUploadSla();

  const postFile = async () => {
    // Create a FormData object
    const formData = new FormData();
    if (file) formData.append("sla", file as File);
    const payload = {
      id: selectedPitch.hospital_id,
      data: formData,
    };

    mutateUploadSla(payload, {
      onSuccess: (data) => {
        console.log(data);
        handleNext();
      },
    });
  };

  const [isPdf, setIsPdf] = useState(false);

  const handleLoadSuccess = () => {
    setIsPdf(true);
  };

  const handleImageError = () => {
    setIsPdf(true); // If image fails to load, assume it's a PDF
  };

  return (
    <div>
      <div className="pitch-data">
        <span className="label">Check for documentation</span>
        {selectedResponse?.sla?.status === "Yes" && (
          <span
            className="link"
            onClick={() => handleSla(selectedResponse.sla.data)}
          >
            view SLA
          </span>
        )}
        {selectedResponse?.sla?.status === "No" && (
          <span className=""
          //  onClick={() => handleOffer(selectedPitch)}
           >
            {/* click to upload */}
            No
          </span>
        )}
      </div>
      <div className="checklist-button">
        {/* {selectedResponse?.sla?.status === "No" && ( */}
        <Button label="Prev" onClick={handlePrev} />
          <Button label="Next" onClick={handleNext} />
        {/* )} */}
        {/* {selectedResponse?.sla?.status === "Yes" && (
        )} */}
      </div>

      {offer && (
        <Modal handleClose={() => setOffer(null)}>
          <div className="row">
            <div className="col-lg">
              <div className="upload-container">
                <label htmlFor="file" className="form-field-label">
                  Offer Letter:
                </label>
                <div className="upload">
                  <label htmlFor="file" className="upload-label">
                    {file ? (
                      <img
                        src={URL.createObjectURL(file as File)}
                        alt={fileName}
                      />
                    ) : (
                      <>
                        <UploadIcon className="upload-icon" />
                      </>
                    )}
                  </label>
                  <input
                    name="file"
                    type="file"
                    id="file"
                    accept=".jpeg, .png, .jpg, .pdf"
                    className="upload-input"
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button
            type="button"
            disabled={isPending}
            label="Upload"
            onClick={postFile}
          />
        </Modal>
      )}

      {sla && (
        <Modal handleClose={() => setSla(null)}>
          <div className="row">
            <div className="col-lg">
              <div>
                {isPdf ? (
                  <a href={sla} target="_blank" rel="noopener noreferrer">
                    <Document file={sla} onLoadSuccess={handleLoadSuccess}>
                      <Page pageNumber={1} />
                    </Document>
                  </a>
                ) : (
                  <a href={sla} target="_blank" rel="noopener noreferrer">
                    <img src={sla} alt="Item" onError={handleImageError} />
                  </a>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SLA;
