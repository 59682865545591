import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import httpService from "./httpService";

const getProductList = () => {
  return httpService.get("/products/list");
};

export const addProduct = (
  data: { name: string; color: string },
  handleProgressChange: { (progress: any): void; (arg0: number): any }
) => {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total !== undefined) {
        handleProgressChange(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    },
  };

  return httpService.post("products/add", data, options);
};

const productService = {
  addProduct,
  getProductList,
};

export default productService;
