import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import BackButton from "../backbutton";
import Checkbox from "../../common/checkbox";
import CustomField from "../../common/CustomField";
import Button from "../../common/button";
import {
  useUpdateDangerTrack,
  useGetDangerTrack,
} from "../../../hooks/useHospital";
import LoadingButton from "../../common/loadingButton";
import { success } from "../../../services/toastService";
import { useQueryClient } from "react-query";

type Props = {
  setShowMastery: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
  handleClose: () => void;
};

const Track = ({ setShowMastery, refId, handleClose }: Props) => {
  const queryClient = useQueryClient();
  const { updateDangerTrack, isPending } = useUpdateDangerTrack();
  const { dangerTrack, isPending: isDangerTrackLoading } =
    useGetDangerTrack(refId);

  const [records, setRecords] = useState<{ [key: string]: any }>({});
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    if (dangerTrack && dangerTrack.records) {
      setRecords(JSON.parse(dangerTrack.records));
    }
  }, [dangerTrack]);

  const handleCheckboxChange = (name: string, checked: boolean) => {
    console.log(name, checked);
    setRecords((prevRecords) => ({
      ...prevRecords,
      [name]: {
        ...prevRecords[name],
        value: checked,
      },
    }));
  };

  const onSubmit = (data: any) => {
    const formattedData: {
      [key: string]: { instruction: string; value: string };
    } = {};

    for (const key in data) {
      formattedData[key] = {
        instruction: records[key]?.instruction || "",
        value: data[key] || records[key]?.value || "",
      };
    }

    const record = {
      ref: dangerTrack.id,
      record: formattedData,
    };

    updateDangerTrack(record, {
      onSuccess: (data) => {
        success(data?.data?.message);
        queryClient.refetchQueries({ queryKey: ["hospitals"] });
        handleClose();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  if (isDangerTrackLoading) {
    return (
      <div>
        <LoadingButton
          visible={isDangerTrackLoading}
          message="Fetching Data... Please wait"
        />
      </div>
    );
  }
  if (!dangerTrack) {
    return (
      <div>
        <BackButton goBack={() => setShowMastery(false)} />
        <p className="flex items-center justify-center h-full"> No Data</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <BackButton goBack={() => setShowMastery(false)} />

      <div className="my-8">
        {Object.entries(records).map(([date, record], index) => (
          <CustomField
            key={date}
            title={`Day  ${index * 2 + 1} - ${date} - ${record.instruction}`}
            value={
              <Controller
                control={control}
                name={date}
                render={({ field: { onChange, value, name } }) => (
                  <Checkbox
                    name={name}
                    message="Done"
                    checked={value === true}
                    onChange={(e: { target: { checked: boolean } }) => {
                      onChange(e.target.checked ? true : false);
                      handleCheckboxChange(name, e.target.checked);
                    }}
                  />
                )}
              />
            }
          />
        ))}
      </div>

      <Button
        disabled={isPending}
        type="button"
        onClick={handleSubmit(onSubmit)}
        label="Finish Actions"
      />
    </React.Fragment>
  );
};

export default Track;
