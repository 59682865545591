import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import utils from "../../utils/utilities";
import {
  OneHospitalUser,
  OneHospitalUserState,
} from "../../ts-utils/interfaces";

// Ensure the initial state follows the OneHospitalUserState structure
const initialState: OneHospitalUserState = {
  id: utils.makeid(),
  timestamp: Date.now(),
  userData: {
    id: "",
    email: "",
    position: "",
    username: "",
    country: "",
    city: "",
    rememberMe: false,
  },
  countryData: {
    id: "",
    imageUrl: "",
    label: "",
    country: "",
    color: "",
    countryUrl: "",
    testUrl: "",
    liteApiUrl: "",
    currency: "",
  },
  userType: "",
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<OneHospitalUser>) => {
      state.userData = action.payload;
    },
    setCountryData: (state, action) => {
      state.countryData = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    }, // Logout reducer: Reset state to initialState
    logout: (state) => {
      state.id = utils.makeid();
      state.timestamp = Date.now();
      state.userData = initialState.userData;
      state.userType = initialState.userType;
    },
    setChangeCountry: () => {
      return initialState;
    },
  },
});

export const {
  setUser,
  setCountryData,
  setUserType,
  logout,
  setChangeCountry,
} = userSlice.actions;

export default userSlice.reducer;
