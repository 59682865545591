import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../../../components/common/button";
import {
  useGetEquipmentList,
  useSendQuipQuotations,
} from "../../../../hooks/purchasingOrder/usePurchaseOrder";

import PaymentMethods from "./PaymentMethod";
import toastService from "../../../../services/toastService";
import * as Dialog from "@radix-ui/react-dialog";
import { Renderable, ValueFunction, Toast } from "react-hot-toast";

const EquipmentDetails = () => {
  const navigate = useNavigate();
  const { id, item } = useParams();

  const hospitalId = useSelector(
    (state: { cart: any }) => state.cart.hospital_id
  );

  const { mutateSendQuipQuotations, isPending: isSubmitting } =
    useSendQuipQuotations();

  const { equipmentList } = useGetEquipmentList(item as string);

  const equipment = equipmentList.find((equipment: any) => equipment.id === id);

  const basePath =
    "https://lab.lifebank.ng/labx/api/v1/orders/image/equipments/";
  const image = basePath + equipment?.imageUrl;

  const [paymentType, setPaymentType] = useState("");
  const [isContinue, setIsContinue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState("0");
  const [error, setError] = React.useState<string>("");

  function handlePaymentType(paymentType: any): void {
    setPaymentType(paymentType);
  }
  const handleSubmitQuotation = (period: string) => {
    const payload = {
      order_time: period,
      hospital_id: hospitalId,
      name: equipment?.equipmentName,
      equipment: "equipment",
      qty: count,
      item_id: id,
      payment_method: paymentType,
    };

    mutateSendQuipQuotations(payload, {
      onSuccess: (response: {
        data: { status: Renderable | ValueFunction<Renderable, Toast> };
      }) => {
        toastService.success(response.data.status);
        navigate("/purchasing-orders");
      },
      onError: (error: any) => {
        console.error(error);
        toastService.error("Failed to send quotation");
      },
    });
  };

  function handleDecrement(): void {
    if (count === "" || isNaN(parseInt(count, 10))) {
      setError("Please enter a valid number first.");
      return;
    }

    setCount((prevCount) => {
      const newCount = parseInt(prevCount, 10) - 1;
      if (newCount < 1) {
        return "1"; // Prevent going below 1
      }
      return newCount.toString();
    });
  }

  function handleIncrement(): void {
    if (count === "" || isNaN(parseInt(count, 10))) {
      setCount("1");
      // setError("Please enter a valid number first.");
      return;
    }

    setCount((prevCount) => (parseInt(prevCount, 10) + 1).toString());
    setError("");
  }

  function handleQuantityChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const newQuantity = e.target.value;

    // Allow empty input to clear the field
    if (newQuantity === "") {
      setCount("");
      setError("");
      return;
    }

    if (/^\d*$/.test(newQuantity)) {
      setCount(newQuantity);
      setError("");
    } else {
      setError("Please enter a valid number.");
    }
  }

  return (
    <>
      <div className="w-full bg-[#F9F9F9] pt-4">
        <div className="mx-auto w-11/12 text-equipmentOrange pb-6 flex flex-col gap-10">
          <div className="flex flex-col md:flex-row gap-20">
            <div className="max-w-96 w-full bg-[white] shadow-md flex items-center justify-center">
              <img
                src={image}
                alt="noting"
                className="object-fit max-h-full w-full"
              />
            </div>
            <div className="flex flex-col md:mt-20">
              <p className="text-3xl text-black">{equipment?.equipmentName}</p>
              <p className="text-2xl text-equipmentOrange border border-equipmentOrange rounded mt-10 p-2 shadow-lg">
                READ FULL SPECIFICATION
              </p>
              <div className="flex max-w-md mr-auto px-10 justify-start mt-4 gap-2">
                <Button
                  className="w-full bg-blue-400 p-3 rounded-xl text-white font-semibold hover:bg-blue-500"
                  label={"Request Quotation"}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
          </div>
          <div className="w-full bg-[white] md:h-[150px] shadow-lg">
            <p className="text-black p-4 text-[20px]">
              {equipment?.equipmentName} Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Atque laborum magnam eveniet sunt sint labore
              earum non fuga optio ex?
            </p>
            <div className="mx-auto w-10/12 flex justify-between gap-2">
              <p className="md:w-4/12 font-bold bg-[#FFD9C9] rounded text-1xl items-center justify-center flex p-2">
                2 vendors available
              </p>
              <p className="md:w-4/12 bg-[#737373] text-white text-1xl items-center justify-center flex p-2">
                ESTIMATED DELIVERY DATES
              </p>
            </div>
          </div>
          {/* <div className="flex w-full mx-auto justify-between mt-4 gap-2">
            <div className="w-full px-10">
              <Button
                className="w-full bg-blue-400 p-3 rounded-xl text-white font-semibold hover:bg-blue-500"
                label={"Request Quotation"}
                onClick={handleShowModal}
              />
            </div>
          </div> */}
        </div>
      </div>

      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 w-full h-full bg-black opacity-40" />
          <Dialog.Content
            aria-describedby={undefined}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-2xl px-4"
          >
            <div className="bg-white rounded-md shadow-lg px-4 py-6">
              <div className="flex items-center justify-between">
                <h1 className="font-semibold text-xl">REQUEST QUOTATION</h1>
                <Dialog.Close
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Dialog.Close>
              </div>
              <div className="space-y-3 overflow-y-auto max-h-96 p-3">
                {!isContinue && (
                  <>
                    <Dialog.Title className="text-lg font-medium text-gray-800 ">
                      <div className="p-4 shadow-md w-full">
                        <p className="w-full text-[#d47248] text-xl">
                          Equipment details
                        </p>
                        <div className="border-t w-full border-gray-400"></div>
                        <p className="mt-2 text-2xl text-gray-500">
                          {equipment?.equipmentName}
                        </p>
                      </div>
                    </Dialog.Title>
                    <div className="p-4 shadow-md w-full flex items-center">
                      <p className="w-full text-[#d47248] text-xl">
                        Quantity needed
                      </p>
                      <div>
                        <div className="flex flex-row items-center gap-1">
                          <span
                            role="button"
                            className="w-8 h-8 text-black text-center font-bold rounded-full p-1 border border-black"
                            onClick={handleDecrement}
                          >
                            -
                          </span>
                          <input
                            id="quantity"
                            name="quantity"
                            type="text"
                            value={count}
                            min={1}
                            className="w-10 h-8 text-black text-center font-bold rounded-full p-1 border border-[#d47248]"
                            onChange={(e) => handleQuantityChange(e)}
                          />
                          <span
                            role="button"
                            className="w-8 h-8 text-black text-center font-bold rounded-full p-1 border border-black"
                            onClick={handleIncrement}
                          >
                            +
                          </span>
                        </div>
                        {error && (
                          <p className="text-red-500 text-sm mt-1">{error}</p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col justify-center gap-3">
                      <PaymentMethods
                        handlePaymentType={handlePaymentType}
                        paymentType={paymentType}
                      />

                      <div className="flex justify-center">
                        <button
                          onClick={() => {
                            if (+count < 1) {
                              toastService.error("Please add a quantity");
                              setError("Please add a quantity");
                              return;
                            }
                            if (!paymentType) {
                              toastService.error(
                                "Please select a payment method"
                              );
                              return;
                            }
                            setIsContinue(true);
                          }}
                          className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-400 ease-in-out duration-300 delay-150"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {isContinue && (
                  <div className="lg:h-96">
                    <h2 className="font-semibold lg:text-3xl mb-8">
                      When do you need your quotation?
                    </h2>

                    <div className="grid lg:grid-cols-3 w-full gap-4">
                      <button
                        disabled={isSubmitting}
                        onClick={() => handleSubmitQuotation("In 24 hours")}
                        className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
                      >
                        <span className="font-semibold text-lg">24 </span> In 24
                        hours
                      </button>
                      <button
                        disabled={isSubmitting}
                        onClick={() => handleSubmitQuotation("In 3 days")}
                        className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
                      >
                        <span className="font-semibold text-lg">3</span> In
                        Three days
                      </button>
                      <button
                        disabled={isSubmitting}
                        onClick={() => handleSubmitQuotation("In 7 days")}
                        className="lg:h-32 bg-smokeWhite border    flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
                      >
                        <span className="font-semibold text-lg">7</span> In
                        Seven days
                      </button>
                      <div className="hidden lg:block"></div>
                      <button
                        disabled={isSubmitting}
                        onClick={() => handleSubmitQuotation("In 10 days")}
                        className="lg:h-32 bg-smokeWhite border flex flex-col items-center justify-center w-full group px-4 py-2 font-semibold text-sm bg-blue-500 text-white rounded-md shadow-sm  hover:scale-105 ease-in-out duration-300 delay-150"
                      >
                        <span className="font-semibold text-lg  ">10</span>{" "}
                        <span className="flex group-hover:text-white">
                          {" "}
                          In Ten days
                        </span>
                      </button>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
export default EquipmentDetails;

// const ApprovalModal = ({
//   setApprovalModal,
//   newData,
// }: {
//   setApprovalModal: any;
//   newData: any;
// }) => {
//   const dispatch = useDispatch();

//   const product = newData;

//   const handleCloseModal = () => {
//     setApprovalModal(false);
//   };

//   const sendEmail = () => {
//     const emailAddress = "recipient@example.com";
//     const emailSubject = "Purchase order";
//     const emailBody = "Please find attached the PO for your approval!";

//     const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(
//       emailSubject
//     )}&body=${encodeURIComponent(emailBody)}`;

//     window.location.href = mailtoUrl;
//   };

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.text("Purchase Order", 10, 10);
//     doc.text(`Item: ${product.name}`, 10, 20);
//     doc.text(`Quantity: ${product.quantity}`, 10, 30);
//     doc.text(`Price: ${product.price}`, 10, 40);
//     doc.save("purchase-order.pdf");
//   };

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="w-7/12 h-10/12  bg-[#F9F9F9]">
//         <div className="flex mb-3 flex-row justify-between bg-equipmentOrange w-full h-[50px] border-t border-r rounded-tl rounded-tr">
//           <div className="flex items-center justify-center w-full">
//             <h2 className="text-xl text-white font-bold text-center p-4">
//               REQUEST APPROVAL
//             </h2>
//           </div>
//           <button
//             onClick={handleCloseModal}
//             className="self-start text-white p-2 pl-4 pr-4 text-2xl"
//           >
//             X
//           </button>
//         </div>
//         <div className="mt-2 p-4 bg-[#F9F9F9] shadow-md rounded w-11/12 mx-auto">
//           <div className="flex flex-col gap-4 mx-auto w-8/12 mt-2 mb-4">
//             <Button
//               label={"DOWNLOAD PO"}
//               // customColor={colors.equipmentOrange}
//               onClick={generatePDF}
//             />

//             <Button
//               label={"SEND EMAIL"}
//               onClick={sendEmail}
//               // customColor={colors.equipmentOrange}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
