import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import Button from "../common/button";
import LoadingButton from "../common/loadingButton";
import NoItems from "./noItems";

import comma from "../../utils/comma";
import utilities from "../../utils/utilities";

import authService from "../../services/authService";
import dashboardService from "../../services/dashboardService";
import saveFile from "../../services/pdfService";

import { useGlobalContext } from "../../contexts/globalContext";

import { useGetProductList } from "../../hooks/useProductList";

const LeadershipBoard = () => {
  const privileges = authService.getPrivileges();
  const { city, country } = useGlobalContext();
  const { liteApiUrl } = country;
  const { productList, isPending } = useGetProductList();

  const [activeProduct, setActiveProduct] = useState("blood");

  const { data, isPending: leadershipBoardPending } = useQuery({
    queryKey: ["leadershipBoard"],
    queryFn: async () => {
      const res = await dashboardService.getLeadershipBoard(liteApiUrl, city);
      const board = utilities.parseKeysToLowercase(res.data);
      return board;
    },
    enabled: privileges.viewLeaderBoard,
  });

  const getActiveData = () => {
    // const allItems = utilities.mapArrayFromObjects(leadershipBoard);
    const leadership =
      data?.[activeProduct]?.filter((item) => item.name !== null) ?? [];

    leadership.sort((a, b) => parseInt(b.cost) - parseInt(a.cost));
    return leadership;
  };

  const activeData = getActiveData();

  return (
    <React.Fragment>
      <LoadingButton
        visible={leadershipBoardPending || isPending}
        message="Fetching leadership board"
      />

      {!leadershipBoardPending && !isPending && (
        <React.Fragment>
          <div className="top-header">
            <h2>Leadership Board</h2>
            <button
              className="full-report"
              onClick={() => saveFile(activeData)}
            >
              Download
            </button>
          </div>

          <p className="sales-paragraph">Top sales of this month</p>

          <div className="flex items-center w-full gap-1 mt-2">
            {productList
              ?.filter((product) => {
                const productName = product?.name?.toLowerCase();
                return ["blood", "oxygen", "quip", "stockbank"].some(
                  (keyword) => productName.includes(keyword)
                );
              })
              .map((product) => {
                const productName = product?.name?.toLowerCase();

                return (
                  <Button
                    key={product.id}
                    label={productName}
                    onClick={() => setActiveProduct(productName)}
                    className={
                      productName === activeProduct
                        ? "flex items-center justify-center w-full p-1.5 rounded-sm text-white capitalize active"
                        : "flex items-center justify-center w-full p-1.5 rounded-sm text-white capitalize disabled opacity-50"
                    }
                    style={{ backgroundColor: product.color }}
                  />
                );
              })}
          </div>

          <div className="leadership-board">
            {activeData.map((l) => {
              return (
                <div className="leadership-board-item" key={l.ref + l.time}>
                  <div className="left-item">
                    <p className="title">{l.name.substring(0, 15)}...</p>
                    <p className="subtitle">
                      Last Order: {dayjs(l.time).format("YYYY-MM-DD hh:mm:ss")}
                    </p>
                  </div>
                  <div className="right-item">
                    <p className="price">
                      {country.currency}
                      {privileges.viewLeaderBoard
                        ? comma.addComma(l.cost)
                        : "******"}
                    </p>
                    <p className="inner error">{l.qty} Units</p>
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}

      <div className="flex items-center justify-center mt-5">
        <NoItems isVisible={activeData.length === 0} label="No data to show" />
      </div>
    </React.Fragment>
  );
};

export default LeadershipBoard;
